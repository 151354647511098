"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.applyAllFilters = void 0;
const filters_types_1 = require("./filters-types");
const common_1 = require("@augment-vir/common");
function applyAllFilters(dataEntry, filters) {
    return Object.values(filters).every(filter => {
        return applyIndividualFilter(dataEntry, filter);
    });
}
exports.applyAllFilters = applyAllFilters;
function applyIndividualFilter(dataEntry, filter) {
    if (filter.filterType === filters_types_1.FilterTypeEnum.Checkboxes) {
        return applyCheckboxFilter(dataEntry, filter);
    }
    else if (filter.filterType === filters_types_1.FilterTypeEnum.Radio) {
        return applyRadioFilter(dataEntry, filter);
    }
    else if (filter.filterType === filters_types_1.FilterTypeEnum.ExpandingList) {
        return applyExpandingListFilter(dataEntry, filter);
    }
    else if (filter.filterType === filters_types_1.FilterTypeEnum.NumericRange) {
        return applyNumericRangeFilter(dataEntry, filter);
    }
    else if (filter.filterType === filters_types_1.FilterTypeEnum.ImageToggles) {
        return applyImageTogglesFilter(dataEntry, filter);
    }
    else {
        throw new Error(`Invalid filterType received: ${filter.filterType}`);
    }
}
function applyImageTogglesFilter(dataEntry, filter) {
    const areAnyChecked = Object.values(filter.entries).some(filterEntry => filterEntry.checked);
    if (areAnyChecked) {
        const shouldShow = Object.entries(filter.entries).some(([filterName, filterEntry,]) => {
            if (!filterEntry.checked) {
                return false;
            }
            const matchedCheckboxFilter = matchSingleCheckboxFilter({
                checkbox: {
                    checked: true,
                    label: filterName,
                    value: filterEntry.filterValue,
                },
                dataEntry,
                fieldKeys: filter.filterField,
            });
            return matchedCheckboxFilter;
        });
        return shouldShow;
    }
    else {
        return true;
    }
}
function applyExpandingListFilter(dataEntry, filter) {
    return filter.entries.every(filterEntry => {
        return filterEntry.checkboxes.every(checkbox => {
            // don't filter on unchecked boxes
            if (!checkbox.checked) {
                return true;
            }
            return matchSingleCheckboxFilter({
                dataEntry,
                fieldKeys: [
                    ...filter.filterField,
                    filterEntry.key,
                ],
                checkbox,
            });
        });
    });
}
function applyNumericRangeFilter(dataEntry, filter) {
    var _a;
    if (filter.currentMax == undefined && filter.currentMin == undefined) {
        return true;
    }
    const value = (_a = (0, common_1.getValueFromNestedKeys)(dataEntry, filter.filterField)) !== null && _a !== void 0 ? _a : 0;
    const factor = filter.factor || 1;
    const matchThisData = Number(value) * factor;
    if (isNaN(matchThisData)) {
        console.error({ filter, dataEntry });
        throw new Error(`Failed to get a numeric value to filter to. See logged filter and dataEntry above.`);
    }
    const matchesMax = filter.currentMax == undefined ? true : matchThisData <= filter.currentMax;
    const matchesMin = filter.currentMin == undefined ? true : matchThisData >= filter.currentMin;
    return matchesMax && matchesMin;
}
function applyRadioFilter(dataEntry, filter) {
    const selectedRadio = filter.radios.filter(radio => String(radio.value) === String(filter.value))[0];
    if (!selectedRadio) {
        throw new Error(`Failed to find selected radio in '${filter.filterField}' filter by value '${filter.value}'.`);
    }
    return matchSingleCheckboxFilter({
        dataEntry,
        fieldKeys: filter.filterField,
        checkbox: {
            ...selectedRadio,
            checked: true,
        },
    });
}
function applyCheckboxFilter(dataEntry, filter) {
    return filter.checkboxes.every(checkbox => {
        return matchSingleCheckboxFilter({
            dataEntry,
            fieldKeys: filter.filterField,
            checkbox,
        });
    });
}
function matchSingleCheckboxFilter({ dataEntry, fieldKeys, checkbox, }) {
    var _a;
    if (checkbox.filterType === filters_types_1.BooleanFilterTypeEnum.Everything) {
        return true;
    }
    const matchThisData = (0, common_1.getValueFromNestedKeys)(dataEntry, fieldKeys);
    if (matchThisData == undefined) {
        return false;
    }
    const checkboxValue = (_a = checkbox.value) !== null && _a !== void 0 ? _a : checkbox.label;
    const matchesData = Array.isArray(matchThisData)
        ? value => {
            return matchThisData.includes(value);
        }
        : typeof matchThisData === 'string'
            ? value => {
                if (typeof value === 'boolean') {
                    if (value) {
                        return !!matchThisData;
                    }
                    else {
                        return !matchThisData;
                    }
                }
                else {
                    if (checkbox.filterType === filters_types_1.BooleanFilterTypeEnum.Contains) {
                        return matchThisData.includes(value);
                    }
                    else {
                        return value === matchThisData;
                    }
                }
            }
            : typeof matchThisData === 'boolean'
                ? value => {
                    if (value) {
                        return !!matchThisData;
                    }
                    else {
                        return !matchThisData;
                    }
                }
                : undefined;
    if (matchesData == undefined) {
        console.error({ fields: fieldKeys, dataEntry });
        throw new Error(`Failed to figure out what to match data against for a checkbox filter. See logged filter and dataEntry above.`);
    }
    if (checkbox.checked) {
        return matchesData(checkboxValue);
    }
    else {
        return !matchesData(checkboxValue);
    }
}
