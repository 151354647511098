"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.icBucksCanisterDetails = void 0;
const ic_canister_domain_1 = require("../../../../api/ic-canister-domain");
const url_1 = require("../../../../augments/url");
exports.icBucksCanisterDetails = {
    collectionName: 'IC Bucks',
    canisterId: '6wih6-siaaa-aaaah-qczva-cai',
    getNftImageData({ originalCanisterId, nftId, ref }) {
        const imageUrl = [
            (0, ic_canister_domain_1.getCanisterDomain)(originalCanisterId),
            (0, url_1.createQueryParamString)({ cc: Date.now(), type: 'thumbnail', tokenid: nftId }, [ref]),
        ].join('/');
        return { url: imageUrl };
    },
};
