"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntrepotNftDisplayReact = exports.EntrepotNftDisplayElement = void 0;
const element_vir_1 = require("element-vir");
const design_system_1 = require("@toniq-labs/design-system");
const get_nft_image_data_1 = require("../../../data/canisters/get-nft-image-data");
const wrap_native_element_1 = require("@toniq-labs/design-system/dist/esm/elements/wrap-native-element");
const resizable_image_element_1 = require("@electrovir/resizable-image-element");
// these dimensions are set so that they fit within an NFTCard component size of about 300x600
const defaultDimensionConstraint = {
    min: {
        width: 272,
        height: 200,
    },
    max: {
        width: 272,
        height: 456,
    },
};
exports.EntrepotNftDisplayElement = (0, design_system_1.defineToniqElement)()({
    tagName: 'toniq-entrepot-nft-display',
    stateInit: {
        nftImageData: (0, element_vir_1.asyncState)(),
    },
    styles: (0, element_vir_1.css) `
        :host {
            color: ${design_system_1.toniqColors.pagePrimary.foregroundColor};
            background-color: ${design_system_1.toniqColors.accentSecondary.backgroundColor};
            border-radius: 8px;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .size-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: inherit;
        }

        ${resizable_image_element_1.VirResizableImage} {
            background-color: inherit;
        }
    `,
    renderCallback: ({ inputs, state, updateState }) => {
        var _a, _b;
        updateState({
            nftImageData: {
                createPromise: async () => {
                    return await (0, get_nft_image_data_1.getNftImageData)(inputs);
                },
                trigger: { ...inputs },
            },
        });
        const dimensionConstraints = {
            min: (_a = inputs.min) !== null && _a !== void 0 ? _a : defaultDimensionConstraint.min,
            max: (_b = inputs.max) !== null && _b !== void 0 ? _b : defaultDimensionConstraint.max,
        };
        const asyncTemplate = (0, element_vir_1.renderAsyncState)(state.nftImageData, (0, element_vir_1.html) `
                <${design_system_1.ToniqIcon}
                    ${(0, element_vir_1.assign)(design_system_1.ToniqIcon, { icon: design_system_1.LoaderAnimated24Icon })}
                ></${design_system_1.ToniqIcon}>
            `, resolvedImageData => (0, element_vir_1.html) `
                    <${resizable_image_element_1.VirResizableImage}
                        ${(0, element_vir_1.assign)(resizable_image_element_1.VirResizableImage, {
            imageUrl: resolvedImageData.url,
            max: dimensionConstraints.max,
            min: dimensionConstraints.min,
            extraHtml: resolvedImageData.extraHtml,
            forcedOriginalImageSize: resolvedImageData.forcedOriginalImageSize,
            htmlSizeQuerySelector: resolvedImageData.htmlSizeQuerySelector,
        })}
                    >
                        <${design_system_1.ToniqIcon}
                            ${(0, element_vir_1.assign)(design_system_1.ToniqIcon, { icon: design_system_1.LoaderAnimated24Icon })}
                            slot="loading"
                        ></${design_system_1.ToniqIcon}>
                    </${resizable_image_element_1.VirResizableImage}>
                `);
        const sizeWrapperStyle = (0, element_vir_1.css) `
            max-width: ${dimensionConstraints.max.width}px;
            max-height: ${dimensionConstraints.max.height}px;
            min-width: ${dimensionConstraints.min.width}px;
            min-height: ${dimensionConstraints.min.height}px;
        `;
        return (0, element_vir_1.html) `
            <div class="size-wrapper" style=${sizeWrapperStyle}>${asyncTemplate}</div>
        `;
    },
});
exports.EntrepotNftDisplayReact = (0, wrap_native_element_1.wrapInReactComponent)(exports.EntrepotNftDisplayElement);
