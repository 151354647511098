"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createProfileFilterInputs = void 0;
const element_vir_1 = require("element-vir");
const profile_tabs_1 = require("./profile-tabs");
const create_profile_nft_filter_inputs_1 = require("./profile-page-nft-filters/create-profile-nft-filter-inputs");
const create_profile_transaction_filter_inputs_1 = require("./profile-page-transaction-filters/create-profile-transaction-filter-inputs");
const profile_nft_types_1 = require("../profile-page-nfts/profile-nft-types");
const profile_collections_filter_1 = require("../profile-page-nfts/profile-collections-filter");
const profile_nft_offer_status_1 = require("./profile-page-nft-filters/profile-nft-offer-status");
const create_profile_offer_filter_inputs_1 = require("./profile-page-offer-filters/create-profile-offer-filter-inputs");
const all_canister_details_1 = require("../../../../../data/canisters/canister-details/all-canister-details");
const nft_id_1 = require("../../../../../data/nft/nft-id");
function combineOffers({ userOffersMade, userOwnedNfts, }) {
    const allPotentialNftsWithOffers = [
        ...(Array.isArray(userOffersMade) ? userOffersMade : []),
        ...(Array.isArray(userOwnedNfts) ? userOwnedNfts : []),
    ];
    return allPotentialNftsWithOffers.filter(nft => {
        return nft.offers.length;
    });
}
function getAsyncStateForCurrentTab(currentState) {
    const tabToStateProp = {
        'my-nfts': currentState.userOwnedNfts,
        favorites: currentState.userFavorites,
        offers: combineOffers(currentState),
        activity: currentState.userTransactions,
        // earn: currentState.userEarnNfts,
        earn: [],
    };
    return tabToStateProp[currentState.currentProfileTab.value];
}
function createProfileFilterInputs({ currentProfilePageState, collectionMap, sellCallback, transferCallback, userAccount, nftClickCallback, }) {
    const asyncEntries = getAsyncStateForCurrentTab(currentProfilePageState);
    const entries = (0, element_vir_1.isRenderReady)(asyncEntries)
        ? asyncEntries.map((nft) => {
            var _a, _b, _c, _d;
            const nftIndex = nft.nftIndex ? nft.nftIndex : (0, nft_id_1.decodeNftId)(nft.nftId).index;
            const nftMintNumber = nft.nftMintNumber
                ? nft.nftMintNumber
                : (0, all_canister_details_1.getCanisterDetails)(nft.collectionId).hasWrappedCanister
                    ? nftIndex
                    : nftIndex + 1;
            const nftNri = (0, element_vir_1.isRenderReady)(currentProfilePageState.collectionNriData)
                ? (_b = (_a = currentProfilePageState.collectionNriData[nft.collectionId]) === null || _a === void 0 ? void 0 : _a.nriData) === null || _b === void 0 ? void 0 : _b[nftIndex]
                : undefined;
            const collection = collectionMap[nft.collectionId];
            if ((0, profile_nft_types_1.isNftType)(nft, profile_tabs_1.profileTabMap.activity.value, currentProfilePageState)) {
                const fullTransaction = {
                    buyerAddress: nft.buyerAddress,
                    collection,
                    collectionId: nft.collectionId,
                    directionForCurrentUser: nft.directionForCurrentUser,
                    listing: nft.hasOwnProperty('listing')
                        ? nft.listing
                        : {
                            price: nft.listPrice,
                            lockedTimestamp: 0,
                        },
                    nftId: nft.nftId,
                    nftIndex,
                    nftMintNumber,
                    nftNri: nftNri,
                    offers: nft.offers ? nft.offers : [],
                    ownerAddress: nft.ownerAddress ? nft.ownerAddress : '',
                    sellerAddress: nft.sellerAddress,
                    transactionId: nft.transactionId ? nft.transactionId : '',
                    transactionTimeMillisecond: nft.transactionTimeMillisecond
                        ? nft.transactionTimeMillisecond
                        : nft.timestampNanosecond / 1000000,
                };
                return fullTransaction;
            }
            else if ((0, profile_nft_types_1.isNftType)(nft, profile_tabs_1.profileTabMap.earn.value, currentProfilePageState)) {
                // need to figure out earn types still
                const fullEarn = {
                    ...nft,
                    nftNri,
                    collection,
                    nftMintNumber,
                };
                return fullEarn;
            }
            else {
                const fullUserNft = {
                    ...nft,
                    nftNri,
                    isListed: nft.listing ? !!nft.listing.price : !!nft.listPrice,
                    collection,
                    offerStatus: (0, profile_nft_types_1.isNftType)(nft, profile_tabs_1.profileTabMap.favorites.value, currentProfilePageState)
                        ? (0, profile_nft_offer_status_1.calculateOfferStatusFavorites)((_c = userAccount === null || userAccount === void 0 ? void 0 : userAccount.address) !== null && _c !== void 0 ? _c : '', nft)
                        : (0, profile_nft_offer_status_1.calculateOfferStatus)((_d = userAccount === null || userAccount === void 0 ? void 0 : userAccount.address) !== null && _d !== void 0 ? _d : '', nft),
                    nftMintNumber,
                };
                return fullUserNft;
            }
        })
        : [];
    const areEntriesRenderReady = (0, element_vir_1.isRenderReady)(asyncEntries);
    const currentCollectionsFilter = (0, profile_collections_filter_1.createCollectionsFilter)({
        entries,
        collectionsExpanded: currentProfilePageState.collectionsFilterExpanded,
        selectedCollectionIds: currentProfilePageState.selectedCollections[currentProfilePageState.currentProfileTab.value],
    });
    const defaultCollectionsFilter = (0, profile_collections_filter_1.createCollectionsFilter)({
        entries,
        collectionsExpanded: false,
        selectedCollectionIds: [],
    });
    const filterInputs = (0, profile_nft_types_1.isEntriesType)(entries, profile_tabs_1.profileTabMap.activity.value, currentProfilePageState)
        ? (0, create_profile_transaction_filter_inputs_1.createUserTransactionFilterInputs)({
            ...currentProfilePageState,
            entries,
            isRenderReady: areEntriesRenderReady,
            nftClickCallback,
        })
        : (0, profile_nft_types_1.isEntriesType)(entries, profile_tabs_1.profileTabMap.earn.value, currentProfilePageState)
            ? {}
            : currentProfilePageState.currentProfileTab.value === profile_tabs_1.profileTabMap.offers.value
                ? (0, create_profile_offer_filter_inputs_1.createProfileOfferFilterInputs)({
                    ...currentProfilePageState,
                    entries,
                    isRenderReady: areEntriesRenderReady,
                    sellCallback,
                    transferCallback,
                    userAccount,
                    nftClickCallback,
                })
                : (0, create_profile_nft_filter_inputs_1.createProfileNftFilterInputs)({
                    ...currentProfilePageState,
                    entries,
                    isRenderReady: areEntriesRenderReady,
                    sellCallback,
                    transferCallback,
                    userAccount,
                    nftClickCallback,
                });
    return {
        ...filterInputs,
        currentFilters: {
            ...filterInputs.currentFilters,
            Collections: currentCollectionsFilter,
        },
        defaultFilters: {
            ...filterInputs.currentFilters,
            Collections: defaultCollectionsFilter,
        },
    };
}
exports.createProfileFilterInputs = createProfileFilterInputs;
