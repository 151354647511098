"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseRawNftOffers = void 0;
function parseRawNftOffers(rawNftOffers) {
    return {
        offers: rawNftOffers.map(rawNftOffer => {
            return {
                offerAmount: Number(rawNftOffer.amount),
                offererAccountAddress: rawNftOffer.offerer.toText(),
                timestamp: Number(rawNftOffer.time / BigInt(1000000)),
            };
        }),
    };
}
exports.parseRawNftOffers = parseRawNftOffers;
