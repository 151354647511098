"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntrepotMarketplaceCardElement = void 0;
const element_vir_1 = require("element-vir");
const design_system_1 = require("@toniq-labs/design-system");
const common_1 = require("@augment-vir/common");
const spa_router_vir_1 = require("spa-router-vir");
exports.EntrepotMarketplaceCardElement = (0, design_system_1.defineToniqElement)()({
    tagName: 'toniq-entrepot-marketplace-card',
    events: {
        navigateToRoute: (0, element_vir_1.defineElementEvent)(),
    },
    styles: (0, element_vir_1.css) `
        :host {
            display: inline-flex;
            flex-direction: column;
            border-radius: 16px;
            will-change: filter;
            margin: 16px 4px;
            width: 304px;
            max-width: 100%;
            border: 1px solid transparent;
            cursor: pointer;
            ${(0, design_system_1.applyBackgroundAndForeground)(design_system_1.toniqColors.pagePrimary)}
            ${design_system_1.toniqShadows.popupShadow}
        }

        a {
            height: 100%;
            width: 100%;
            text-decoration: none;
            display: flex;
            align-items: stretch;
            text-align: left;
            flex-direction: column;
            flex-grow: 1;
            color: inherit;
        }

        :host(:hover) {
            border-color: ${design_system_1.toniqColors.pageInteraction.foregroundColor};
        }

        .description {
            flex-grow: 1;
            margin: 0;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }

        .image-holder {
            height: 188px;
            width: 100%;
            border-radius: 16px 16px 0 0;
            overflow: hidden;
            background-position: center;
            background-size: cover;
        }

        .collection-details {
            display: flex;
            flex-grow: 1;
            gap: 16px;
            flex-direction: column;
            padding: 16px;
        }

        h3 {
            margin: 0;
            ${design_system_1.toniqFontStyles.h3Font}
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }

        .stats {
            display: flex;
            flex-wrap: wrap;
            gap: 16px;
        }

        .stat-entry {
            /* This weird flex-basis value allows the stat-entry elements to snap between 
             * horizontal and vertical. See https://codepen.io/heydon/pen/JwwZaX
             */
            flex-basis: calc(calc(256px - 100%) * 999);
            flex-shrink: 0;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            gap: 8px;
            align-items: stretch;
            max-width: 100%;
        }

        .stat-entry ${design_system_1.ToniqChip} {
            ${design_system_1.toniqFontStyles.boldParagraphFont}
            ${design_system_1.toniqFontStyles.monospaceFont}
            max-height: 24px;
            flex-grow: 1;
        }

        .stat-title {
            text-align: center;
            ${design_system_1.toniqFontStyles.labelFont}
            ${(0, design_system_1.applyBackgroundAndForeground)(design_system_1.toniqColors.pageSecondary)}
        }
    `,
    renderCallback: ({ inputs, events, dispatch }) => {
        const linkUrl = `/marketplace/${inputs.collection.route}`;
        return (0, element_vir_1.html) `
            <a
                href=${linkUrl}
                @click=${(clickEvent) => {
            if ((0, spa_router_vir_1.shouldMouseEventTriggerRoutes)(clickEvent)) {
                clickEvent.preventDefault();
                dispatch(new events.navigateToRoute(inputs.collection.route));
            }
        }}
            >
                <div
                    class="image-holder"
                    style="background-image: url('${inputs.collection.collection}')"
                ></div>
                <div class="collection-details">
                    <h3>${inputs.collection.name}</h3>
                    <p class="description">${inputs.collection.brief}</p>
                    <div class="stats">${createStatsTemplate(inputs.collection.stats)}</div>
                </div>
            </a>
        `;
    },
});
function createStatsTemplate(stats) {
    if (!stats) {
        return (0, element_vir_1.html) ``;
    }
    const statsArray = [
        {
            title: 'VOLUME',
            icon: design_system_1.Icp16Icon,
            stat: stats.total,
        },
        {
            title: 'LISTINGS',
            stat: stats.listings,
        },
        {
            title: 'FLOOR PRICE',
            icon: design_system_1.Icp16Icon,
            stat: stats.floor,
        },
    ];
    return statsArray.map(statEntry => {
        return (0, element_vir_1.html) `
            <div class="stat-entry">
                <span class="stat-title">${statEntry.title}</span>
                <${design_system_1.ToniqChip}
                    class=${design_system_1.ToniqChip.hostClasses.secondary}
                    ${(0, element_vir_1.assign)(design_system_1.ToniqChip, {
            icon: statEntry.icon,
            text: (0, common_1.truncateNumber)(statEntry.stat),
        })}
                ></${design_system_1.ToniqChip}>
            </div>
        `;
    });
}
