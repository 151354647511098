"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntrepotMarketplace = exports.EntrepotMarketplacePageElement = void 0;
const wrap_native_element_1 = require("@toniq-labs/design-system/dist/esm/elements/wrap-native-element");
const common_1 = require("@augment-vir/common");
const element_vir_1 = require("element-vir");
const toniq_entrepot_with_filters_element_1 = require("../../common/with-filters/toniq-entrepot-with-filters.element");
const toniq_entrepot_marketplace_card_element_1 = require("./toniq-entrepot-marketplace-card.element");
const marketplace_filters_1 = require("./marketplace-filters");
const toniq_entrepot_page_header_element_1 = require("../../common/toniq-entrepot-page-header.element");
const design_system_1 = require("@toniq-labs/design-system");
exports.EntrepotMarketplacePageElement = (0, design_system_1.defineToniqElement)()({
    tagName: 'toniq-entrepot-marketplace-page',
    styles: (0, element_vir_1.css) `
        :host {
            display: block;
            min-height: 100vh;
        }

        @media (max-width: 1200px) {
            :host {
                padding: 16px;
            }
        }
    `,
    events: {
        collectionSelected: (0, element_vir_1.defineElementEvent)(),
    },
    stateInit: {
        showFilters: false,
        filters: marketplace_filters_1.defaultMarketplaceFilters,
        currentSort: (0, common_1.ensureType)({
            ascending: false,
            name: marketplace_filters_1.sortDefinitions[0].sortName,
        }),
    },
    renderCallback: ({ inputs, state, updateState, dispatch, events }) => {
        return (0, element_vir_1.html) `
            <${toniq_entrepot_page_header_element_1.EntrepotPageHeaderElement}
                ${(0, element_vir_1.assign)(toniq_entrepot_page_header_element_1.EntrepotPageHeaderElement, {
            headerText: 'All Collections',
        })}
            ></${toniq_entrepot_page_header_element_1.EntrepotPageHeaderElement}>
            <${toniq_entrepot_with_filters_element_1.EntrepotWithFiltersElement}
                ${(0, element_vir_1.assign)(toniq_entrepot_with_filters_element_1.EntrepotWithFiltersElement, (0, toniq_entrepot_with_filters_element_1.createWithFiltersInputs)({
            countName: 'Collections',
            showFilters: state.showFilters,
            currentSort: state.currentSort,
            sortDefinitions: marketplace_filters_1.sortDefinitions,
            defaultFilters: marketplace_filters_1.defaultMarketplaceFilters,
            currentFilters: state.filters,
            allEntries: inputs.collections,
            searchPlaceholder: 'Search: Collection Name or Keywords',
            searchCallback: (searchTerm, collection) => {
                const allSearchAreas = [
                    collection.name,
                    collection.keywords,
                    collection.route,
                    collection.id,
                ].join(' ');
                return allSearchAreas.toLowerCase().includes(searchTerm.toLowerCase());
            },
            createEntryTemplateCallback: collection => {
                return (0, element_vir_1.html) `
                                <${toniq_entrepot_marketplace_card_element_1.EntrepotMarketplaceCardElement}
                                    ${(0, element_vir_1.assign)(toniq_entrepot_marketplace_card_element_1.EntrepotMarketplaceCardElement, {
                    collection: collection,
                })}
                                    ${(0, element_vir_1.listen)(toniq_entrepot_marketplace_card_element_1.EntrepotMarketplaceCardElement.events.navigateToRoute, () => {
                    dispatch(new events.collectionSelected(collection));
                })}
                                ></${toniq_entrepot_marketplace_card_element_1.EntrepotMarketplaceCardElement}>
                            `;
            },
        }))}
                ${(0, element_vir_1.listen)(toniq_entrepot_with_filters_element_1.EntrepotWithFiltersElement.events.showFiltersChange, event => {
            updateState({ showFilters: event.detail });
        })}
                ${(0, element_vir_1.listen)(toniq_entrepot_with_filters_element_1.EntrepotWithFiltersElement.events.filtersChange, event => {
            updateState({ filters: event.detail });
        })}
                ${(0, element_vir_1.listen)(toniq_entrepot_with_filters_element_1.EntrepotWithFiltersElement.events.sortChange, event => {
            updateState({ currentSort: event.detail });
        })}
            >
            </${toniq_entrepot_with_filters_element_1.EntrepotWithFiltersElement}>
        `;
    },
});
exports.EntrepotMarketplace = (0, wrap_native_element_1.wrapInReactComponent)(exports.EntrepotMarketplacePageElement);
