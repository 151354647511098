/* global BigInt */
import {Principal} from '@dfinity/principal';
import {Ed25519KeyIdentity} from '@dfinity/identity';
import {getCrc32} from '@dfinity/principal/lib/esm/utils/getCrc';
import {sha224} from '@dfinity/principal/lib/esm/utils/sha224';
import RosettaApi from './RosettaApi.js';
import {to32bitArray} from '../typescript/augments/bits';
import {toHexString} from '../typescript/augments/string';

const LEDGER_CANISTER_ID = 'ryjl3-tyaaa-aaaaa-aaaba-cai';
const GOVERNANCE_CANISTER_ID = 'rrkah-fqaaa-aaaaa-aaaaq-cai';
const NNS_CANISTER_ID = 'qoctq-giaaa-aaaaa-aaaea-cai';
const CYCLES_MINTING_CANISTER_ID = 'rkp4c-7iaaa-aaaaa-aaaca-cai';
const rosettaApi = new RosettaApi();
const sjcl = require('sjcl');
const bip39 = require('bip39');
const pbkdf2 = require('pbkdf2');

const getCyclesTopUpAddress = canisterId => {
    return principalToAccountIdentifier(
        CYCLES_MINTING_CANISTER_ID,
        getCyclesTopUpSubAccount(canisterId),
    );
};
const getCyclesTopUpSubAccount = canisterId => {
    var pb = Array.from(Principal.fromText(canisterId).toUint8Array());
    return [
        pb.length,
        ...pb,
    ];
};
const amountToBigInt = (amount, decimals) => {
    //decimals = decimals ?? 8;
    if (amount < 1) {
        amount = BigInt(amount * 10 ** decimals);
    } else {
        amount = BigInt(amount) * BigInt(10 ** decimals);
    }
    return amount;
};
const principalToAccountIdentifier = (p, s) => {
    const padding = Buffer('\x0Aaccount-id');
    const array = new Uint8Array([
        ...padding,
        ...Principal.fromText(p).toUint8Array(),
        ...getSubAccountArray(s),
    ]);
    const hash = sha224(array);
    const checksum = to32bitArray(getCrc32(hash));
    const array2 = new Uint8Array([
        ...checksum,
        ...hash,
    ]);
    return toHexString(array2);
};
const getSubAccountArray = s => {
    if (Array.isArray(s)) {
        return s.concat(Array(32 - s.length).fill(0));
    } else {
        //32 bit number only
        return Array(28)
            .fill(0)
            .concat(to32bitArray(s ? s : 0));
    }
};
const mnemonicToId = mnemonic => {
    var seed = bip39.mnemonicToSeedSync(mnemonic);
    seed = Array.from(seed);
    seed = seed.splice(0, 32);
    seed = new Uint8Array(seed);
    return Ed25519KeyIdentity.generate(seed);
};
const encrypt = (mnemonic, principal, password) => {
    return new Promise((resolve, reject) => {
        pbkdf2.pbkdf2(password, principal, 30000, 512, 'sha512', (e, d) => {
            if (e) return reject(e);
            resolve(sjcl.encrypt(d.toString(), btoa(mnemonic)));
        });
    });
};
const decrypt = (data, principal, password) => {
    return new Promise((resolve, reject) => {
        pbkdf2.pbkdf2(password, principal, 30000, 512, 'sha512', (e, d) => {
            if (e) return reject(e);
            try {
                resolve(atob(sjcl.decrypt(d.toString(), data)));
            } catch (e) {
                reject(e);
            }
        });
    });
};
const isHex = h => {
    var regexp = /^[0-9a-fA-F]+$/;
    return regexp.test(h);
};
const validateAddress = a => {
    return isHex(a) && a.length === 64;
};
const validatePrincipal = p => {
    try {
        return p === Principal.fromText(p).toText();
    } catch (e) {
        return false;
    }
};
export {
    LEDGER_CANISTER_ID,
    GOVERNANCE_CANISTER_ID,
    NNS_CANISTER_ID,
    CYCLES_MINTING_CANISTER_ID,
    getCyclesTopUpAddress,
    getCyclesTopUpSubAccount,
    amountToBigInt,
    rosettaApi,
    Principal,
    principalToAccountIdentifier,
    getSubAccountArray,
    mnemonicToId,
    encrypt,
    decrypt,
    isHex,
    bip39,
    validateAddress,
    validatePrincipal,
};
