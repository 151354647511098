"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.retry = exports.throttle = void 0;
const common_1 = require("@augment-vir/common");
function throttle(
/** The delay between the initial firing and when subsequent firing takes effect. */
delayMs, callback) {
    let timeoutId;
    /**
     * When set to true, indicates the function was fired again after the initial call and before
     * the timeout finished.
     */
    let fireAgain = false;
    let latestArgs;
    return (...args) => {
        latestArgs = args;
        // on initial firing, fire the callback
        if (timeoutId == undefined) {
            timeoutId = window.setTimeout(() => {
                timeoutId = undefined;
                if (fireAgain) {
                    callback(...latestArgs);
                }
            }, delayMs);
            fireAgain = false;
            callback(...latestArgs);
        }
        else if (!fireAgain) {
            fireAgain = true;
        }
    };
}
exports.throttle = throttle;
async function retry({ maxRetryCount, functionToCall, retryInterval = 1000, }) {
    let retryCount = 0;
    let lastError;
    while (retryCount < maxRetryCount) {
        lastError = undefined;
        let result = undefined;
        try {
            result = await functionToCall();
        }
        catch (error) {
            lastError = error;
        }
        if (lastError || !result) {
            retryCount++;
            await (0, common_1.wait)(retryInterval);
        }
        else {
            return result;
        }
    }
    if (lastError) {
        throw new Error(`Retry attempts ('${maxRetryCount}') maxed: ${(0, common_1.extractErrorMessage)(lastError)}`);
    }
    else {
        throw new Error(`Retry attempts ('${maxRetryCount}') maxed.`);
    }
}
exports.retry = retry;
