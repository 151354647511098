"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchRawNftListingAndOffers = void 0;
const entrepot_data_api_1 = require("../../../api/entrepot-apis/entrepot-data-api");
const nft_id_1 = require("../../nft/nft-id");
const entrepot_canisters_1 = require("../../../api/entrepot-apis/entrepot-canisters");
const common_1 = require("@augment-vir/common");
async function fetchRawNftListingAndOffers(waitIndex, nftId) {
    if (waitIndex <= 0) {
        throw new Error(`Must provide positive wait index to prevent DOSing canisters.`);
    }
    await (0, common_1.wait)(waitIndex + (Math.random() * waitIndex || 1) / 10);
    if (nftId.startsWith('0fb0d53c19')) {
        console.log('here');
    }
    const rawNftOffers = await entrepot_canisters_1.entrepotCanisters.nftOffers.offers((0, nft_id_1.getExtNftId)(nftId));
    const rawNftListing = await fetch((0, entrepot_data_api_1.createCloudFunctionsEndpointUrl)([
        'token',
        nftId,
    ]))
        .then(response => {
        if (response.ok) {
            return response.json();
        }
        throw new Error('Something went wrong');
    })
        .then(json => {
        return json;
    })
        .catch(err => console.error(err));
    return { rawNftListing, rawNftOffers };
}
exports.fetchRawNftListingAndOffers = fetchRawNftListingAndOffers;
