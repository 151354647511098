"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntrepotSaleCategoryTabElement = void 0;
const element_vir_1 = require("element-vir");
const design_system_1 = require("@toniq-labs/design-system");
exports.EntrepotSaleCategoryTabElement = (0, element_vir_1.defineElement)()({
    tagName: 'toniq-entrepot-sale-category-tab',
    styles: (0, element_vir_1.css) `
        .header {
            margin-bottom: 16px;
            text-align: start;
        }

        .title {
            ${design_system_1.toniqFontStyles.boldFont};
            font-size: 20px;
            line-height: 28px;
            color: ${design_system_1.toniqColors.pagePrimary.foregroundColor};
        }

        .see-all {
            ${design_system_1.removeNativeFormStyles};
            font-weight: 500;
            color: ${design_system_1.toniqColors.pageInteraction.foregroundColor};
        }

        .tab-content {
            display: flex;
            flex-direction: column;
            gap: 52px;
            margin: 0 32px;
        }

        .items-container {
            gap: 24px;
            display: grid;
            justify-content: center;
            grid-template-columns: repeat(auto-fill, 644px);
        }

        @media (max-width: 1439px) {
            .items-container {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-evenly;
            }
        }
    `,
    events: {
        collectionSelected: (0, element_vir_1.defineElementEvent)(),
    },
    renderCallback: ({ inputs, dispatch, events }) => {
        return (0, element_vir_1.html) `
            <div class="tab-content">
                <div>
                    <div class="header">
                        <span class="title">${inputs.categoryName}</span>
                    </div>
                    <div class="items-container">${inputs.children}</div>
                </div>
            </div>
        `;
    },
});
