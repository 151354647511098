"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isProd = exports.environmentByUrl = void 0;
const environments_1 = require("./environments");
const devHosts = [
    'localhost',
    '127.0.0.1',
    'deploy-preview',
    // cspell:disable-next-line
    'friendly-raman-30db7b',
];
function getEnvironmentByUrl() {
    const hostName = window.location.hostname;
    if (devHosts.some(devHost => hostName.startsWith(devHost))) {
        return environments_1.EnvironmentEnum.Dev;
    }
    else if (hostName.startsWith('deploy-preview') && hostName.endsWith('netlify.app')) {
        return environments_1.EnvironmentEnum.DeployPreview;
    }
    return environments_1.EnvironmentEnum.Prod;
}
exports.environmentByUrl = getEnvironmentByUrl();
exports.isProd = exports.environmentByUrl === environments_1.EnvironmentEnum.Prod;
