"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.userMadeOffersCache = exports.makeUserOffersKey = void 0;
const define_local_cache_1 = require("../../define-local-cache");
const base_nft_1 = require("../../../nft/base-nft");
const fetch_raw_nft_listing_and_offers_1 = require("../fetch-raw-nft-listing-and-offers");
const entrepot_canisters_1 = require("../../../../api/entrepot-apis/entrepot-canisters");
function makeUserOffersKey({ userAccount, userIdentity }) {
    return `${userAccount.address}__${userIdentity.getPrincipal().toText()}`;
}
exports.makeUserOffersKey = makeUserOffersKey;
async function updateUserMadeOffers({ userAccount, userIdentity, }) {
    const offersMadeNftIds = await (0, entrepot_canisters_1.getEntrepotCanister)({ userIdentity }).nftOffers.offered();
    const userOfferedNfts = Promise.all(offersMadeNftIds.map(async (offerMadeNftId, index) => {
        const rawNftListingAndOffers = await (0, fetch_raw_nft_listing_and_offers_1.fetchRawNftListingAndOffers)(index + 1, offerMadeNftId);
        return (0, base_nft_1.parseRawNftData)(rawNftListingAndOffers);
    }));
    return userOfferedNfts;
}
exports.userMadeOffersCache = (0, define_local_cache_1.defineAutomaticallyUpdatingCache)({
    cacheName: 'user-offers',
    valueUpdater: updateUserMadeOffers,
    keyGenerator: makeUserOffersKey,
    subKeyRequirement: define_local_cache_1.SubKeyRequirementEnum.Required,
});
