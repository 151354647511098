"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntrepotTermsOfService = exports.EntrepotTermsOfServicePageElement = void 0;
const element_vir_1 = require("element-vir");
const terms_of_service_template_1 = require("./terms-of-service-template");
const wrap_native_element_1 = require("@toniq-labs/design-system/dist/esm/elements/wrap-native-element");
const design_system_1 = require("@toniq-labs/design-system");
exports.EntrepotTermsOfServicePageElement = (0, design_system_1.defineToniqElementNoInputs)({
    tagName: 'toniq-entrepot-terms-of-service-page',
    styles: (0, element_vir_1.css) `
        :host {
            display: block;
        }

        @media (max-width: 1200px) {
            :host {
                padding: 16px;
            }
        }
    `,
    renderCallback: () => {
        return (0, element_vir_1.html) `
            ${terms_of_service_template_1.termsOfServiceTemplate}
        `;
    },
});
exports.EntrepotTermsOfService = (0, wrap_native_element_1.wrapInReactComponent)(exports.EntrepotTermsOfServicePageElement);
