"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.removeUnknownKeys = void 0;
const common_1 = require("@augment-vir/common");
const cache_database_1 = require("./cache-database");
async function removeUnknownKeys(databaseTableName, knownKeys) {
    const database = await (0, cache_database_1.getEntrepotCacheDatabase)();
    try {
        const allValues = await database[databaseTableName].toArray();
        const toBeRemovedValues = allValues
            .map(value => {
            return knownKeys.includes(value.rowKey) ? undefined : value.rowKey;
        })
            .filter(common_1.isTruthy);
        if (toBeRemovedValues.length) {
            database[databaseTableName].bulkDelete(toBeRemovedValues);
        }
    }
    catch (error) {
        throw new Error(`Failed to remove unknown keys for "${databaseTableName}": ${(0, common_1.extractErrorMessage)(error)}`);
    }
}
exports.removeUnknownKeys = removeUnknownKeys;
