"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntrepotProfileNftCardElement = void 0;
const class_map_js_1 = require("lit/directives/class-map.js");
const element_vir_1 = require("element-vir");
const design_system_1 = require("@toniq-labs/design-system");
const toniq_entrepot_nft_card_element_1 = require("../../../common/toniq-entrepot-nft-card.element");
const icp_1 = require("../../../../../data/icp");
const common_1 = require("@augment-vir/common");
exports.EntrepotProfileNftCardElement = (0, design_system_1.defineToniqElement)()({
    tagName: 'toniq-entrepot-nft-profile-card',
    styles: (0, element_vir_1.css) `
        .footer-contents {
            display: flex;
            flex-direction: column;
        }

        p {
            margin: 16px 0;
            ${design_system_1.toniqFontStyles.h3Font}
        }

        .monospace {
            ${design_system_1.toniqFontStyles.monospaceFont}
        }

        .icp-price {
            display: flex;
            align-items: center;
            gap: 4px;
            ${design_system_1.toniqFontStyles.boldFont}
        }

        .button-row {
            display: flex;
            justify-content: space-between;
        }

        .rarity {
            ${design_system_1.toniqFontStyles.labelFont}
        }

        .left-side {
            display: flex;
            flex-direction: column;
            gap: 8px;
        }
    `,
    renderCallback: ({ inputs }) => {
        var _a, _b, _c, _d, _e, _f;
        const hasListing = ((_b = (_a = inputs.nft) === null || _a === void 0 ? void 0 : _a.listing) === null || _b === void 0 ? void 0 : _b.hasOwnProperty('price'))
            ? ((_d = (_c = inputs.nft) === null || _c === void 0 ? void 0 : _c.listing) === null || _d === void 0 ? void 0 : _d.price) > 0
            : false;
        const listPriceDisplay = hasListing
            ? (0, icp_1.toIcp)(BigInt((_f = (_e = inputs.nft) === null || _e === void 0 ? void 0 : _e.listing) === null || _f === void 0 ? void 0 : _f.price))
            : 'Unlisted';
        const rarityDisplay = (0, common_1.isRuntimeTypeOf)(inputs.nft.nftNri, 'number')
            ? `NRI: ${(inputs.nft.nftNri * 100).toFixed(1)}%`
            : (0, element_vir_1.html) `
                  &nbsp;
              `;
        return (0, element_vir_1.html) `
            <${toniq_entrepot_nft_card_element_1.EntrepotNftCardElement}
                ${(0, element_vir_1.assign)(toniq_entrepot_nft_card_element_1.EntrepotNftCardElement, {
            nft: inputs.nft,
        })}
            >
                <div class="footer-contents">
                    <p>
                        #${inputs.nft.nftMintNumber}
                    </p>
                    <div class="button-row">
                        <div class="left-side">
                            <div class=${(0, class_map_js_1.classMap)({
            'icp-price': true,
            monospace: !!hasListing,
        })}>
                                ${(0, element_vir_1.renderIf)(!!hasListing, (0, element_vir_1.html) `
                                        <${design_system_1.ToniqIcon}
                                            ${(0, element_vir_1.assign)(design_system_1.ToniqIcon, { icon: design_system_1.Icp16Icon })}
                                        ></${design_system_1.ToniqIcon}>
                                    `)}
                                <span>
                                    ${listPriceDisplay}
                                </span>
                            </div>
                            <div class="rarity">
                                ${rarityDisplay}
                            </div>
                        </div>
                        <div>
                            <slot></slot>
                        </div>
                    </div>
                </div>
            </${toniq_entrepot_nft_card_element_1.EntrepotNftCardElement}>
        `;
    },
});
