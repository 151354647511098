"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.bannerCanisters = void 0;
exports.bannerCanisters = [
    'pk6rk-6aaaa-aaaae-qaazq-cai',
    'bzsui-sqaaa-aaaah-qce2a-cai',
    'oeee4-qaaaa-aaaak-qaaeq-cai',
    'dhiaa-ryaaa-aaaae-qabva-cai',
    'skjpp-haaaa-aaaae-qac7q-cai',
    'rw623-hyaaa-aaaah-qctcq-cai',
    'e3izy-jiaaa-aaaah-qacbq-cai',
    'yrdz3-2yaaa-aaaah-qcvpa-cai',
    '3mttv-dqaaa-aaaah-qcn6q-cai',
    '5movr-diaaa-aaaak-aaftq-cai',
    'ugdkf-taaaa-aaaak-acoia-cai',
    'j3dqa-byaaa-aaaah-qcwfa-cai',
    'gtb2b-tiaaa-aaaah-qcxca-cai',
    '3vdxu-laaaa-aaaah-abqxa-cai',
    '6wih6-siaaa-aaaah-qczva-cai',
    '4fcza-biaaa-aaaah-abi4q-cai',
    'er7d4-6iaaa-aaaaj-qac2q-cai',
    's36wu-5qaaa-aaaah-qcyzq-cai',
    't2mog-myaaa-aaaal-aas7q-cai',
    'nbg4r-saaaa-aaaah-qap7a-cai',
    '2l7rh-eiaaa-aaaah-qcvaa-cai',
    'z7mqv-liaaa-aaaah-qcnqa-cai',
    'bapzn-kiaaa-aaaam-qaiva-cai',
];
