"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isStillAtDefaults = exports.countFiltersNotAtDefaults = void 0;
const common_1 = require("@augment-vir/common");
const filters_types_1 = require("./filters-types");
function countFiltersNotAtDefaults({ filters, defaultFilters, }) {
    return Object.keys(filters)
        .map(filterName => {
        const filter = filters[filterName];
        const defaultFilter = defaultFilters[filterName];
        return !isStillAtDefaults({ filter, defaultFilter });
    })
        .filter(common_1.isTruthy).length;
}
exports.countFiltersNotAtDefaults = countFiltersNotAtDefaults;
function isStillAtDefaults({ defaultFilter, filter, }) {
    if (defaultFilter.filterType === filters_types_1.FilterTypeEnum.Checkboxes &&
        filter.filterType === filters_types_1.FilterTypeEnum.Checkboxes) {
        return defaultFilter.checkboxes.every((checkbox, index) => {
            return checkbox.checked === filter.checkboxes[index].checked;
        });
    }
    else if (defaultFilter.filterType === filters_types_1.FilterTypeEnum.ExpandingList &&
        filter.filterType === filters_types_1.FilterTypeEnum.ExpandingList) {
        return compareExpandingList(defaultFilter, filter);
    }
    else if (defaultFilter.filterType === filters_types_1.FilterTypeEnum.NumericRange &&
        filter.filterType === filters_types_1.FilterTypeEnum.NumericRange) {
        return (defaultFilter.currentMax === filter.currentMax &&
            defaultFilter.currentMin === filter.currentMin &&
            defaultFilter.selectedType === filter.selectedType);
    }
    else if (defaultFilter.filterType === filters_types_1.FilterTypeEnum.Radio &&
        filter.filterType === filters_types_1.FilterTypeEnum.Radio) {
        return defaultFilter.value === filter.value;
    }
    else if (defaultFilter.filterType === filters_types_1.FilterTypeEnum.ImageToggles &&
        filter.filterType === filters_types_1.FilterTypeEnum.ImageToggles) {
        const defaultEnabledMap = Object.values(defaultFilter.entries).map(entry => entry.checked);
        const currentEnabledMap = Object.values(defaultFilter.entries).map(entry => entry.checked);
        return (0, common_1.areJsonEqual)(defaultEnabledMap, currentEnabledMap);
    }
    else {
        throw new Error(`Unsupported filter types for calculating if at default value: ${defaultFilter.filterType}, ${filter.filterType}`);
    }
}
exports.isStillAtDefaults = isStillAtDefaults;
function compareExpandingList(a, b) {
    return a.entries.every((aEntry, index) => {
        const bEntry = b.entries[index];
        return compareExpandingListEntries(aEntry, bEntry);
    });
}
function compareExpandingListEntries(a, b) {
    return a.checkboxes.every((checkbox, index) => {
        return checkbox.checked === b.checkboxes[index].checked;
    });
}
