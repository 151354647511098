"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.petBotsCanisterDetails = void 0;
const default_canister_details_1 = require("../default-canister-details");
exports.petBotsCanisterDetails = {
    collectionName: 'Pet Bots',
    canisterId: 't2mog-myaaa-aaaal-aas7q-cai',
    getNftLinkUrl({ originalCanisterId, nftId }) {
        return (0, default_canister_details_1.getDefaultNftImageData)({
            canisterId: originalCanisterId,
            nftId: nftId,
            fullSize: true,
        }).url;
    },
};
