"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createCollectionsFilter = void 0;
const filters_types_1 = require("../../../common/with-filters/filters-types");
function createCollectionsFilter({ selectedCollectionIds, collectionsExpanded, entries, }) {
    const uniqueCollections = new Map();
    entries.forEach(entry => {
        if (entry.collection) {
            const collectionEntry = uniqueCollections.get(entry.collectionId);
            if (collectionEntry) {
                collectionEntry.count++;
            }
            else {
                uniqueCollections.set(entry.collectionId, {
                    collection: entry.collection,
                    count: 1,
                });
            }
        }
    });
    const collectionEntries = Object.fromEntries(Array.from(uniqueCollections.values())
        .sort((a, b) => a.count - b.count)
        .map((entry) => {
        return [
            entry.collection.name,
            {
                checked: selectedCollectionIds.includes(entry.collection.id),
                count: entry.count,
                filterValue: entry.collection.id,
                imageUrl: entry.collection.avatar,
            },
        ];
    }));
    return {
        filterType: filters_types_1.FilterTypeEnum.ImageToggles,
        allEntriesTitle: 'All Collections',
        entries: collectionEntries,
        expanded: collectionsExpanded,
        filterField: ['collectionId'],
    };
}
exports.createCollectionsFilter = createCollectionsFilter;
