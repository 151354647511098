"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.initializeSentry = exports.toniqIoSentryConfig = void 0;
const Sentry = __importStar(require("@sentry/browser"));
const environment_by_url_1 = require("../../environment/environment-by-url");
const browser_1 = require("@sentry/browser");
const common_1 = require("@augment-vir/common");
const log_levels_1 = require("./log-levels");
// this is a public key, it does not need to be a secret
const toniqIoSentryDsn = 'https://40c7d3bf235f49e5a2092036feeb8191@o4504718034927616.ingest.sentry.io/4505037528629248';
const errorLevels = [];
exports.toniqIoSentryConfig = {
    dsn: toniqIoSentryDsn,
    defaultIntegrations: false,
    environment: environment_by_url_1.environmentByUrl,
    enabled: true,
    integrations: [
        new browser_1.HttpContext(),
        new browser_1.Dedupe(),
    ],
    beforeSend(event, hint) {
        const consoleMethod = (0, log_levels_1.getEventConsoleLogFunction)(event);
        if (environment_by_url_1.isProd) {
            consoleMethod('sending to Sentry:', hint.originalException, hint.captureContext);
            return event;
        }
        else {
            consoleMethod('would have sent to Sentry:', hint.originalException, hint.captureContext);
            return null;
        }
    },
    beforeSendTransaction() {
        return null;
    },
};
function initializeSentry() {
    try {
        Sentry.init(exports.toniqIoSentryConfig);
        return Sentry;
    }
    catch (caught) {
        const error = (0, common_1.ensureError)(caught);
        error.message = 'Sentry init failed: ' + error.message;
        console.error(error);
        return undefined;
    }
}
exports.initializeSentry = initializeSentry;
