"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntrepotProfile = exports.EntrepotProfilePageElement = void 0;
const class_map_js_1 = require("lit/directives/class-map.js");
const wrap_native_element_1 = require("@toniq-labs/design-system/dist/esm/elements/wrap-native-element");
const element_vir_1 = require("element-vir");
const toniq_entrepot_with_filters_element_1 = require("../../common/with-filters/toniq-entrepot-with-filters.element");
const toniq_entrepot_page_header_element_1 = require("../../common/toniq-entrepot-page-header.element");
const design_system_1 = require("@toniq-labs/design-system");
const toniq_entrepot_top_tabs_element_1 = require("../../common/toniq-entrepot-top-tabs.element");
const profile_tabs_1 = require("./profile-page-state/profile-tabs");
const profile_page_state_1 = require("./profile-page-state/profile-page-state");
const create_profile_filters_1 = require("./profile-page-state/create-profile-filters");
const overall_profile_stats_1 = require("./profile-page-state/overall-profile-stats");
const filters_types_1 = require("../../common/with-filters/filters-types");
const common_1 = require("@augment-vir/common");
const toniq_entrepot_profile_nft_list_item_text_items_element_1 = require("./profile-nft-card-element/toniq-entrepot-profile-nft-list-item-text-items.element");
const profile_page_filter_definitions_1 = require("./profile-page-state/profile-page-filter-definitions");
exports.EntrepotProfilePageElement = (0, design_system_1.defineToniqElement)()({
    tagName: 'toniq-entrepot-profile-page',
    styles: (0, element_vir_1.css) `
        :host {
            display: flex;
            flex-direction: column;
            min-height: 100vh;
        }

        ${toniq_entrepot_top_tabs_element_1.EntrepotTopTabsElement} {
            margin: 0 32px;
            max-width: 100%;
        }

        @media (max-width: 1200px) {
            :host {
                padding: 16px;
            }
        }

        .view-style-controls {
            display: flex;
            gap: 8px;
            align-items: center;
        }

        .view-style-controls ${design_system_1.ToniqIcon} {
            color: ${design_system_1.toniqColors.pageTertiary.foregroundColor};
            transition: ${design_system_1.interactionDuration};
        }

        .view-style-controls button {
            ${design_system_1.removeNativeFormStyles}
        }

        .view-style-controls ${design_system_1.ToniqIcon}.current-view-style-icon {
            color: ${design_system_1.toniqColors.pagePrimary.foregroundColor};
        }

        ${toniq_entrepot_profile_nft_list_item_text_items_element_1.EntrepotProfileNftListItemTextItemsElement} {
            width: 100%;
        }

        .tab-wrapper {
            position: relative;
        }

        .powered-nft-geek {
            top: 0;
            position: absolute;
            bottom: 0;
            right: 32px;
            margin: auto 0;
            height: min-content;
        }

        @media (max-width: 800px) {
            .powered-nft-geek {
                display: none;
            }
        }
    `,
    events: {
        sellClick: (0, element_vir_1.defineElementEvent)(),
        transferClick: (0, element_vir_1.defineElementEvent)(),
        nftClick: (0, element_vir_1.defineElementEvent)(),
    },
    stateInit: profile_page_state_1.profilePageStateInit,
    initCallback: ({ inputs, state, updateState }) => {
        (0, profile_page_state_1.initProfileElement)({ inputs, state, updateState });
    },
    renderCallback: ({ inputs, state, updateState, dispatch, events }) => {
        if (state.currentProfileTab.value === profile_tabs_1.profileTabMap['my-nfts'].value) {
            updateState((0, profile_page_state_1.createAsyncProfileStateUpdateOwnedNfts)({ inputs }));
        }
        else if (state.currentProfileTab.value === profile_tabs_1.profileTabMap['favorites'].value) {
            updateState((0, profile_page_state_1.createAsyncProfileStateUpdateFavorites)({ inputs }));
        }
        else if (state.currentProfileTab.value === profile_tabs_1.profileTabMap['offers'].value) {
            updateState((0, profile_page_state_1.createAsyncProfileStateUpdateOffers)({ inputs }));
        }
        else if (state.currentProfileTab.value === profile_tabs_1.profileTabMap['activity'].value) {
            updateState((0, profile_page_state_1.createAsyncProfileStateUpdateActivity)({ inputs }));
        }
        updateState((0, profile_page_state_1.createAsyncProfileStateUpdate)({ state }));
        updateState((0, profile_page_state_1.createAsyncProfileStateUpdateOwnedNftsStat)({ inputs }));
        const filterInputs = (0, create_profile_filters_1.createProfileFilterInputs)({
            currentProfilePageState: { ...state },
            collectionMap: inputs.collectionMap,
            sellCallback: nft => {
                dispatch(new events.sellClick(nft));
            },
            transferCallback: nft => {
                dispatch(new events.transferClick(nft));
            },
            nftClickCallback: nft => {
                dispatch(new events.nftClick(nft));
            },
            userAccount: inputs.userAccount,
        });
        const extraControlsTemplate = (0, element_vir_1.html) `
            <div class="view-style-controls" slot="extra-controls">
                <button ${(0, element_vir_1.listen)('click', () => {
            updateState({
                viewStyle: profile_page_state_1.ProfileViewStyleEnum.Grid,
            });
        })}>
                    <${design_system_1.ToniqIcon}
                        ${(0, element_vir_1.assign)(design_system_1.ToniqIcon, {
            icon: design_system_1.LayoutGrid24Icon,
        })}
                        class=${(0, class_map_js_1.classMap)({
            'current-view-style-icon': state.viewStyle === profile_page_state_1.ProfileViewStyleEnum.Grid,
        })}
                    ></${design_system_1.ToniqIcon}>
                </button>
                <button ${(0, element_vir_1.listen)('click', () => {
            updateState({
                viewStyle: profile_page_state_1.ProfileViewStyleEnum.List,
            });
        })}>
                    <${design_system_1.ToniqIcon}
                        ${(0, element_vir_1.assign)(design_system_1.ToniqIcon, {
            icon: design_system_1.ListDetails24Icon,
        })}
                        class=${(0, class_map_js_1.classMap)({
            'current-view-style-icon': state.viewStyle === profile_page_state_1.ProfileViewStyleEnum.List,
        })}
                    ></${design_system_1.ToniqIcon}>
                </button>
            </div>
        `;
        return (0, element_vir_1.html) `
            <${toniq_entrepot_page_header_element_1.EntrepotPageHeaderElement}
                ${(0, element_vir_1.assign)(toniq_entrepot_page_header_element_1.EntrepotPageHeaderElement, {
            headerText: 'My Profile',
        })}
            ></${toniq_entrepot_page_header_element_1.EntrepotPageHeaderElement}>
            ${(0, overall_profile_stats_1.createOverallStatsTemplate)(state, inputs.collectionMap)}
            <div class="tab-wrapper">
                <span class="powered-nft-geek">Data powered by NFT Geek</span>
                <${toniq_entrepot_top_tabs_element_1.EntrepotTopTabsElement}
                    ${(0, element_vir_1.assign)(toniq_entrepot_top_tabs_element_1.EntrepotTopTabsElement, {
            selected: state.currentProfileTab,
            tabs: (0, profile_tabs_1.getAllowedTabs)({ isToniqEarnAllowed: inputs.isToniqEarnAllowed }),
        })}
                    ${(0, element_vir_1.listen)(toniq_entrepot_top_tabs_element_1.EntrepotTopTabsElement.events.tabChange, event => {
            if (event.detail === profile_tabs_1.profileTabMap['activity']) {
                updateState({
                    viewStyle: profile_page_state_1.ProfileViewStyleEnum.List,
                });
            }
            else {
                updateState({
                    viewStyle: profile_page_state_1.ProfileViewStyleEnum.Grid,
                });
            }
            updateState({
                currentProfileTab: event.detail,
            });
        })}
                ></${toniq_entrepot_top_tabs_element_1.EntrepotTopTabsElement}>
            </div>
            <${toniq_entrepot_with_filters_element_1.EntrepotWithFiltersElement}
                ${(0, element_vir_1.assign)(toniq_entrepot_with_filters_element_1.EntrepotWithFiltersElement, filterInputs)}
                ${(0, element_vir_1.listen)(toniq_entrepot_with_filters_element_1.EntrepotWithFiltersElement.events.showFiltersChange, event => {
            updateState({ showFilters: event.detail });
        })}
                ${(0, element_vir_1.listen)(toniq_entrepot_with_filters_element_1.EntrepotWithFiltersElement.events.filtersChange, event => {
            const collectionFilter = event.detail.Collections;
            if ((collectionFilter === null || collectionFilter === void 0 ? void 0 : collectionFilter.filterType) === filters_types_1.FilterTypeEnum.ImageToggles) {
                const selectedCollectionIds = Object.values(collectionFilter.entries)
                    .map(filterEntry => {
                    if (filterEntry.checked) {
                        return filterEntry.filterValue;
                    }
                    else {
                        return undefined;
                    }
                })
                    .filter(common_1.isTruthy);
                updateState({
                    collectionsFilterExpanded: collectionFilter.expanded,
                    selectedCollections: {
                        ...state.selectedCollections,
                        [state.currentProfileTab.value]: selectedCollectionIds,
                    },
                });
            }
            updateState({
                allFilters: {
                    ...state.allFilters,
                    [profile_page_filter_definitions_1.filterSortKeyByTab[state.currentProfileTab.value]]: event.detail,
                },
            });
        })}
                ${(0, element_vir_1.listen)(toniq_entrepot_with_filters_element_1.EntrepotWithFiltersElement.events.sortChange, event => {
            updateState({
                allSorts: {
                    ...state.allSorts,
                    [profile_page_filter_definitions_1.filterSortKeyByTab[state.currentProfileTab.value]]: event.detail,
                },
            });
        })}
            >
                ${extraControlsTemplate}
                ${(0, element_vir_1.renderIf)(state.viewStyle === profile_page_state_1.ProfileViewStyleEnum.List, (0, element_vir_1.html) `
                        <${toniq_entrepot_profile_nft_list_item_text_items_element_1.EntrepotProfileNftListItemTextItemsElement}
                            slot="entries-header"
                            ${(0, element_vir_1.assign)(toniq_entrepot_profile_nft_list_item_text_items_element_1.EntrepotProfileNftListItemTextItemsElement, {
            isHeader: true,
            finalItemHeaderTitle: profile_page_state_1.listViewFinalItemHeaderTitleByTab[state.currentProfileTab.value],
        })}
                        ></${toniq_entrepot_profile_nft_list_item_text_items_element_1.EntrepotProfileNftListItemTextItemsElement}>
                    `)}
            </${toniq_entrepot_with_filters_element_1.EntrepotWithFiltersElement}>
        `;
    },
});
exports.EntrepotProfile = (0, wrap_native_element_1.wrapInReactComponent)(exports.EntrepotProfilePageElement);
