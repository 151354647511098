"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.icatsCanisterDetails = void 0;
const ic_canister_domain_1 = require("../../../../api/ic-canister-domain");
exports.icatsCanisterDetails = {
    collectionName: 'ICats',
    canisterId: {
        original: '4nvhy-3qaaa-aaaah-qcnoq-cai',
        extWrapped: 'y3b7h-siaaa-aaaah-qcnwa-cai',
    },
    getNftLinkUrl({ originalCanisterId, nftIndex }) {
        return [
            (0, ic_canister_domain_1.getCanisterDomain)(originalCanisterId),
            'Token',
            nftIndex,
        ].join('/');
    },
};
