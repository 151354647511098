"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isWrappedType = exports.getCanisterWrappedType = exports.CanisterWrappedType = exports.getOriginalCanisterId = exports.getExtCanisterId = void 0;
const common_1 = require("@augment-vir/common");
const all_canister_details_1 = require("./all-canister-details");
/** Or, in other words, get the wrapped canister id. */
function getExtCanisterId(canisterId) {
    const details = (0, all_canister_details_1.getCanisterDetails)(canisterId);
    if (!details) {
        return canisterId;
    }
    return details.extCanisterId;
}
exports.getExtCanisterId = getExtCanisterId;
function getOriginalCanisterId(canisterId) {
    const details = (0, all_canister_details_1.getCanisterDetails)(canisterId);
    if (!details) {
        return canisterId;
    }
    if ((0, common_1.isRuntimeTypeOf)(details.canisterId, 'string')) {
        return details.canisterId;
    }
    else {
        return details.canisterId.original;
    }
}
exports.getOriginalCanisterId = getOriginalCanisterId;
var CanisterWrappedType;
(function (CanisterWrappedType) {
    // has no wrapping going on
    CanisterWrappedType["ExtOnly"] = "ext-only";
    // the original, unwrapped, version of the wrapped canister
    CanisterWrappedType["UnwrappedOriginal"] = "wrapped-original";
    // the EXT version of the wrapped canister
    CanisterWrappedType["WrappedExt"] = "wrapped-ext";
})(CanisterWrappedType = exports.CanisterWrappedType || (exports.CanisterWrappedType = {}));
function getCanisterWrappedType(canisterId) {
    const details = (0, all_canister_details_1.getCanisterDetails)(canisterId);
    if (!details || (0, common_1.isRuntimeTypeOf)(details.canisterId, 'string')) {
        return CanisterWrappedType.ExtOnly;
    }
    if (canisterId === details.canisterId.extWrapped) {
        return CanisterWrappedType.WrappedExt;
    }
    else if (canisterId === details.canisterId.original) {
        return CanisterWrappedType.UnwrappedOriginal;
    }
    else {
        // this should never happen but this covers it just in case
        throw new Error(`Got details for wrapped canister '${canisterId}' but it matches none of the internal canister ids.`);
    }
}
exports.getCanisterWrappedType = getCanisterWrappedType;
function isWrappedType(canisterId, type) {
    return getCanisterWrappedType(canisterId) === type;
}
exports.isWrappedType = isWrappedType;
