"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.moonWalkersCanisterDetails = void 0;
const element_vir_1 = require("element-vir");
exports.moonWalkersCanisterDetails = {
    collectionName: 'MoonWalkers',
    canisterId: 'er7d4-6iaaa-aaaaj-qac2q-cai',
    getNftImageData({ fullSize, nftLinkUrl }) {
        if (fullSize) {
            return {
                url: nftLinkUrl,
                forcedOriginalImageSize: {
                    width: 375,
                    height: 500,
                },
                extraHtml: (0, element_vir_1.html) `
                    <script>
                        document.querySelector('img').style.width = 'unset';
                    </script>
                `,
            };
        }
        else {
            return undefined;
        }
    },
};
