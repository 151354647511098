"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isEntriesType = exports.isNftType = void 0;
function isNftType(nft, tabValue, state) {
    return state.currentProfileTab.value === tabValue;
}
exports.isNftType = isNftType;
function isEntriesType(entries, tabValue, state) {
    return state.currentProfileTab.value === tabValue;
}
exports.isEntriesType = isEntriesType;
