"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntrepotSaleFeatureTabElement = void 0;
const element_vir_1 = require("element-vir");
const design_system_1 = require("@toniq-labs/design-system");
const toniq_entrepot_horizontal_scroll_element_1 = require("../../../common/toniq-entrepot-horizontal-scroll.element");
const toniq_entrepot_sale_card_element_1 = require("../toniq-entrepot-sale-card.element");
exports.EntrepotSaleFeatureTabElement = (0, element_vir_1.defineElement)()({
    tagName: 'toniq-entrepot-sale-feature-tab',
    styles: (0, element_vir_1.css) `
        .header {
            display: flex;
            justify-content: space-between;
        }

        .title {
            ${design_system_1.toniqFontStyles.boldFont};
            font-size: 20px;
            line-height: 28px;
            color: ${design_system_1.toniqColors.pagePrimary.foregroundColor};
        }

        .see-all {
            ${design_system_1.removeNativeFormStyles};
            font-weight: 500;
            color: ${design_system_1.toniqColors.pageInteraction.foregroundColor};
        }

        .tab-content {
            display: flex;
            flex-direction: column;
            gap: 52px;
            margin: 0 32px;
        }
    `,
    events: {
        collectionSelected: (0, element_vir_1.defineElementEvent)(),
    },
    renderCallback: ({ inputs, dispatch, events }) => {
        function goToTab(tab) {
            inputs.updateState({ saleSelectedTab: tab });
            window.scrollTo({ behavior: 'smooth', top: 0 });
        }
        return (0, element_vir_1.html) `
            <div class="tab-content">
                ${inputs.upcoming.length
            ? (0, element_vir_1.html) `
                <div>
                    <div class="header">
                        <span class="title">Upcoming</span>
                        <button
                            class="see-all"
                            @click=${() => goToTab({ label: 'Upcoming', value: 'upcoming' })}
                        >
                            See All
                        </button>
                    </div>
                    <${toniq_entrepot_horizontal_scroll_element_1.EntrepotHorizontalScrollElement}
                        ${(0, element_vir_1.assign)(toniq_entrepot_horizontal_scroll_element_1.EntrepotHorizontalScrollElement, {
                children: (0, element_vir_1.html) `
                                ${inputs.upcoming.map(collection => {
                    return (0, element_vir_1.html) `
                                        <${toniq_entrepot_sale_card_element_1.EntrepotSaleCardElement}
                                            ${(0, element_vir_1.assign)(toniq_entrepot_sale_card_element_1.EntrepotSaleCardElement, {
                        collectionImageUrl: collection.collection,
                        collectionName: collection.name,
                        descriptionText: collection.brief,
                        date: collection.sales.startDate,
                        dateMessage: 'Just Launched',
                        statsArray: [
                            {
                                title: 'PRICE',
                                icon: design_system_1.Icp16Icon,
                                stat: collection.sales.salePrice,
                            },
                            {
                                title: 'SIZE',
                                stat: collection.sales.quantity,
                            },
                            {
                                title: 'FOR SALE',
                                stat: collection.sales.remaining,
                            },
                        ],
                    })}
                                            ${(0, element_vir_1.listen)('click', () => {
                        dispatch(new events.collectionSelected(collection));
                    })}
                                        ></${toniq_entrepot_sale_card_element_1.EntrepotSaleCardElement}>
                                    `;
                })}
                            `,
                maxCardHeight: 460,
            })}
                    ></${toniq_entrepot_horizontal_scroll_element_1.EntrepotHorizontalScrollElement}>
                </div>`
            : ''}
                ${inputs.inProgress.length
            ? (0, element_vir_1.html) `
                <div>
                    <div class="header">
                        <span class="title">In Progress</span>
                        <button
                            class="see-all"
                            @click=${() => goToTab({ label: 'In Progress', value: 'inProgress' })}
                        >
                            See All
                        </button>
                    </div>
                    <${toniq_entrepot_horizontal_scroll_element_1.EntrepotHorizontalScrollElement}
                        ${(0, element_vir_1.assign)(toniq_entrepot_horizontal_scroll_element_1.EntrepotHorizontalScrollElement, {
                children: (0, element_vir_1.html) `
                                ${inputs.inProgress.map(collection => {
                    return (0, element_vir_1.html) `
                                        <${toniq_entrepot_sale_card_element_1.EntrepotSaleCardElement}
                                            ${(0, element_vir_1.assign)(toniq_entrepot_sale_card_element_1.EntrepotSaleCardElement, {
                        collectionImageUrl: collection.collection,
                        collectionName: collection.name,
                        descriptionText: collection.brief,
                        date: collection.sales.endDate,
                        statsArray: [
                            {
                                title: 'PRICE',
                                icon: design_system_1.Icp16Icon,
                                stat: collection.sales.salePrice,
                            },
                            {
                                title: 'SOLD',
                                stat: Number(collection.sales.quantity) -
                                    Number(collection.sales.remaining),
                            },
                            {
                                title: 'REMAINING',
                                stat: collection.sales.remaining,
                            },
                        ],
                        progress: collection.sales.percentMinted,
                    })}
                                            ${(0, element_vir_1.listen)('click', () => {
                        dispatch(new events.collectionSelected(collection));
                    })}
                                        ></${toniq_entrepot_sale_card_element_1.EntrepotSaleCardElement}>
                                    `;
                })}
                            `,
            })}
                    ></${toniq_entrepot_horizontal_scroll_element_1.EntrepotHorizontalScrollElement}>
                </div>`
            : ''}
                ${inputs.endingSoon.length
            ? (0, element_vir_1.html) `
                <div>
                    <div class="header">
                        <span class="title">Ending Soon</span>
                        <button
                            class="see-all"
                            @click=${() => goToTab({ label: 'Ending Soon', value: 'endingSoon' })}
                        >
                            See All
                        </button>
                    </div>
                    <${toniq_entrepot_horizontal_scroll_element_1.EntrepotHorizontalScrollElement}
                        ${(0, element_vir_1.assign)(toniq_entrepot_horizontal_scroll_element_1.EntrepotHorizontalScrollElement, {
                children: (0, element_vir_1.html) `
                                ${inputs.endingSoon.map(collection => {
                    return (0, element_vir_1.html) `
                                        <${toniq_entrepot_sale_card_element_1.EntrepotSaleCardElement}
                                            ${(0, element_vir_1.assign)(toniq_entrepot_sale_card_element_1.EntrepotSaleCardElement, {
                        collectionImageUrl: collection.collection,
                        collectionName: collection.name,
                        descriptionText: collection.brief,
                        date: collection.sales.endDate,
                        statsArray: [
                            {
                                title: 'PRICE',
                                icon: design_system_1.Icp16Icon,
                                stat: collection.sales.salePrice,
                            },
                            {
                                title: 'SOLD',
                                stat: Number(collection.sales.quantity) -
                                    Number(collection.sales.remaining),
                            },
                            {
                                title: 'REMAINING',
                                stat: collection.sales.remaining,
                            },
                        ],
                        progress: collection.sales.percentMinted,
                    })}
                                            ${(0, element_vir_1.listen)('click', () => {
                        dispatch(new events.collectionSelected(collection));
                    })}
                                        ></${toniq_entrepot_sale_card_element_1.EntrepotSaleCardElement}>
                                    `;
                })}
                            `,
            })}
                    ></${toniq_entrepot_horizontal_scroll_element_1.EntrepotHorizontalScrollElement}>
                </div>`
            : ''}
            </div>
        `;
    },
});
