"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.imaginationProjectCanisterDetails = void 0;
const default_canister_details_1 = require("../default-canister-details");
const element_vir_1 = require("element-vir");
exports.imaginationProjectCanisterDetails = {
    collectionName: 'Imagination Project',
    canisterId: 'px5ub-qqaaa-aaaah-qcjxa-cai',
    getNftImageData({ originalCanisterId, nftId, fullSize }) {
        if (!fullSize) {
            return undefined;
        }
        const imageData = (0, default_canister_details_1.getDefaultNftImageData)({
            canisterId: originalCanisterId,
            nftId: nftId,
            fullSize: true,
        });
        return {
            ...imageData,
            htmlSizeQuerySelector: '.front img',
            extraHtml: (0, element_vir_1.html) `
                <script>
                    executeBeforeSizing = () => {
                        const img = document.querySelector('.front img');

                        if (img) {
                            const size = {
                                width: img.naturalWidth,
                                height: img.naturalHeight,
                            };

                            const mustChange = [
                                '.container',
                                '.box',
                            ];

                            mustChange.forEach(mustChangeQuery => {
                                const element = document.querySelector(mustChangeQuery);
                                if (element) {
                                    element.style.height = img.height + 'px';
                                    element.style.width = img.width + 'px';
                                }
                            });
                        }
                    };
                </script>
            `,
        };
    },
};
