"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntrepotFlipCardElement = void 0;
const element_vir_1 = require("element-vir");
const drop_shadow_card_style_1 = require("../styles/drop-shadow-card.style");
const class_map_js_1 = require("lit/directives/class-map.js");
const design_system_1 = require("@toniq-labs/design-system");
exports.EntrepotFlipCardElement = (0, design_system_1.defineToniqElement)()({
    tagName: 'toniq-entrepot-flip-card',
    events: {
        flipChange: (0, element_vir_1.defineElementEvent)(),
    },
    styles: (0, element_vir_1.css) `
        :host {
            display: inline-block;
            perspective: 1200px;
            position: relative;
            border-radius: 16px;
            box-sizing: border-box;
        }

        * {
            border-radius: inherit;
            box-sizing: border-box;
        }

        .flip-wrapper {
            height: 100%;
            width: 100%;
            position: relative;
            transition: transform 600ms;
            transform-style: preserve-3d;
            will-change: transform;
        }

        .flip-wrapper.flipped {
            transform: rotateY(180deg);
        }

        ${(0, drop_shadow_card_style_1.makeDropShadowCardStyles)('.card-face', false)}

        .card-face {
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            padding: 0;
            overflow: hidden;
            backface-visibility: hidden;
            background-color: ${design_system_1.toniqColors.pagePrimary.backgroundColor};
        }

        .card-face.back {
            position: absolute;
            transform: rotateY(180deg);
        }
    `,
    renderCallback: ({ inputs, dispatch, events }) => {
        return (0, element_vir_1.html) `
            <div
                class="flip-wrapper ${(0, class_map_js_1.classMap)({ flipped: inputs.flipped })}"
                ${(0, element_vir_1.listen)('click', () => {
            dispatch(new events.flipChange(!inputs.flipped));
        })}
            >
                <div class="card-face front"><slot name="front"></slot></div>
                <div class="card-face back"><slot name="back"></slot></div>
            </div>
        `;
    },
});
