"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntrepotExpandingListFilterElement = void 0;
const class_map_js_1 = require("lit/directives/class-map.js");
const element_vir_1 = require("element-vir");
const design_system_1 = require("@toniq-labs/design-system");
exports.EntrepotExpandingListFilterElement = (0, design_system_1.defineToniqElement)()({
    tagName: 'toniq-entrepot-expanding-list-template',
    events: {
        entryChange: (0, element_vir_1.defineElementEvent)(),
    },
    stateInit: {
        searchTerm: '',
    },
    styles: (0, element_vir_1.css) `
        :host {
            display: flex;
            flex-direction: column;
        }

        .title-row {
            ${design_system_1.removeNativeFormStyles}
            ${design_system_1.toniqFontStyles.paragraphFont}
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            margin: 0;
        }

        .title-row-suffix {
            display: flex;
            gap: 8px;
        }

        .selected-amount {
            display: flex;
            min-height: 24px;
            min-width: 24px;
            border-radius: 12px;
            justify-content: center;
            align-items: center;
            ${design_system_1.toniqFontStyles.boldLabelFont}
            ${(0, design_system_1.applyBackgroundAndForeground)(design_system_1.toniqColors.accentPrimary)}
        }

        .expandable-list {
            display: flex;
            flex-direction: column;
            gap: 16px;
            max-height: 0;
            overflow: hidden;
        }

        .expandable-list.expanded {
            max-height: unset;
            margin-bottom: 16px;
        }

        ${design_system_1.ToniqInput} {
            margin: 24px 8px 8px;
        }

        .hidden {
            display: none;
        }
    `,
    renderCallback: ({ inputs, state, updateState, events, dispatch }) => {
        const selectedCount = inputs.expandingListEntry.checkboxes.reduce((accum, checkbox) => accum + Number(checkbox.checked), 0);
        const filteredCheckboxes = inputs.expandingListEntry.checkboxes.filter(checkbox => {
            if (state.searchTerm) {
                return checkbox.label.toLowerCase().includes(state.searchTerm.toLowerCase());
            }
            else {
                return true;
            }
        });
        return (0, element_vir_1.html) `
            <button class="title-row"
                ${(0, element_vir_1.listen)('click', () => {
            dispatch(new events.entryChange({
                ...inputs.expandingListEntry,
                expanded: !inputs.expandingListEntry.expanded,
            }));
        })}
            >
                <span class="title">
                    ${inputs.expandingListEntry.name}
                    (${inputs.expandingListEntry.checkboxes.length})
                </span>
                <div class="title-row-suffix">
                    <div class="selected-amount ${(0, class_map_js_1.classMap)({ hidden: selectedCount === 0 })}">
                        <span>${selectedCount}</span>
                    </div>
                    <${design_system_1.ToniqIcon}
                        ${(0, element_vir_1.assign)(design_system_1.ToniqIcon, {
            icon: inputs.expandingListEntry.expanded
                ? design_system_1.ChevronUp24Icon
                : design_system_1.ChevronDown24Icon,
        })}
                    ></${design_system_1.ToniqIcon}>
                </div>
            </button>
            <div
                class="expandable-list ${(0, class_map_js_1.classMap)({
            expanded: inputs.expandingListEntry.expanded,
        })}"
            >
                <${design_system_1.ToniqInput}
                    ${(0, element_vir_1.assign)(design_system_1.ToniqInput, {
            value: state.searchTerm,
            placeholder: 'Search....',
            icon: design_system_1.Search24Icon,
            disableBrowserHelps: true,
        })}
                    ${(0, element_vir_1.listen)(design_system_1.ToniqInput.events.valueChange, event => {
            updateState({
                searchTerm: event.detail,
            });
        })}
                ></${design_system_1.ToniqInput}>
                ${filteredCheckboxes.map((checkbox, index) => {
            return (0, element_vir_1.html) `
                        <${design_system_1.ToniqCheckbox}
                            ${(0, element_vir_1.assign)(design_system_1.ToniqCheckbox, {
                checked: checkbox.checked,
                text: checkbox.label,
            })}
                            ${(0, element_vir_1.listen)(design_system_1.ToniqCheckbox.events.checkedChange, event => {
                const checkboxes = [...inputs.expandingListEntry.checkboxes];
                checkboxes[index] = { ...checkbox, checked: event.detail };
                dispatch(new events.entryChange({
                    ...inputs.expandingListEntry,
                    checkboxes,
                }));
            })}
                        ></${design_system_1.ToniqCheckbox}>
                    `;
        })}
        </div>
        `;
    },
});
