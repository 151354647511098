"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntrepotSocialLinkElement = exports.SocialLinkTypeEnum = void 0;
const design_system_1 = require("@toniq-labs/design-system");
const element_vir_1 = require("element-vir");
var SocialLinkTypeEnum;
(function (SocialLinkTypeEnum) {
    SocialLinkTypeEnum["Twitter"] = "twitter";
    SocialLinkTypeEnum["Discord"] = "discord";
    SocialLinkTypeEnum["Telegram"] = "telegram";
})(SocialLinkTypeEnum = exports.SocialLinkTypeEnum || (exports.SocialLinkTypeEnum = {}));
const socialImageUrls = {
    [SocialLinkTypeEnum.Twitter]: design_system_1.BrandTwitter24Icon,
    [SocialLinkTypeEnum.Discord]: design_system_1.BrandDiscord24Icon,
    [SocialLinkTypeEnum.Telegram]: design_system_1.BrandTelegram24Icon,
};
exports.EntrepotSocialLinkElement = (0, design_system_1.defineToniqElement)()({
    tagName: 'toniq-entrepot-social-link',
    styles: (0, element_vir_1.css) ``,
    renderCallback: ({ inputs }) => {
        return (0, element_vir_1.html) `
            <a href=${inputs.socialLinkDetails.link}>
                <${design_system_1.ToniqIcon}
                    ${(0, element_vir_1.assign)(design_system_1.ToniqIcon, { icon: socialImageUrls[inputs.socialLinkDetails.type] })}
                ></${design_system_1.ToniqIcon}>
            </a>
        `;
    },
});
