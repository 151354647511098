"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getNftImageData = void 0;
const all_canister_details_1 = require("./canister-details/all-canister-details");
const treasure_canister_1 = require("./treasure-canister");
async function getNftImageData({ collectionId, fullSize, nftId, nftIndex, cachePriority, ref, }) {
    if (collectionId === treasure_canister_1.treasureCanisterId) {
        if (!fullSize) {
            return { url: '/earn/loading.png' };
        }
    }
    const collectionCanisterDetails = (0, all_canister_details_1.getCanisterDetails)(collectionId);
    return await collectionCanisterDetails.getNftImageData({
        fullSize,
        nftId,
        nftIndex,
        priority: Number(cachePriority) || 0,
        ref: Number(ref) || 0,
    });
}
exports.getNftImageData = getNftImageData;
