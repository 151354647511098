"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntrepotPageHeaderElement = void 0;
const element_vir_1 = require("element-vir");
const design_system_1 = require("@toniq-labs/design-system");
exports.EntrepotPageHeaderElement = (0, design_system_1.defineToniqElement)()({
    tagName: 'toniq-entrepot-page-header',
    styles: (0, element_vir_1.css) `
        h1 {
            ${design_system_1.toniqFontStyles.h1Font}
            ${design_system_1.toniqFontStyles.extraBoldFont}
            margin: 32px 32px 8px;
        }

        @media (max-width: 1200px) {
            h1 {
                ${design_system_1.toniqFontStyles.h2Font}
                ${design_system_1.toniqFontStyles.extraBoldFont}
            }
        }
    `,
    renderCallback: ({ inputs }) => {
        return (0, element_vir_1.html) `
            <h1>${inputs.headerText}</h1>
        `;
    },
});
