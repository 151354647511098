"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createUserTransactionFilterInputs = void 0;
const element_vir_1 = require("element-vir");
const toniq_entrepot_with_filters_element_1 = require("../../../../common/with-filters/toniq-entrepot-with-filters.element");
const toniq_entrepot_profile_nft_card_element_1 = require("../../profile-nft-card-element/toniq-entrepot-profile-nft-card.element");
const profile_page_state_1 = require("../profile-page-state");
const create_base_profile_filters_1 = require("../create-base-profile-filters");
const design_system_1 = require("@toniq-labs/design-system");
const user_nft_transaction_1 = require("../../../../../../data/nft/user-nft-transaction");
const relative_date_1 = require("../../../../../../augments/relative-date");
const create_right_column_template_1 = require("../../profile-page-nfts/create-right-column-template");
const toniq_entrepot_profile_nft_list_item_element_1 = require("../../profile-nft-card-element/toniq-entrepot-profile-nft-list-item.element");
function createUserTransactionFilterInputs({ showFilters, allSorts, allFilters, isRenderReady, entries, currentProfileTab, nftClickCallback, viewStyle, }) {
    return (0, toniq_entrepot_with_filters_element_1.createWithFiltersInputs)({
        ...(0, create_base_profile_filters_1.createBaseProfileWithFiltersInputs)({
            isRenderReady,
            showFilters,
            allFilters,
            allSorts,
            currentProfileTab,
        }),
        allEntries: entries ? entries : [],
        createEntryTemplateCallback: (entry) => {
            if (!isRenderReady) {
                return (0, element_vir_1.html) `
                    <${design_system_1.ToniqIcon}
                        ${(0, element_vir_1.assign)(design_system_1.ToniqIcon, {
                    icon: design_system_1.LoaderAnimated24Icon,
                })}
                    ></${design_system_1.ToniqIcon}>
                `;
            }
            const rightSideTemplate = createRightSideTemplate({
                timestampMilliseconds: entry.transactionTimeMillisecond,
                direction: entry.directionForCurrentUser,
            });
            if (viewStyle === profile_page_state_1.ProfileViewStyleEnum.Grid) {
                return (0, element_vir_1.html) `
                <${toniq_entrepot_profile_nft_card_element_1.EntrepotProfileNftCardElement}
                    ${(0, element_vir_1.assign)(toniq_entrepot_profile_nft_card_element_1.EntrepotProfileNftCardElement, {
                    nft: {
                        ...entry,
                    },
                })}
                    ${(0, element_vir_1.listen)('click', () => {
                    nftClickCallback(entry);
                })}
                >
                    ${rightSideTemplate}
                </${toniq_entrepot_profile_nft_card_element_1.EntrepotProfileNftCardElement}>`;
            }
            else {
                return (0, element_vir_1.html) `
                    <${toniq_entrepot_profile_nft_list_item_element_1.EntrepotProfileNftListItemElement}
                        ${(0, element_vir_1.assign)(toniq_entrepot_profile_nft_list_item_element_1.EntrepotProfileNftListItemElement, {
                    nft: entry,
                })}
                        ${(0, element_vir_1.listen)('click', () => {
                    nftClickCallback(entry);
                })}
                    >
                        ${rightSideTemplate}
                    </${toniq_entrepot_profile_nft_list_item_element_1.EntrepotProfileNftListItemElement}>
                `;
            }
        },
    });
}
exports.createUserTransactionFilterInputs = createUserTransactionFilterInputs;
function createRightSideTemplate({ timestampMilliseconds, direction, }) {
    const directionDisplayString = direction === user_nft_transaction_1.TransactionDirection.Purchase ? 'Bought' : 'Sold';
    const relativeDateDisplayString = (0, relative_date_1.getRelativeDate)(timestampMilliseconds);
    return (0, create_right_column_template_1.createRightSideTextTemplate)({
        topString: relativeDateDisplayString,
        bottomString: directionDisplayString,
        useNbsp: true,
    });
}
