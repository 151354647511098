"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntrepotTopTabsElement = void 0;
const element_vir_1 = require("element-vir");
const class_map_js_1 = require("lit/directives/class-map.js");
const design_system_1 = require("@toniq-labs/design-system");
exports.EntrepotTopTabsElement = (0, design_system_1.defineToniqElement)()({
    tagName: 'toniq-entrepot-top-tabs',
    styles: (0, element_vir_1.css) `
        :host {
            display: flex;
            ${design_system_1.toniqFontStyles.paragraphFont};
            position: relative;
        }

        :host::after {
            position: absolute;
            content: '';
            bottom: 0;
            width: 100%;
            height: 1px;
            border-bottom: 1px solid ${design_system_1.toniqColors.divider.foregroundColor};
            z-index: 0;
        }

        button {
            ${design_system_1.removeNativeFormStyles};
            padding: 12px;
            position: relative;
            outline: none;
            padding: 6px;
        }

        button:focus {
            outline: 0;
        }

        a {
            display: inline-flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            text-decoration: none;
        }

        a::after {
            content: attr(data-text);
            content: attr(data-text) / '';
            height: 0;
            visibility: hidden;
            overflow: hidden;
            user-select: none;
            pointer-events: none;
            ${design_system_1.toniqFontStyles.boldParagraphFont};

            @media speech {
                display: none;
            }
        }

        ul {
            list-style: none;
            display: flex;
            gap: 16px;
            flex-grow: 1;
            overflow-x: auto;
        }

        ${(0, design_system_1.createFocusStyles)({
        mainSelector: 'button:focus:focus-visible:not(:active)',
        elementBorderSize: 0,
        outlineGap: 0,
    })}

        ul {
            margin: 0;
            padding: 0;
        }

        li.selected {
            ${design_system_1.toniqFontStyles.boldParagraphFont};
            color: ${design_system_1.toniqColors.pageInteraction.foregroundColor};
            border-bottom: 2px solid ${design_system_1.toniqColors.pageInteraction.foregroundColor};
            pointer-events: none;
            z-index: 1;
        }

        li.selected > button > .title-preloader {
            background-color: ${design_system_1.toniqColors.pageInteraction.foregroundColor};
            opacity: 0.6;
        }

        li {
            margin: 0;
            padding: 6px 6px 10px;
            white-space: nowrap;
        }

        li:last-of-type {
            flex-grow: 1;
        }

        .title-preloader {
            display: block;
            height: 24px;
            width: 90px;
            background-color: #f6f6f6;
            border-radius: 8px;
        }
    `,
    events: {
        tabChange: (0, element_vir_1.defineElementEvent)(),
    },
    initCallback: ({ inputs, events, dispatch }) => {
        dispatch(new events.tabChange(inputs.selected));
    },
    renderCallback: ({ inputs, dispatch, events }) => {
        const preloader = new Array(Math.floor(Math.random() * (4 - 3) + 3)).fill(0);
        return (0, element_vir_1.html) `
            ${inputs.tabs
            ? (0, element_vir_1.html) `
                      <ul>
                          ${inputs.tabs.map(tab => {
                var _a;
                return makeTopTab(tab, tab.label === ((_a = inputs.selected) === null || _a === void 0 ? void 0 : _a.label), () => {
                    dispatch(new events.tabChange(tab));
                });
            })}
                          <li class="take up remaining space"></li>
                      </ul>
                  `
            : (0, element_vir_1.html) `
                      <ul>
                          ${preloader.map((preloaderTab, preloaderTabIndex) => makeTopTab(preloaderTab, preloaderTabIndex === 0, () => { }, true))}
                          <li class="take up remaining space"></li>
                      </ul>
                  `}
        `;
    },
});
function makeTopTab(tab, isSelected, clickCallback, preloader = false) {
    return (0, element_vir_1.html) `
        <li
            class=${(0, class_map_js_1.classMap)({
        selected: isSelected,
    })}
            ${(0, element_vir_1.listen)('click', clickCallback)}
        >
            ${preloader
        ? (0, element_vir_1.html) `
                      <button><a class="title-preloader"></a></button>
                  `
        : (0, element_vir_1.html) `
                      <button><a data-text=${tab.label}>${tab.label}</a></button>
                  `}
        </li>
    `;
}
