"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createBaseProfileWithFiltersInputs = void 0;
const profile_page_filter_definitions_1 = require("./profile-page-filter-definitions");
function createBaseProfileWithFiltersInputs({ showFilters, isRenderReady, currentProfileTab, allFilters, allSorts, }) {
    return {
        countName: 'NFTs',
        showFilters,
        isLoading: !isRenderReady,
        searchPlaceholder: 'Search: Collection Name or Keywords',
        currentSort: allSorts[profile_page_filter_definitions_1.filterSortKeyByTab[currentProfileTab.value]],
        sortDefinitions: profile_page_filter_definitions_1.sortDefinitions[profile_page_filter_definitions_1.filterSortKeyByTab[currentProfileTab.value]],
        currentFilters: allFilters[profile_page_filter_definitions_1.filterSortKeyByTab[currentProfileTab.value]],
        defaultFilters: {
            ...profile_page_filter_definitions_1.defaultProfileFilters[profile_page_filter_definitions_1.filterSortKeyByTab[currentProfileTab.value]],
        },
        searchCallback: (searchTerm, entry) => {
            var _a, _b;
            return (entry.nftId.includes(searchTerm) ||
                entry.collectionId.includes(searchTerm) ||
                ((_a = entry.collection) === null || _a === void 0 ? void 0 : _a.name.includes(searchTerm)) ||
                ((_b = entry.collection) === null || _b === void 0 ? void 0 : _b.keywords.includes(searchTerm)) ||
                entry.nftMintNumber === Number(searchTerm) ||
                entry.nftIndex === Number(searchTerm));
        },
    };
}
exports.createBaseProfileWithFiltersInputs = createBaseProfileWithFiltersInputs;
