"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseRawUserNftTransaction = exports.TransactionDirection = void 0;
const nft_id_1 = require("./nft-id");
const nft_mint_number_1 = require("./nft-mint-number");
const nft_listing_1 = require("./nft-listing");
const nft_offer_1 = require("./nft-offer");
var TransactionDirection;
(function (TransactionDirection) {
    TransactionDirection["Purchase"] = "purchase";
    TransactionDirection["Sale"] = "sale";
})(TransactionDirection = exports.TransactionDirection || (exports.TransactionDirection = {}));
function parseRawUserNftTransaction({ rawTransaction, rawNftListing, rawNftOffers, }) {
    const decodedNft = (0, nft_id_1.decodeNftId)(rawTransaction.token);
    if (decodedNft.canister !== rawTransaction.canister) {
        console.error({ rawNft: rawNftListing, decodedNft: decodedNft });
        throw new Error(`Decoded canister id '${decodedNft.canister}' did not match transaction canister id '${rawTransaction.canister}'`);
    }
    const listing = {
        price: rawTransaction.price,
        lockedTimestamp: rawTransaction.time,
    };
    return {
        ...(0, nft_listing_1.parseRawNftListing)(rawNftListing),
        ...(0, nft_offer_1.parseRawNftOffers)(rawNftOffers),
        buyerAddress: rawTransaction.buyer,
        nftIndex: decodedNft.index,
        nftMintNumber: (0, nft_mint_number_1.getNftMintNumber)({
            collectionId: rawTransaction.canister,
            nftIndex: decodedNft.index,
        }),
        sellerAddress: rawTransaction.seller,
        transactionTimeMillisecond: rawTransaction.time / 1000000,
        transactionId: rawTransaction.id ? rawTransaction.id : '',
        /** This overrides the listing property from parseRawNftListing(rawNftListing) */
        listing,
    };
}
exports.parseRawUserNftTransaction = parseRawUserNftTransaction;
