"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntrepotHorizontalScrollElement = void 0;
const design_system_1 = require("@toniq-labs/design-system");
const element_vir_1 = require("element-vir");
const scroll_snap_api_1 = require("scroll-snap-api");
const function_1 = require("../../../augments/function");
const class_map_js_1 = require("lit/directives/class-map.js");
const maxCardHeight = 510;
exports.EntrepotHorizontalScrollElement = (0, element_vir_1.defineElement)()({
    tagName: 'toniq-entrepot-horizontal-scroll',
    stateInit: {
        scrollPosition: {
            left: 0,
            right: Infinity,
        },
        scrollSnapPositions: [],
    },
    styles: (0, element_vir_1.css) `
        :host {
            display: block;
            position: relative;
            overflow: visible;
        }

        .scroll-container {
            max-height: 495px;
            margin: 0 -22px;
            overflow-y: hidden;
        }

        .items-container {
            position: relative;
            display: flex;
            overflow-x: scroll;
            scroll-snap-type: x mandatory;
            z-index: 9;
            padding-bottom: 100px;
        }

        .arrow {
            --background-degrees: 90deg;
            position: absolute;
            height: 42px;
            top: 0;
            bottom: 0;
            left: 0;
            margin: auto 0;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            z-index: 10;
        }

        .arrow.left {
            left: -24px;
            right: unset;
        }

        .arrow.right {
            justify-content: flex-end;
            --background-degrees: -90deg;
            left: unset;
            right: -24px;
        }

        .arrow.right::after {
            left: unset;
            right: 0;
        }

        .arrow ${design_system_1.ToniqIcon} {
            transition: 300ms;
            cursor: pointer;
            position: relative;
            z-index: 11;
            box-shadow: 0px 2px 28px rgba(0, 0, 0, 0.12);
            display: inline-flex;
            padding: 8px;
            border-radius: 50%;
            ${(0, design_system_1.applyBackgroundAndForeground)(design_system_1.toniqColors.pagePrimary)};
            border: 1px solid rgba(0, 208, 147, 0.16);
            color: ${design_system_1.toniqColors.pageInteraction.foregroundColor};
        }

        .arrow.left ${design_system_1.ToniqIcon} {
            transform: rotate(90deg);
        }

        .arrow.right ${design_system_1.ToniqIcon} {
            transform: rotate(270deg);
        }

        .hidden {
            opacity: 0;
            pointer-events: none;
        }
    `,
    renderCallback: ({ inputs, state, host, updateState }) => {
        return (0, element_vir_1.html) `
			<div
                class="scroll-container"
                style="max-height: ${inputs.maxCardHeight ? `${inputs.maxCardHeight}px` : `${maxCardHeight}px`}"
            >
				<div class="arrow left">
					<${design_system_1.ToniqIcon}
						class=${(0, class_map_js_1.classMap)({
            hidden: state.scrollPosition.left <= 100,
        })}
						${(0, element_vir_1.assign)(design_system_1.ToniqIcon, {
            icon: design_system_1.ChevronDown24Icon,
        })}
						${(0, element_vir_1.listen)('click', () => {
            rotateCarousel({
                direction: 'left',
                host,
            });
        })}
					></${design_system_1.ToniqIcon}>
				</div>
				<div
                    class="items-container"
                    ${(0, element_vir_1.onResize)(event => {
            updateState({
                scrollSnapPositions: (0, scroll_snap_api_1.getScrollSnapPositions)(getScrollContainer(host)).x,
            });
            throttledUpdateScrollPosition(event.target, updateState);
        })}
                    ${(0, element_vir_1.listen)('scroll', event => {
            throttledUpdateScrollPosition(event.target, updateState);
        })}
                >
					${inputs.children}
				</div>
				<div class="arrow right">
					<${design_system_1.ToniqIcon}
						class=${(0, class_map_js_1.classMap)({
            hidden: state.scrollPosition.right <= 100,
        })}
						${(0, element_vir_1.assign)(design_system_1.ToniqIcon, {
            icon: design_system_1.ChevronDown24Icon,
        })}
						${(0, element_vir_1.listen)('click', () => {
            rotateCarousel({
                direction: 'right',
                host,
            });
        })}
					></${design_system_1.ToniqIcon}>
				</div>
			</div>
        `;
    },
});
function getScrollContainer(host) {
    const scrollContainer = host.shadowRoot.querySelector('.items-container');
    if (!(scrollContainer instanceof HTMLElement)) {
        throw new Error(`Failed to find scroll container.`);
    }
    return scrollContainer;
}
function rotateCarousel({ host, direction, }) {
    const scrollContainer = getScrollContainer(host);
    const snapPositions = (0, scroll_snap_api_1.getScrollSnapPositions)(getScrollContainer(host)).x;
    const currentPosition = getSnapPositionClosestTo(scrollContainer.scrollLeft, snapPositions);
    const directionFactor = direction === 'right' ? 1 : -1;
    const nextPosition = getSnapPositionClosestTo((currentPosition || 0) + (scrollContainer.clientWidth - 304) * directionFactor, snapPositions);
    if (direction === 'right' && nextPosition > currentPosition) {
        scrollContainer.scrollTo({ behavior: 'smooth', left: nextPosition });
    }
    else if (direction === 'left' && nextPosition < currentPosition) {
        scrollContainer.scrollTo({ behavior: 'smooth', left: nextPosition });
    }
    else {
        (0, scroll_snap_api_1.scrollSnapToNext)(scrollContainer, direction);
    }
}
function getSnapPositionClosestTo(closestToThis, snapPositions) {
    const closestPosition = snapPositions.reduce((closest, position) => {
        const positionDistance = Math.abs(closestToThis - position);
        const closestDistance = Math.abs(closestToThis - closest);
        if (positionDistance < closestDistance) {
            return position;
        }
        else {
            return closest;
        }
    }, Infinity);
    return closestPosition;
}
const throttledUpdateScrollPosition = (0, function_1.throttle)(250, (element, updateState) => {
    updateState({
        scrollPosition: {
            left: element.scrollLeft,
            right: element.scrollWidth - element.scrollLeft - element.clientWidth,
        },
    });
});
