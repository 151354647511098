"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getEventConsoleLogFunction = exports.LogSeverityEnum = void 0;
const common_1 = require("@augment-vir/common");
var LogSeverityEnum;
(function (LogSeverityEnum) {
    LogSeverityEnum["Warning"] = "warning";
    LogSeverityEnum["Info"] = "info";
    LogSeverityEnum["Debug"] = "debug";
    LogSeverityEnum["Fatal"] = "fatal";
    LogSeverityEnum["Error"] = "error";
})(LogSeverityEnum = exports.LogSeverityEnum || (exports.LogSeverityEnum = {}));
const errorLevels = [
    LogSeverityEnum.Error,
    LogSeverityEnum.Fatal,
];
const warnLevels = [LogSeverityEnum.Warning];
function getEventConsoleLogFunction(event) {
    if ((0, common_1.typedArrayIncludes)(errorLevels, event.level)) {
        return console.error;
    }
    else if ((0, common_1.typedArrayIncludes)(warnLevels, event.level)) {
        return console.warn;
    }
    else {
        return console.info;
    }
}
exports.getEventConsoleLogFunction = getEventConsoleLogFunction;
