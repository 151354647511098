"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntrepotProfileNftListItemElement = void 0;
const element_vir_1 = require("element-vir");
const design_system_1 = require("@toniq-labs/design-system");
const toniq_entrepot_nft_list_item_element_1 = require("../../../common/toniq-entrepot-nft-list-item.element");
const toniq_entrepot_profile_nft_list_item_text_items_element_1 = require("./toniq-entrepot-profile-nft-list-item-text-items.element");
exports.EntrepotProfileNftListItemElement = (0, design_system_1.defineToniqElement)()({
    tagName: 'toniq-entrepot-profile-nft-list-item',
    styles: (0, element_vir_1.css) `
        :host {
            display: flex;
            width: 100%;
        }

        .footer-contents {
            display: flex;
            flex-direction: column;
        }

        p {
            margin: 16px 0;
            ${design_system_1.toniqFontStyles.h3Font}
        }

        .monospace {
            ${design_system_1.toniqFontStyles.monospaceFont}
        }

        .icp-price {
            display: flex;
            align-items: center;
            gap: 4px;
            ${design_system_1.toniqFontStyles.boldFont}
        }

        .button-row {
            display: flex;
            justify-content: space-between;
        }

        .rarity {
            ${design_system_1.toniqFontStyles.labelFont}
        }

        .row-items {
            display: flex;
        }

        ${toniq_entrepot_nft_list_item_element_1.EntrepotNftListItemElement} {
            width: 100%;
        }

        ${toniq_entrepot_profile_nft_list_item_text_items_element_1.EntrepotProfileNftListItemTextItemsElement} {
            flex-grow: 1;
        }
    `,
    renderCallback: ({ inputs }) => {
        return (0, element_vir_1.html) `
            <${toniq_entrepot_nft_list_item_element_1.EntrepotNftListItemElement}
                ${(0, element_vir_1.assign)(toniq_entrepot_nft_list_item_element_1.EntrepotNftListItemElement, {
            nft: inputs.nft,
        })}
            >
                <${toniq_entrepot_profile_nft_list_item_text_items_element_1.EntrepotProfileNftListItemTextItemsElement}
                    ${(0, element_vir_1.assign)(toniq_entrepot_profile_nft_list_item_text_items_element_1.EntrepotProfileNftListItemTextItemsElement, {
            nft: inputs.nft,
        })}
                >
                    <slot></slot>
                </${toniq_entrepot_profile_nft_list_item_text_items_element_1.EntrepotProfileNftListItemTextItemsElement}>
            </${toniq_entrepot_nft_list_item_element_1.EntrepotNftListItemElement}>
        `;
    },
});
