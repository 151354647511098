"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.icDinosCanisterDetails = void 0;
const entrepot_images_url_1 = require("../../../../api/entrepot-apis/entrepot-images-url");
exports.icDinosCanisterDetails = {
    collectionName: 'IC Dinos',
    canisterId: 'yrdz3-2yaaa-aaaah-qcvpa-cai',
    getNftImageData({ originalCanisterId, nftId, ref }) {
        return {
            url: (0, entrepot_images_url_1.formEntrepotTncImagesUrl)({
                canisterId: originalCanisterId,
                nftId,
                ref,
            }),
        };
    },
};
