"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.icpFlowerCanisterDetails = void 0;
const ext_1 = require("../../../../api/ext");
exports.icpFlowerCanisterDetails = {
    collectionName: 'ICP Flower',
    canisterId: '4ggk4-mqaaa-aaaae-qad6q-cai',
    getNftImageData({ ref, priority, fullSize, nftIndex }) {
        if (fullSize) {
            return undefined;
        }
        const encodedTokenId = (0, ext_1.encodeNftId)('dexpm-6aaaa-aaaal-qbgrq-cai', nftIndex);
        const refQuery = ref == undefined ? '' : String(ref);
        const ampersand = ref == undefined ? '' : '&';
        const queryParams = `?${refQuery}${ampersand}cache=${priority}`;
        const imageUrl = `https://images.entrepot.app/t/dexpm-6aaaa-aaaal-qbgrq-cai/${encodedTokenId}${queryParams}`;
        return {
            url: imageUrl,
        };
    },
};
