"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAllowedTabs = exports.profileTabMap = void 0;
const common_1 = require("@augment-vir/common");
const profileTabValueToLabelMap = {
    'my-nfts': 'My NFTs',
    favorites: 'Favorites',
    offers: 'Offers',
    activity: 'Activity',
    earn: 'Earn',
};
exports.profileTabMap = (0, common_1.mapObjectValues)(profileTabValueToLabelMap, (value, label) => {
    return {
        value,
        label,
    };
});
const profileTabArray = Object.values(exports.profileTabMap);
function getAllowedTabs({ isToniqEarnAllowed }) {
    return profileTabArray.filter(profileTab => {
        if (isToniqEarnAllowed) {
            return profileTab;
        }
        else {
            return true;
        }
    });
}
exports.getAllowedTabs = getAllowedTabs;
