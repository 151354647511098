"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.userOwnedNftsCache = exports.makeUserNftsKey = void 0;
const entrepot_data_api_1 = require("../../../../api/entrepot-apis/entrepot-data-api");
const define_local_cache_1 = require("../../define-local-cache");
const base_nft_1 = require("../../../nft/base-nft");
const fetch_raw_nft_listing_and_offers_1 = require("../fetch-raw-nft-listing-and-offers");
function makeUserNftsKey({ userAccount, userIdentity }) {
    return `${userAccount.address}__${userIdentity.getPrincipal().toText()}`;
}
exports.makeUserNftsKey = makeUserNftsKey;
async function updateUserOwnedNfts({ userAccount }) {
    const cloudFunctionsUrl = (0, entrepot_data_api_1.createCloudFunctionsEndpointUrl)([
        'user',
        userAccount.address,
        'all',
    ]);
    const rawUserNfts = await (await fetch(cloudFunctionsUrl)).json();
    // // do we actually need to load NFTs from the wrapped canisters?
    // const rawWrappedNfts = await allWrappedCanistersApi.getTokens(
    //     userAccount.address,
    //     identity.getPrincipal().toText(),
    // );
    const userOwnedNfts = await Promise.all(rawUserNfts.map(async (rawUserOwnedNft, index) => {
        const rawNftListingAndOffers = await (0, fetch_raw_nft_listing_and_offers_1.fetchRawNftListingAndOffers)(index + 1, rawUserOwnedNft.id);
        return (0, base_nft_1.parseRawNftData)(rawNftListingAndOffers);
    }));
    return userOwnedNfts;
}
exports.userOwnedNftsCache = (0, define_local_cache_1.defineAutomaticallyUpdatingCache)({
    cacheName: 'user-nfts',
    valueUpdater: updateUserOwnedNfts,
    keyGenerator: makeUserNftsKey,
    subKeyRequirement: define_local_cache_1.SubKeyRequirementEnum.Required,
});
