"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.motokoMechsCanisterDetails = void 0;
exports.motokoMechsCanisterDetails = {
    collectionName: 'Motoko Mechs',
    canisterId: 'ugdkf-taaaa-aaaak-acoia-cai',
    getNftImageData({ fullSize, nftLinkUrl }) {
        if (fullSize) {
            return {
                url: nftLinkUrl,
                forcedOriginalImageSize: {
                    width: 850,
                    height: 1214,
                },
            };
        }
        else {
            return undefined;
        }
    },
};
