"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getNftMintNumber = void 0;
const all_canister_details_1 = require("../canisters/canister-details/all-canister-details");
function getNftMintNumber(nftDetails) {
    const collectionDetails = (0, all_canister_details_1.getCanisterDetails)(nftDetails.collectionId);
    if (collectionDetails.hasWrappedCanister) {
        return nftDetails.nftIndex;
    }
    else {
        return nftDetails.nftIndex + 1;
    }
}
exports.getNftMintNumber = getNftMintNumber;
