"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createOverallStatsTemplate = void 0;
const common_1 = require("@augment-vir/common");
const element_vir_1 = require("element-vir");
const design_system_1 = require("@toniq-labs/design-system");
const icp_1 = require("../../../../../data/icp");
function calculateOverallProfileStats(profileState, collectionMap) {
    const ready = (0, element_vir_1.isRenderReady)(profileState.userOwnedNftsStat);
    const floorValue = ready
        ? profileState.userOwnedNftsStat.reduce((sum, nft) => {
            const collection = collectionMap[nft.canister];
            if (!collection) {
                console.error(`Failed to find collection by id '${nft.canister}' for NFT '${nft.id}'`);
                return sum;
            }
            if (!collection.stats) {
                console.error(`No stats yet for collection '${nft.canister}', '${collection.name}'  for NFT '${nft.id}'`);
                return sum;
            }
            const collectionFloor = Number(collection.stats.floor);
            if (isNaN(collectionFloor)) {
                console.error(`Failed to convert floor price '${collection.stats.floor}' into a number from collection '${nft.canister}', '${collection.name}'  for NFT '${nft.id}'`);
                return sum;
            }
            return sum + collectionFloor;
        }, 0)
        : 0;
    const floorDisplayString = ready && !!floorValue ? (0, common_1.truncateNumber)((0, icp_1.convertToIcpNumber)(floorValue)) : '-';
    const collectionCount = ready
        ? (0, common_1.truncateNumber)(new Set(profileState.userOwnedNftsStat.map(nft => nft.canister)).size)
        : '-';
    const profileStats = [
        {
            title: 'OWNED NFTS',
            count: ready ? (0, common_1.truncateNumber)(profileState.userOwnedNftsStat.length) : '-',
        },
        {
            title: 'COLLECTIONS',
            count: collectionCount,
        },
        {
            title: 'FLOOR VALUE',
            icon: design_system_1.Icp16Icon,
            count: floorDisplayString,
        },
    ];
    return profileStats;
}
function createOverallStatsTemplate(profileState, collectionMap) {
    const stats = calculateOverallProfileStats(profileState, collectionMap);
    const statTemplates = stats.map(stat => {
        return (0, element_vir_1.html) `
            <div class="profile-stat-wrapper">
                <div class="profile-stat-title">${stat.title}</div>
                <${design_system_1.ToniqChip}
                    class=${design_system_1.ToniqChip.hostClasses.secondary}
                    ${(0, element_vir_1.assign)(design_system_1.ToniqChip, {
            icon: stat.icon,
            text: String(stat.count),
        })}
                ></${design_system_1.ToniqChip}>
            </div>
        `;
    });
    return (0, element_vir_1.html) `
        <style>
            .profile-stats-wrapper {
                display: flex;
                gap: 16px;
                margin: 16px 32px;
            }

            .profile-stat-wrapper {
                min-width: 80px;
                display: flex;
                flex-direction: column;
                gap: 8px;
            }

            .profile-stat-title {
                ${design_system_1.toniqFontStyles.labelFont};
            }
        </style>
        <div class="profile-stats-wrapper">${statTemplates}</div>
    `;
}
exports.createOverallStatsTemplate = createOverallStatsTemplate;
