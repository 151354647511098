"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.genesisIICanisterDetails = void 0;
const element_vir_1 = require("element-vir");
exports.genesisIICanisterDetails = {
    collectionName: 'Genesis II',
    canisterId: 't555s-uyaaa-aaaal-qbjsa-cai',
    getNftImageData({ fullSize, nftLinkUrl }) {
        if (fullSize) {
            return {
                url: nftLinkUrl,
                forcedOriginalImageSize: {
                    width: 2048,
                    height: 1900,
                },
                extraHtml: (0, element_vir_1.html) `
                    <script>
                        document.getElementById('root').style.width = '100vw';
                        document.getElementById('root').style.height = '100vh';
                        document.getElementById('defaultCanvas0').style.width = '100vw';
                        document.getElementById('defaultCanvas0').style.height = '100vh';
                    </script>
                `,
            };
        }
        else {
            return undefined;
        }
    },
};
