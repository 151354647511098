"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.listViewFinalItemHeaderTitleByTab = exports.initProfileElement = exports.createAsyncProfileStateUpdateActivity = exports.createAsyncProfileStateUpdateOffers = exports.createAsyncProfileStateUpdateFavorites = exports.createAsyncProfileStateUpdateOwnedNftsStat = exports.createAsyncProfileStateUpdateOwnedNfts = exports.createAsyncProfileStateUpdate = exports.profilePageStateInit = exports.ProfileViewStyleEnum = void 0;
const common_1 = require("@augment-vir/common");
const element_vir_1 = require("element-vir");
const profile_tabs_1 = require("./profile-tabs");
const user_transactions_cache_1 = require("../../../../../data/local-cache/caches/user-data/user-transactions-cache");
const user_owned_nfts_cache_1 = require("../../../../../data/local-cache/caches/user-data/user-owned-nfts-cache");
const user_favorites_cache_1 = require("../../../../../data/local-cache/caches/user-data/user-favorites-cache");
const user_owned_nfts_stat_cache_1 = require("../../../../../data/local-cache/caches/user-data/user-owned-nfts-stat-cache");
const user_made_offers_cache_1 = require("../../../../../data/local-cache/caches/user-data/user-made-offers-cache");
const collection_nri_cache_1 = require("../../../../../data/local-cache/caches/collection-nri-cache");
const entrepot_router_1 = require("../../../../../routing/entrepot-router");
const url_filters_1 = require("../../../common/with-filters/url-filters");
const profile_page_filter_definitions_1 = require("./profile-page-filter-definitions");
const selectedCollections = (0, common_1.mapObjectValues)(profile_tabs_1.profileTabMap, () => {
    return [];
});
var ProfileViewStyleEnum;
(function (ProfileViewStyleEnum) {
    ProfileViewStyleEnum["Grid"] = "grid";
    ProfileViewStyleEnum["List"] = "list";
})(ProfileViewStyleEnum = exports.ProfileViewStyleEnum || (exports.ProfileViewStyleEnum = {}));
exports.profilePageStateInit = {
    showFilters: false,
    allFilters: profile_page_filter_definitions_1.defaultProfileFilters,
    selectedCollections,
    collectionsFilterExpanded: true,
    currentProfileTab: profile_tabs_1.profileTabMap['my-nfts'],
    userTransactions: (0, element_vir_1.asyncState)(),
    userOwnedNfts: (0, element_vir_1.asyncState)(),
    userOwnedNftsStat: (0, element_vir_1.asyncState)(),
    userFavorites: (0, element_vir_1.asyncState)(),
    userOffersMade: (0, element_vir_1.asyncState)(),
    collectionNriData: (0, element_vir_1.asyncState)(),
    userEarnNfts: (0, element_vir_1.asyncState)(),
    allSorts: profile_page_filter_definitions_1.defaultProfileSort,
    viewStyle: ProfileViewStyleEnum.Grid,
};
function getAllCollectionIds(asyncStates) {
    const collectionIds = new Set();
    asyncStates.forEach(possibleArray => {
        if ((0, common_1.isRuntimeTypeOf)(possibleArray, 'array')) {
            possibleArray.forEach(entry => {
                collectionIds.add(entry.collectionId);
            });
        }
    });
    return Array.from(collectionIds).sort();
}
function createAsyncProfileStateUpdate({ state, }) {
    const asyncUserNftArrays = [
        state.userFavorites,
        state.userOwnedNfts,
        state.userOffersMade,
        state.userTransactions,
    ];
    const allUserCollectionIds = getAllCollectionIds(asyncUserNftArrays);
    // update nft data
    return {
        collectionNriData: {
            createPromise: async () => {
                let waitIndex = 1;
                const nriData = await Promise.all(allUserCollectionIds.map(async (collectionId) => {
                    return collection_nri_cache_1.collectionNriCache.get({
                        collectionId,
                        waitIndex: waitIndex++,
                    });
                }));
                const nriDataByCollectionId = Object.fromEntries(nriData.map((nriEntry) => {
                    return [
                        nriEntry.collectionId,
                        nriEntry,
                    ];
                }));
                return nriDataByCollectionId;
            },
            trigger: allUserCollectionIds,
        },
    };
}
exports.createAsyncProfileStateUpdate = createAsyncProfileStateUpdate;
function createAsyncProfileStateUpdateOwnedNfts({ inputs, }) {
    var _a, _b;
    return {
        userOwnedNfts: {
            createPromise: async () => {
                return inputs.userAccount && inputs.userIdentity
                    ? user_owned_nfts_cache_1.userOwnedNftsCache.get({
                        userAccount: inputs.userAccount,
                        userIdentity: inputs.userIdentity,
                    })
                    : [];
            },
            trigger: {
                account: (_a = inputs.userAccount) === null || _a === void 0 ? void 0 : _a.address,
                identity: (_b = inputs.userIdentity) === null || _b === void 0 ? void 0 : _b.getPrincipal().toText(),
            },
        },
    };
}
exports.createAsyncProfileStateUpdateOwnedNfts = createAsyncProfileStateUpdateOwnedNfts;
function createAsyncProfileStateUpdateOwnedNftsStat({ inputs, }) {
    var _a, _b;
    return {
        userOwnedNftsStat: {
            createPromise: async () => {
                return inputs.userAccount && inputs.userIdentity
                    ? user_owned_nfts_stat_cache_1.userOwnedNftsStatCache.get({
                        userAccount: inputs.userAccount,
                        userIdentity: inputs.userIdentity,
                    })
                    : [];
            },
            trigger: {
                account: (_a = inputs.userAccount) === null || _a === void 0 ? void 0 : _a.address,
                identity: (_b = inputs.userIdentity) === null || _b === void 0 ? void 0 : _b.getPrincipal().toText(),
            },
        },
    };
}
exports.createAsyncProfileStateUpdateOwnedNftsStat = createAsyncProfileStateUpdateOwnedNftsStat;
function createAsyncProfileStateUpdateFavorites({ inputs, }) {
    var _a, _b;
    return {
        userFavorites: {
            createPromise: async () => inputs.userAccount && inputs.userIdentity
                ? user_favorites_cache_1.userFavoritesCache.get({
                    userIdentity: inputs.userIdentity,
                    userAccount: inputs.userAccount,
                })
                : [],
            trigger: {
                account: (_a = inputs.userAccount) === null || _a === void 0 ? void 0 : _a.address,
                identity: (_b = inputs.userIdentity) === null || _b === void 0 ? void 0 : _b.getPrincipal().toText(),
            },
        },
    };
}
exports.createAsyncProfileStateUpdateFavorites = createAsyncProfileStateUpdateFavorites;
function createAsyncProfileStateUpdateOffers({ inputs, }) {
    var _a, _b;
    return {
        userOffersMade: {
            createPromise: async () => inputs.userAccount && inputs.userIdentity
                ? user_made_offers_cache_1.userMadeOffersCache.get({
                    userIdentity: inputs.userIdentity,
                    userAccount: inputs.userAccount,
                })
                : [],
            trigger: {
                account: (_a = inputs.userAccount) === null || _a === void 0 ? void 0 : _a.address,
                identity: (_b = inputs.userIdentity) === null || _b === void 0 ? void 0 : _b.getPrincipal().toText(),
            },
        },
    };
}
exports.createAsyncProfileStateUpdateOffers = createAsyncProfileStateUpdateOffers;
function createAsyncProfileStateUpdateActivity({ inputs, }) {
    var _a;
    return {
        userTransactions: {
            createPromise: async () => inputs.userAccount && inputs.userIdentity
                ? user_transactions_cache_1.userTransactionsCache.get({
                    userAccount: inputs.userAccount,
                    userIdentity: inputs.userIdentity,
                })
                : [],
            trigger: {
                account: (_a = inputs.userAccount) === null || _a === void 0 ? void 0 : _a.address,
            },
        },
    };
}
exports.createAsyncProfileStateUpdateActivity = createAsyncProfileStateUpdateActivity;
function initProfileElement({ state, inputs, updateState, }) {
    user_transactions_cache_1.userTransactionsCache.subscribe(({ generatedKey: updatedAddress, newValue }) => {
        if (inputs.userAccount && inputs.userAccount.address === updatedAddress) {
            updateState({
                userTransactions: {
                    resolvedValue: newValue,
                },
            });
        }
    });
    user_owned_nfts_cache_1.userOwnedNftsCache.subscribe(({ generatedKey: updatedAddress, newValue }) => {
        if (inputs.userAccount && inputs.userAccount.address === updatedAddress) {
            updateState({
                userOwnedNfts: {
                    resolvedValue: newValue,
                },
            });
        }
    });
    user_favorites_cache_1.userFavoritesCache.subscribe(({ generatedKey: updatedAddress, newValue }) => {
        if (inputs.userAccount && inputs.userAccount.address === updatedAddress) {
            updateState({
                userFavorites: {
                    resolvedValue: newValue,
                },
            });
        }
    });
    user_made_offers_cache_1.userMadeOffersCache.subscribe(({ generatedKey: updatedGeneratedKey, newValue }) => {
        if (inputs.userAccount && inputs.userIdentity) {
            const userKey = (0, user_made_offers_cache_1.makeUserOffersKey)({
                userAccount: inputs.userAccount,
                userIdentity: inputs.userIdentity,
            });
            if (userKey === updatedGeneratedKey) {
                updateState({
                    userOffersMade: {
                        resolvedValue: newValue,
                    },
                });
            }
        }
    });
    collection_nri_cache_1.collectionNriCache.subscribe(async ({ generatedKey: collectionId, newValue }) => {
        if ((0, common_1.isPromiseLike)(state.collectionNriData)) {
            await state.collectionNriData;
        }
        updateState({
            collectionNriData: {
                resolvedValue: {
                    ...((0, element_vir_1.isRenderReady)(state.collectionNriData) ? state.collectionNriData : {}),
                    [collectionId]: newValue,
                },
            },
        });
    });
    entrepot_router_1.entrepotRouter.addRouteListener(true, routes => {
        if (routes.paths[0] !== entrepot_router_1.EntrepotRoutePageEnum.Profile) {
            return;
        }
        // tab
        const profileTab = profile_tabs_1.profileTabMap[routes.paths[1]];
        if (profileTab) {
            updateState({ currentProfileTab: profileTab });
        }
        if (routes.search) {
            if (routes.search.filters) {
                try {
                    const defaultFilters = profile_page_filter_definitions_1.defaultProfileFilters[profile_page_filter_definitions_1.filterSortKeyByTab[state.currentProfileTab.value]];
                    // filters
                    const filters = (0, url_filters_1.urlStringToFilters)({
                        filterString: routes.search.filters,
                        defaultFilters,
                    });
                    updateState({
                        allFilters: {
                            ...state.allFilters,
                            [profile_page_filter_definitions_1.filterSortKeyByTab[state.currentProfileTab.value]]: filters,
                        },
                    });
                }
                catch (error) {
                    console.error(error);
                }
            }
        }
    });
}
exports.initProfileElement = initProfileElement;
exports.listViewFinalItemHeaderTitleByTab = {
    'my-nfts': '',
    activity: 'TIME',
    earn: '',
    favorites: 'OFFERS',
    offers: 'OFFERS',
};
