"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.cronicCrittersCanisterDetails = void 0;
const entrepot_images_url_1 = require("../../../../api/entrepot-apis/entrepot-images-url");
exports.cronicCrittersCanisterDetails = {
    collectionName: 'Cronic Critters',
    canisterId: 'e3izy-jiaaa-aaaah-qacbq-cai',
    getNftImageData({ originalCanisterId, ref, nftId }) {
        const url = (0, entrepot_images_url_1.formEntrepotTncImagesUrl)({
            canisterId: originalCanisterId,
            nftId,
            ref,
        });
        return {
            url,
        };
    },
};
