"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createLoggingContext = exports.sendLogToSentry = void 0;
const common_1 = require("@augment-vir/common");
const sentry_init_1 = require("./sentry-init");
function sendLogToSentry(info, infoDetails) {
    const sentryClient = (0, sentry_init_1.initializeSentry)();
    if (!sentryClient) {
        return;
    }
    const scopeContext = createLoggingContext(infoDetails);
    let eventId;
    if ((0, common_1.isRuntimeTypeOf)(info, 'string')) {
        eventId = sentryClient.captureMessage(info, scopeContext);
    }
    else {
        eventId = sentryClient.captureEvent({
            ...info,
            ...scopeContext,
        });
    }
    return eventId;
}
exports.sendLogToSentry = sendLogToSentry;
function createLoggingContext(logDetails) {
    const extra = {
        ...logDetails.extraData,
    };
    return {
        extra,
        level: logDetails.severity,
    };
}
exports.createLoggingContext = createLoggingContext;
