"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntrepotImageToggleFilterElement = void 0;
const class_map_js_1 = require("lit/directives/class-map.js");
const element_vir_1 = require("element-vir");
const design_system_1 = require("@toniq-labs/design-system");
const lit_1 = require("lit");
exports.EntrepotImageToggleFilterElement = (0, design_system_1.defineToniqElement)()({
    tagName: 'toniq-entrepot-image-toggle-filter',
    events: {
        select: (0, element_vir_1.defineElementEvent)(),
    },
    styles: (0, element_vir_1.css) `
        :host {
            display: flex;
            flex-direction: column;
        }

        .row {
            ${design_system_1.removeNativeFormStyles}
            ${design_system_1.toniqFontStyles.paragraphFont}
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 0;
        }

        .row-suffix {
            display: flex;
            gap: 8px;
        }

        .first-part {
            display: flex;
            gap: 16px;
            align-items: center;
        }

        .selected-row .title {
            ${design_system_1.toniqFontStyles.boldFont};
        }

        .selected-row .row-count {
            ${(0, design_system_1.applyBackgroundAndForeground)(design_system_1.toniqColors.accentPrimary)}
        }

        .row-count {
            display: flex;
            min-height: 24px;
            min-width: 24px;
            border-radius: 12px;
            justify-content: center;
            align-items: center;
            ${design_system_1.toniqFontStyles.boldLabelFont}
            ${(0, design_system_1.applyBackgroundAndForeground)(design_system_1.toniqColors.accentSecondary)}
        }

        .hidden {
            display: none;
        }

        .image-toggle-filter-image {
            border-radius: 8px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            flex-shrink: 0;
            height: 100%;
            width: 100%;
        }

        .header-row .image-toggle-filter-image {
            width: 22px;
            height: 22px;
        }

        .row-image-wrapper {
            width: 48px;
            height: 48px;
            flex-shrink: 0;
            position: relative;
            display: flex;
            gap: 4px;
            flex-wrap: wrap;
        }
    `,
    renderCallback: ({ inputs, state, updateState, events, dispatch }) => {
        var _a, _b;
        const isHeader = !!((_a = inputs.headerImages) === null || _a === void 0 ? void 0 : _a.length);
        const isChecked = inputs.imageToggleEntry.checked;
        const imageTemplate = isHeader
            ? ((_b = inputs.headerImages) !== null && _b !== void 0 ? _b : []).map(makeRowImageTemplate)
            : makeRowImageTemplate(inputs.imageToggleEntry.imageUrl);
        return (0, element_vir_1.html) `
            <button
                class=${(0, class_map_js_1.classMap)({ row: true, 'header-row': isHeader, 'selected-row': isChecked })}
                ${(0, element_vir_1.listen)('click', () => {
            dispatch(new events.select(!isChecked));
        })}
            >
                <div class="first-part">
                    <div class="row-image-wrapper">${imageTemplate}</div>
                    <span class="title">${inputs.name}</span>
                </div>
                <div class="row-suffix">
                    <div class="row-count">
                        <span>${inputs.imageToggleEntry.count}</span>
                    </div>
                </div>
            </button>
        `;
    },
});
function makeRowImageTemplate(imageUrl) {
    const backgroundImageStyle = String((0, element_vir_1.css) `
            background-image: url(${(0, lit_1.unsafeCSS)(imageUrl)});
        `);
    return (0, element_vir_1.html) `
        <div class="image-toggle-filter-image" style=${backgroundImageStyle}></div>
    `;
}
