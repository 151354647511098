"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.applySort = void 0;
const common_1 = require("@augment-vir/common");
function applySort({ sortDefinitions, currentSort, entries, }) {
    const sortToUse = sortDefinitions.find(sortDefinition => sortDefinition.sortName === currentSort.name);
    if (!sortToUse) {
        throw new Error(`Failed to find sort definition by sort name "${currentSort.name}"`);
    }
    return [...entries].sort((a, b) => {
        const aValue = (0, common_1.getValueFromNestedKeys)(a, sortToUse.sortField);
        const bValue = (0, common_1.getValueFromNestedKeys)(b, sortToUse.sortField);
        const numericA = Number(aValue);
        const numericB = Number(bValue);
        if (bValue == undefined) {
            return -1;
        }
        else if (aValue == undefined) {
            return 1;
        }
        else if (typeof aValue === 'number' && typeof bValue === 'number') {
            if (currentSort.ascending) {
                return aValue - bValue;
            }
            else {
                return bValue - aValue;
            }
        }
        else if (!isNaN(numericA) && !isNaN(numericB)) {
            if (currentSort.ascending) {
                return numericA - numericB;
            }
            else {
                return numericB - numericA;
            }
        }
        else if (typeof aValue === 'string' && typeof bValue === 'string') {
            const noQuoteA = aValue.replace(/^['"]/, '').replace(/['"]$/, '');
            const noQuoteB = bValue.replace(/^['"]/, '').replace(/['"]$/, '');
            if (currentSort.ascending) {
                return noQuoteA.localeCompare(noQuoteB);
            }
            else {
                return noQuoteB.localeCompare(noQuoteA);
            }
        }
        else {
            console.error({ aValue, bValue, currentSort });
            throw new Error(`Could not compare the above a,b values.`);
        }
    });
}
exports.applySort = applySort;
