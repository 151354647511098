"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntrepotHomePageTopCardElement = void 0;
const class_map_js_1 = require("lit/directives/class-map.js");
const design_system_1 = require("@toniq-labs/design-system");
const element_vir_1 = require("element-vir");
const drop_shadow_card_style_1 = require("../../../styles/drop-shadow-card.style");
const icp_1 = require("../../../../../data/icp");
const nft_id_1 = require("../../../../../data/nft/nft-id");
const toniq_entrepot_nft_display_element_1 = require("../../../common/toniq-entrepot-nft-display.element");
exports.EntrepotHomePageTopCardElement = (0, design_system_1.defineToniqElement)()({
    tagName: 'toniq-entrepot-home-page-top-card',
    styles: (0, element_vir_1.css) `
        ${(0, drop_shadow_card_style_1.makeDropShadowCardStyles)(':host')}

        :host {
            ${(0, design_system_1.applyBackgroundAndForeground)(design_system_1.toniqColors.pagePrimary)};
            display: inline-flex;
            border-radius: 16px;
            align-items: stretch;
            padding: 0;
        }

        .index-number {
            ${design_system_1.toniqFontStyles.h3Font};
            ${(0, design_system_1.applyBackgroundAndForeground)(design_system_1.toniqColors.pageInteraction)};
            border-right: 1px solid ${design_system_1.toniqColors.dividerFaint.foregroundColor};

            border-top-left-radius: inherit;
            border-bottom-left-radius: inherit;
            width: 44px;
            justify-content: center;
            flex-shrink: 0;
            display: flex;
            align-items: center;
        }

        .main-contents {
            padding: 12px;
            min-height: 72px;
            display: flex;
            flex-grow: 1;
            align-items: stretch;
            border-radius: inherit;
            ${design_system_1.toniqFontStyles.paragraphFont};
            margin-right: 32px;
            overflow: hidden;
        }

        .main-contents div {
            display: flex;
            align-items: center;
            flex-shrink: 0;
            white-space: nowrap;
        }

        .main-contents.left-spacing {
            margin-left: 32px;
        }

        .image-wrapper {
            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 72px;
            width: 72px;
            position: relative;
            margin-right: 12px;
        }

        .collection-image {
            max-height: 100%;
            max-width: 100%;
            border-radius: 8px;
        }

        /* double selector to override ".main-contents div" */
        .collection-name.collection-name {
            ${design_system_1.toniqFontStyles.boldFont};
            flex-grow: 1;
            flex-shrink: 1;
            overflow: hidden;
        }

        .collection-name span {
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .floor-price {
            padding: 0 12px;
            margin-right: 20px;
        }

        .price {
            ${design_system_1.toniqFontStyles.monospaceFont};
            flex-basis: 90px;
            display: flex;
            justify-content: flex-end;
            font-size: 17px;
        }

        @media (max-width: 600px) {
            .price {
                flex-basis: 65px;
            }

            .floor-price {
                margin-right: 0px;
            }

            .main-contents {
                padding: 6px;
                margin-right: 18px;
            }
        }
    `,
    renderCallback: ({ inputs }) => {
        const indexTemplate = inputs.index == undefined
            ? ''
            : (0, element_vir_1.html) `
                      <span class="index-number">${inputs.index}</span>
                  `;
        let { index, canister } = (0, nft_id_1.decodeNftId)(inputs.id);
        const imageTemplate = canister == undefined
            ? ''
            : (0, element_vir_1.html) `
                      <div class="image-wrapper">
                        <${toniq_entrepot_nft_display_element_1.EntrepotNftDisplayElement}
                            ${(0, element_vir_1.assign)(toniq_entrepot_nft_display_element_1.EntrepotNftDisplayElement, {
                collectionId: canister,
                fullSize: false,
                cachePriority: 0,
                nftId: inputs.id,
                nftIndex: index,
                ref: 0,
                min: { width: 72, height: 72 },
                max: { width: 72, height: 72 },
            })}
                        ></${toniq_entrepot_nft_display_element_1.EntrepotNftDisplayElement}>
                      </div>
                  `;
        const displayFloorPrice = `${(0, icp_1.toIcp)(inputs.floorPrice)} ICP`;
        const displayVolume = `${(0, icp_1.toIcp)(inputs.volume)} ICP`;
        return (0, element_vir_1.html) `
            ${indexTemplate}
            <div
                class="main-contents ${(0, class_map_js_1.classMap)({
            'left-spacing': !indexTemplate,
        })}"
            >
                ${imageTemplate}
                <div class="collection-name">
                    <span>${inputs.collectionName}</span>
                </div>
                <div class="price floor-price">
                    <span>${displayFloorPrice}</span>
                </div>
                <div class="price">
                    <span>${displayVolume}</span>
                </div>
            </div>
        `;
    },
});
