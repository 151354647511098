"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.profileUserTransactionSortDefinitions = exports.defaultProfileUserTransactionFilters = void 0;
const common_1 = require("@augment-vir/common");
const filters_types_1 = require("../../../../common/with-filters/filters-types");
const user_nft_transaction_1 = require("../../../../../../data/nft/user-nft-transaction");
exports.defaultProfileUserTransactionFilters = (0, common_1.wrapNarrowTypeWithTypeCheck)()({
    'Activity Type': {
        filterType: filters_types_1.FilterTypeEnum.Radio,
        radios: [
            {
                label: 'All',
                value: 'All',
                filterType: filters_types_1.BooleanFilterTypeEnum.Everything,
            },
            {
                label: 'Purchases',
                value: user_nft_transaction_1.TransactionDirection.Purchase,
            },
            {
                label: 'Sales',
                value: user_nft_transaction_1.TransactionDirection.Sale,
            },
        ],
        filterField: ['directionForCurrentUser'],
        value: 'All',
    },
});
exports.profileUserTransactionSortDefinitions = (0, common_1.wrapNarrowTypeWithTypeCheck)()([
    {
        sortName: 'Time',
        sortField: [
            'transactionTimeMillisecond',
        ],
    },
    {
        sortName: 'Price',
        sortField: [
            'listing',
            'price',
        ],
    },
    {
        sortName: 'Rarity',
        sortField: [
            'nftNri',
        ],
    },
]);
