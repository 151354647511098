"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntrepotSalePreloaderElement = void 0;
const design_system_1 = require("@toniq-labs/design-system");
const element_vir_1 = require("element-vir");
const toniq_entrepot_horizontal_scroll_element_1 = require("../../common/toniq-entrepot-horizontal-scroll.element");
const toniq_entrepot_sale_card_element_1 = require("./toniq-entrepot-sale-card.element");
exports.EntrepotSalePreloaderElement = (0, element_vir_1.defineElementNoInputs)({
    tagName: 'toniq-entrepot-sale-preloader',
    styles: (0, element_vir_1.css) `
        .header {
            display: flex;
            justify-content: space-between;
        }

        .title-preloader {
            display: block;
            height: 28px;
            width: 128px;
            background-color: #f6f6f6;
            border-radius: 8px;
        }

        .see-all-preloader {
            ${design_system_1.removeNativeFormStyles};
            display: block;
            height: 22px;
            width: 46px;
            background-color: ${design_system_1.toniqColors.pageInteraction.foregroundColor};
            border-radius: 8px;
            opacity: 0.6;
        }

        .tab-content {
            display: flex;
            flex-direction: column;
            gap: 52px;
            margin: 0 32px;
        }
    `,
    renderCallback: () => {
        const preloader = new Array(Math.floor(Math.random() * (8 - 4) + 4)).fill(0);
        return (0, element_vir_1.html) `
			<div class="tab-content">
				<div>
					<div class="header">
						<span class="title-preloader"></span>
						<button class="see-all-preloader"></button>
					</div>
					<${toniq_entrepot_horizontal_scroll_element_1.EntrepotHorizontalScrollElement}
						${(0, element_vir_1.assign)(toniq_entrepot_horizontal_scroll_element_1.EntrepotHorizontalScrollElement, {
            children: (0, element_vir_1.html) `
                                ${preloader.map(() => {
                return (0, element_vir_1.html) `
										<${toniq_entrepot_sale_card_element_1.EntrepotSaleCardElement}
										${(0, element_vir_1.assign)(toniq_entrepot_sale_card_element_1.EntrepotSaleCardElement, {})}
										></${toniq_entrepot_sale_card_element_1.EntrepotSaleCardElement}>
									`;
            })}
                            `,
            maxCardHeight: 460,
        })}
					></${toniq_entrepot_horizontal_scroll_element_1.EntrepotHorizontalScrollElement}>
				</div>

				<div>
					<div class="header">
						<span class="title-preloader"></span>
						<button class="see-all-preloader"></button>
					</div>
					<${toniq_entrepot_horizontal_scroll_element_1.EntrepotHorizontalScrollElement}
						${(0, element_vir_1.assign)(toniq_entrepot_horizontal_scroll_element_1.EntrepotHorizontalScrollElement, {
            children: (0, element_vir_1.html) `
                                ${preloader.map(() => {
                return (0, element_vir_1.html) `
										<${toniq_entrepot_sale_card_element_1.EntrepotSaleCardElement}
										${(0, element_vir_1.assign)(toniq_entrepot_sale_card_element_1.EntrepotSaleCardElement, {})}
										></${toniq_entrepot_sale_card_element_1.EntrepotSaleCardElement}>
									`;
            })}
                            `,
            maxCardHeight: 460,
        })}
					></${toniq_entrepot_horizontal_scroll_element_1.EntrepotHorizontalScrollElement}>
				</div>
			</div>
        `;
    },
});
