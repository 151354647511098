"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.icPunksCanisterDetails = void 0;
const ic_canister_domain_1 = require("../../../../api/ic-canister-domain");
exports.icPunksCanisterDetails = {
    collectionName: 'ICPunks',
    canisterId: {
        original: 'qcg3w-tyaaa-aaaah-qakea-cai',
        extWrapped: 'bxdf4-baaaa-aaaah-qaruq-cai',
    },
    getNftLinkUrl({ originalCanisterId, nftIndex }) {
        return [
            (0, ic_canister_domain_1.getCanisterDomain)(originalCanisterId),
            'Token',
            nftIndex,
        ].join('/');
    },
};
