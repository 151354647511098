"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseRawNftData = void 0;
const nft_listing_1 = require("./nft-listing");
const nft_offer_1 = require("./nft-offer");
const nft_id_1 = require("./nft-id");
const wrapped_canister_id_1 = require("../canisters/canister-details/wrapped-canister-id");
const nft_mint_number_1 = require("./nft-mint-number");
function parseRawNftData({ rawNftListing, rawNftOffers, }) {
    const decodedNftId = (0, nft_id_1.decodeNftId)(rawNftListing.id);
    if (decodedNftId.canister !== rawNftListing.canister) {
        console.error({ rawNft: rawNftListing, decodedNft: decodedNftId });
        throw new Error(`Trying to parse raw NFT but its collection id does not match its decoded collection id. See error printed above.`);
    }
    const collectionId = (0, wrapped_canister_id_1.getExtCanisterId)(decodedNftId.canister);
    const nftIndex = decodedNftId.index;
    return {
        ...(0, nft_listing_1.parseRawNftListing)(rawNftListing),
        ...(0, nft_offer_1.parseRawNftOffers)(rawNftOffers),
        nftIndex,
        nftMintNumber: (0, nft_mint_number_1.getNftMintNumber)({ collectionId, nftIndex }),
    };
}
exports.parseRawNftData = parseRawNftData;
