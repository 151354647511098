"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defineAutomaticallyUpdatingCache = exports.SubKeyRequirementEnum = void 0;
const common_1 = require("@augment-vir/common");
const global_cache_options_1 = require("./global-cache-options");
const localforage_shim_1 = require("./localforage-shim");
var SubKeyRequirementEnum;
(function (SubKeyRequirementEnum) {
    // sub keys are always required to be passed into cache get methods
    SubKeyRequirementEnum["Required"] = "required";
    // sub keys can be omitted when calling cache get methods
    SubKeyRequirementEnum["Optional"] = "optional";
    // sub keys must never be provided when calling cache get methods
    SubKeyRequirementEnum["Blocked"] = "blocked";
})(SubKeyRequirementEnum = exports.SubKeyRequirementEnum || (exports.SubKeyRequirementEnum = {}));
const defaultSubKey = Symbol('default-sub-key');
function logIf(setup, ...inputs) {
    if (setup.enableLogging) {
        console.info(...inputs);
    }
}
const memoryCache = {};
// for debugging
globalThis.InMemoryEntrepotCache = memoryCache;
function defineAutomaticallyUpdatingCache(rawInputs) {
    const setup = {
        ...rawInputs,
        ...(0, global_cache_options_1.maskOptionsWithGlobalOptions)(rawInputs),
    };
    if (memoryCache[setup.cacheName]) {
        throw new Error(`Tried to setup '${setup.cacheName}' cache but it is already setup.`);
    }
    const memoryEntry = setupMemoryEntry(setup);
    memoryCache[setup.cacheName] = memoryEntry;
    let lastTimeoutId;
    function setNextUpdate() {
        if (!setup.enableAutomaticUpdating) {
            return;
        }
        globalThis.clearTimeout(lastTimeoutId);
        lastTimeoutId = globalThis.setTimeout(async () => {
            setNextUpdate();
            logIf(setup, `Updating '${setup.cacheName}' cache`);
            const cachedKeys = Array.from(memoryEntry.generatedKeys);
            await (0, common_1.awaitedForEach)(cachedKeys, async (generatedKey) => {
                fireListeners(generatedKey);
            });
        }, setup.minUpdateInterval);
    }
    async function fireListeners(generatedKey) {
        const accessorKey = generatedKey === defaultSubKey ? undefined : generatedKey;
        const lastInputs = memoryEntry.lastGeneratedKeyInputs.get(generatedKey);
        const lastValue = await getValue({ ...setup, enableLogging: false }, accessorKey, memoryEntry, lastInputs, false);
        const newValue = await updateValue(setup, accessorKey, memoryEntry, lastInputs);
        try {
            if (!lastValue || !(0, common_1.areJsonEqual)(lastValue, newValue)) {
                const listenerOutput = (setup.subKeyRequirement === SubKeyRequirementEnum.Blocked
                    ? {
                        newValue,
                    }
                    : generatedKey === defaultSubKey
                        ? {
                            newValue,
                        }
                        : {
                            generatedKey,
                            newValue,
                        });
                listeners.forEach(listener => listener(listenerOutput));
            }
        }
        catch (error) {
            console.error('errored data:', {
                cacheName: setup.cacheName,
                lastValue,
                newValue,
            });
            throw error;
        }
    }
    async function forceUpdate(generatedKey) {
        setNextUpdate();
        const lastInputs = memoryEntry.lastGeneratedKeyInputs.get(generatedKey || defaultSubKey);
        const newValue = await updateValue(setup, generatedKey, memoryEntry, lastInputs);
        fireListeners(generatedKey !== null && generatedKey !== void 0 ? generatedKey : defaultSubKey);
        return newValue;
    }
    setNextUpdate();
    const listeners = new Set();
    function generateKey(keysInput) {
        if (keysInput == undefined) {
            return undefined;
        }
        else {
            return setup.keyGenerator(keysInput);
        }
    }
    const keyGenerator = setup.subKeyRequirement === SubKeyRequirementEnum.Blocked
        ? {}
        : {
            generateKey: generateKey,
        };
    return {
        get: (async (keyInputs) => {
            const generatedKey = generateKey(keyInputs);
            if (keyInputs) {
                memoryEntry.lastGeneratedKeyInputs.set(generatedKey || defaultSubKey, keyInputs);
            }
            const results = await getValue(setup, generatedKey, memoryEntry, keyInputs, true);
            if (results.shouldUpdateCache) {
                logIf(setup, `Updating '${setup.cacheName}' in the background.`);
                // don't await this, it needs to happen in the background
                (0, common_1.wait)(
                /**
                 * Random wait done to stagger loads and push them back off of the initial page
                 * load.
                 */
                Math.random() * 2000).then(() => forceUpdate(generatedKey));
            }
            return results.value;
        }),
        forceImmediateUpdate: (async (keyInputs) => {
            const generatedKey = generateKey(keyInputs);
            if (keyInputs) {
                memoryEntry.lastGeneratedKeyInputs.set(generatedKey || defaultSubKey, keyInputs);
            }
            return forceUpdate(generatedKey);
        }),
        subscribe: ((listener) => {
            listeners.add(listener);
            return () => {
                listeners.delete(listener);
            };
        }),
        ...keyGenerator,
    };
}
exports.defineAutomaticallyUpdatingCache = defineAutomaticallyUpdatingCache;
function setupMemoryEntry(setup) {
    const initCachedKeys = setup.subKeyRequirement === SubKeyRequirementEnum.Required
        ? new Set()
        : new Set([defaultSubKey]);
    const newCacheEntry = {
        lastUpdate: 0,
        generatedKeys: initCachedKeys,
        lastGeneratedKeyInputs: new Map(),
        cachedData: {},
    };
    return newCacheEntry;
}
async function updateValue(setup, generatedKey, memoryEntry, inputs) {
    const newValue = setup.subKeyRequirement === SubKeyRequirementEnum.Blocked
        ? await setup.valueUpdater()
        : await setup.valueUpdater(inputs);
    const accessorKey = generatedKey || defaultSubKey;
    memoryEntry.lastUpdate = Date.now();
    memoryEntry.generatedKeys.add(accessorKey);
    addInMemoryValue(setup, generatedKey, newValue, memoryEntry);
    if (setup.enableCacheBrowserStorage) {
        try {
            const lastItem = (await localforage_shim_1.localForage.getItem(setup.cacheName)) || {};
            lastItem[accessorKey] = newValue;
            logIf(setup, `Saving to in-browser cache for '${setup.cacheName}:${generatedKey}'`);
            localforage_shim_1.localForage.setItem(setup.cacheName, lastItem);
        }
        catch (error) {
            logIf(setup, `Failed to save to in-browser storage for '${setup.cacheName}' with generatedKey '${generatedKey}': ${(0, common_1.extractErrorMessage)(error)}`);
        }
    }
    return newValue;
}
function addInMemoryValue(setup, generatedKey, newValue, memoryEntry) {
    const accessorKey = generatedKey || defaultSubKey;
    memoryEntry.generatedKeys.add(accessorKey);
    if (!memoryEntry.cachedData) {
        memoryEntry.cachedData = {};
    }
    memoryEntry.lastUpdate = Date.now();
    logIf(setup, `Saving to in-memory cache for '${setup.cacheName}:${generatedKey}'`);
    memoryEntry.cachedData[accessorKey] = newValue;
}
async function getValue(setup, generatedKey, memoryEntry, keyInputs, allowUpdate) {
    const accessorKey = generatedKey || defaultSubKey;
    if (accessorKey in memoryEntry.cachedData) {
        logIf(setup, `Accessing in-memory cache for '${setup.cacheName}:${generatedKey}'`);
        return {
            value: memoryEntry.cachedData[accessorKey],
            shouldUpdateCache: false,
        };
    }
    if (setup.enableCacheBrowserStorage) {
        try {
            const inBrowserStorage = await localforage_shim_1.localForage.getItem(setup.cacheName);
            if (inBrowserStorage !== null && accessorKey in inBrowserStorage) {
                logIf(setup, `Accessing in-browser storage for '${setup.cacheName}:${generatedKey}'`);
                const cachedData = inBrowserStorage[accessorKey];
                addInMemoryValue(setup, generatedKey, cachedData, memoryEntry);
                return { value: cachedData, shouldUpdateCache: true };
            }
        }
        catch (error) {
            logIf(setup, `Failed to access in-browser storage for '${setup.cacheName}:${generatedKey}': ${(0, common_1.extractErrorMessage)(error)}`);
        }
    }
    logIf(setup, `No cached value to get for '${setup.cacheName}:${generatedKey}'`);
    if (allowUpdate) {
        return {
            value: await updateValue(setup, generatedKey, memoryEntry, keyInputs),
            shouldUpdateCache: false,
        };
    }
    else {
        return { value: undefined, shouldUpdateCache: false };
    }
}
