"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BooleanFilterTypeEnum = exports.FilterTypeEnum = void 0;
var FilterTypeEnum;
(function (FilterTypeEnum) {
    FilterTypeEnum["Checkboxes"] = "checkboxes";
    FilterTypeEnum["Radio"] = "radio";
    /** For filters like traits */
    FilterTypeEnum["ExpandingList"] = "expanding-list";
    /** For filters like "min to max" */
    FilterTypeEnum["NumericRange"] = "numeric-range";
    /** For filters like the collections */
    FilterTypeEnum["ImageToggles"] = "image-toggles";
})(FilterTypeEnum = exports.FilterTypeEnum || (exports.FilterTypeEnum = {}));
var BooleanFilterTypeEnum;
(function (BooleanFilterTypeEnum) {
    /** For this filter type, string comparison values must match the checkbox value exclusively. */
    BooleanFilterTypeEnum["Exclusive"] = "exclusive";
    /** For this filter type, string comparison values must merely contain the checkbox value. */
    BooleanFilterTypeEnum["Contains"] = "contains";
    /** For this filter type, allow any values. Use this for an "all" filter. */
    BooleanFilterTypeEnum["Everything"] = "everything";
})(BooleanFilterTypeEnum = exports.BooleanFilterTypeEnum || (exports.BooleanFilterTypeEnum = {}));
