"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.profileOfferSortDefinitions = exports.defaultProfileOfferFilters = void 0;
const common_1 = require("@augment-vir/common");
const filters_types_1 = require("../../../../common/with-filters/filters-types");
const profile_nft_filters_1 = require("../profile-page-nft-filters/profile-nft-filters");
exports.defaultProfileOfferFilters = (0, common_1.wrapNarrowTypeWithTypeCheck)()({
    ...profile_nft_filters_1.defaultProfileNftFilters,
    Offers: {
        ...profile_nft_filters_1.defaultProfileNftFilters.Offers,
        radios: [
            {
                label: 'All',
                value: 'All',
                filterType: filters_types_1.BooleanFilterTypeEnum.Everything,
            },
            {
                label: 'Offers received',
                value: 'received',
                filterType: filters_types_1.BooleanFilterTypeEnum.Contains,
            },
            {
                label: 'Offers placed',
                value: 'offered',
                filterType: filters_types_1.BooleanFilterTypeEnum.Contains,
            },
        ],
    },
});
exports.profileOfferSortDefinitions = profile_nft_filters_1.profileNftSortDefinitions;
