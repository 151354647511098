"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.maskOptionsWithGlobalOptions = void 0;
const object_1 = require("../../augments/object");
/** If we need to modify a setting globally for all caches, do so here. */
const globalCacheOptions = {
    enableCacheBrowserStorage: true,
    enableAutomaticUpdating: true,
    enableLogging: false,
    minUpdateInterval: 1000000,
};
function maskOptionsWithGlobalOptions(options) {
    const masked = (0, object_1.maskObjects)(globalCacheOptions, options);
    return {
        ...masked,
        minUpdateInterval: masked.minUpdateInterval < globalCacheOptions.minUpdateInterval
            ? globalCacheOptions.minUpdateInterval
            : masked.minUpdateInterval,
    };
}
exports.maskOptionsWithGlobalOptions = maskOptionsWithGlobalOptions;
