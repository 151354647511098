"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDefaultNftImageData = exports.createDefaultCanisterDetails = void 0;
const ic_canister_domain_1 = require("../../../api/ic-canister-domain");
const entrepot_images_url_1 = require("../../../api/entrepot-apis/entrepot-images-url");
function createDefaultCanisterDetails(canisterId, rawCanisterDetails) {
    return {
        canisterId,
        collectionName: canisterId,
        extCanisterId: canisterId,
        originalCanisterId: canisterId,
        hasWrappedCanister: false,
        getNftLinkUrl: ({ nftId }) => {
            return `https://${canisterId}.raw.icp0.io/?tokenid=${nftId}`;
        },
        getNftImageData: inputs => {
            var _a;
            const priority = (_a = inputs.priority) !== null && _a !== void 0 ? _a : 10;
            return getDefaultNftImageData({
                ...inputs,
                priority,
                canisterId,
            });
        },
    };
}
exports.createDefaultCanisterDetails = createDefaultCanisterDetails;
function getDefaultNftImageData({ fullSize, nftId, priority, canisterId, useCaseBuster, entrepotImagesCanisterId, }) {
    const imageUrl = fullSize
        ? `${(0, ic_canister_domain_1.getCanisterDomain)(canisterId)}/?cc=0&tokenid=${nftId}`
        : (0, entrepot_images_url_1.formEntrepotImagesUrl)({
            entrepotImagesCanisterId: entrepotImagesCanisterId || canisterId,
            nftId,
            cachePriority: priority,
            useCacheBuster: useCaseBuster,
        });
    return { url: imageUrl };
}
exports.getDefaultNftImageData = getDefaultNftImageData;
