"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCollectionSales = void 0;
const bignumber_js_1 = require("bignumber.js");
const icp_1 = require("../../icp");
const entrepot_canisters_1 = require("../../../api/entrepot-apis/entrepot-canisters");
async function getCollectionSales(collections) {
    const allLaunchSettings = await entrepot_canisters_1.entrepotCanisters.launch.get_all_launch_settings();
    return collections.reduce((result, collection) => {
        const launchSetting = allLaunchSettings.find(launch => {
            return launch.id === collection.id;
        });
        if (launchSetting) {
            const { start, end, quantity, remaining, groups } = launchSetting;
            const publicSaleGroups = groups.filter(currentSale => {
                return currentSale.public;
            });
            let salePrice;
            if (publicSaleGroups !== undefined) {
                salePrice = getSalePrice(publicSaleGroups);
            }
            else {
                const privateSaleGroups = groups.filter(currentSale => {
                    return !currentSale.public;
                });
                salePrice = getSalePrice(privateSaleGroups);
            }
            salePrice = salePrice ? (0, icp_1.convertToIcpNumber)(salePrice) : 0;
            const formattedData = {
                ...launchSetting,
                startDate: start instanceof bignumber_js_1.BigNumber || (0, icp_1.isBigInt)(start) ? Number(start) / 1000000 : start,
                endDate: end instanceof bignumber_js_1.BigNumber || (0, icp_1.isBigInt)(end) ? Number(end) / 1000000 : end,
                percentMinted: Math.round((((Number(quantity) - Number(remaining)) / Number(quantity)) * 100 +
                    Number.EPSILON) *
                    100) / 100,
                groups,
                salePrice,
            };
            if (Object.keys(formattedData).length) {
                result.push({
                    ...collection,
                    sales: formattedData,
                });
            }
        }
        return result;
    }, []);
}
exports.getCollectionSales = getCollectionSales;
function getSalePrice(saleGroup) {
    return saleGroup
        .map(saleGroup => {
        return saleGroup.pricing;
    })
        .map(pricing => {
        return pricing.map(price => {
            return price[1];
        });
    })
        .reduce((prevPricing, currentPricing) => {
        return Number(prevPricing) < Number(currentPricing) ? prevPricing : currentPricing;
    })
        .reduce((prevPricing, currentPricing) => {
        return Number(prevPricing) < Number(currentPricing) ? prevPricing : currentPricing;
    });
}
