"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formEntrepotTncImagesUrl = exports.formEntrepotImagesUrl = void 0;
const url_1 = require("../../augments/url");
function formEntrepotImagesUrl({ entrepotImagesCanisterId, nftId, cachePriority, useCacheBuster, }) {
    const searchString = (0, url_1.createQueryParamString)({
        cache: cachePriority,
        cachebuster: useCacheBuster ? (Math.random() + 1).toString(36).substring(7) : undefined,
    });
    return ([
        'https://images.entrepot.app/t',
        entrepotImagesCanisterId,
        nftId,
    ].join('/') + searchString);
}
exports.formEntrepotImagesUrl = formEntrepotImagesUrl;
function formEntrepotTncImagesUrl({ canisterId, nftId, ref, }) {
    const searchString = (0, url_1.createQueryParamString)({}, [ref]);
    return ([
        'https://images.entrepot.app/tnc',
        canisterId,
        nftId,
    ].join('/') + searchString);
}
exports.formEntrepotTncImagesUrl = formEntrepotTncImagesUrl;
