"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sortDefinitions = exports.defaultMarketplaceFilters = void 0;
const common_1 = require("@augment-vir/common");
const filters_types_1 = require("../../common/with-filters/filters-types");
exports.defaultMarketplaceFilters = (0, common_1.wrapNarrowTypeWithTypeCheck)()({
    'Floor Price': {
        filterType: filters_types_1.FilterTypeEnum.NumericRange,
        currentMin: undefined,
        currentMax: undefined,
        filterField: [
            'stats',
            'floor',
        ],
    },
    'Total Volume': {
        filterType: filters_types_1.FilterTypeEnum.NumericRange,
        currentMin: undefined,
        currentMax: undefined,
        // selectedType: VolumeDurationEnum.days7,
        // types: getEnumTypedValues(VolumeDurationEnum),
        filterField: [
            'stats',
            'total',
        ],
    },
    // Dev: {
    //     filterType: FilterTypeEnum.Checkboxes,
    //     checkboxes: [
    //         {
    //             label: 'dev',
    //             checked: false,
    //             filterType: BooleanFilterTypeEnum.Exclusive,
    //         },
    //     ],
    //     filterField: ['dev'],
    // },
    // Bonus: {
    //     filterType: FilterTypeEnum.ExpandingList,
    //     expanded: false,
    //     entries: [
    //         {
    //             expanded: false,
    //             name: 'Keywords',
    //             key: 'keywords',
    //             checkboxes: [
    //                 {
    //                     checked: false,
    //                     label: 'meme',
    //                     filterType: BooleanFilterTypeEnum.Contains,
    //                 },
    //                 {
    //                     checked: false,
    //                     label: 'fun',
    //                     filterType: BooleanFilterTypeEnum.Contains,
    //                 },
    //                 {
    //                     checked: false,
    //                     label: 'collection',
    //                     filterType: BooleanFilterTypeEnum.Contains,
    //                 },
    //             ],
    //         },
    //         {
    //             expanded: false,
    //             name: 'Brief',
    //             key: 'brief',
    //             checkboxes: [
    //                 {
    //                     checked: false,
    //                     label: 'the ',
    //                     filterType: BooleanFilterTypeEnum.Contains,
    //                 },
    //                 {
    //                     checked: false,
    //                     label: 'an ',
    //                     filterType: BooleanFilterTypeEnum.Contains,
    //                 },
    //                 {
    //                     checked: false,
    //                     label: 'and ',
    //                     filterType: BooleanFilterTypeEnum.Contains,
    //                 },
    //                 {
    //                     checked: false,
    //                     label: 'of ',
    //                     filterType: BooleanFilterTypeEnum.Contains,
    //                 },
    //             ],
    //         },
    //     ],
    //     filterField: [],
    // },
});
exports.sortDefinitions = (0, common_1.wrapNarrowTypeWithTypeCheck)()([
    {
        sortName: 'Volume',
        sortField: [
            'stats',
            'total',
        ],
    },
    {
        sortName: 'Listings',
        sortField: [
            'stats',
            'listings',
        ],
    },
    {
        sortName: 'Price',
        sortField: [
            'stats',
            'floor',
        ],
    },
    {
        sortName: 'Name',
        sortField: ['name'],
    },
]);
