"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateOfferStatusFavorites = exports.calculateOfferStatus = void 0;
function calculateOfferStatus(currentUserAccountAddress, nft) {
    if (!nft.offers || !nft.offers.length) {
        return 'none';
    }
    const offered = nft.offers.filter(() => nft.ownerAddress !== currentUserAccountAddress);
    const received = nft.offers.length - offered.length > 0;
    if (offered.length && received) {
        return 'offered received';
    }
    else if (offered.length) {
        return 'offered';
    }
    else if (received) {
        return 'received';
    }
    return 'none';
}
exports.calculateOfferStatus = calculateOfferStatus;
function calculateOfferStatusFavorites(currentUserAccountAddress, nft) {
    if (!nft.offers || !nft.offers.length) {
        return 'none';
    }
    const offered = nft.offers.filter(() => nft.ownerAddress === currentUserAccountAddress);
    const received = nft.offers.length - offered.length > 0;
    if (offered.length && received) {
        return 'offered received';
    }
    else if (offered.length) {
        return 'offered';
    }
    else if (received) {
        return 'received';
    }
    return 'none';
}
exports.calculateOfferStatusFavorites = calculateOfferStatusFavorites;
