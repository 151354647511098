"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.icTurtlesCanisterDetails = void 0;
const ic_canister_domain_1 = require("../../../../api/ic-canister-domain");
exports.icTurtlesCanisterDetails = {
    collectionName: 'ICTurtles',
    canisterId: {
        original: 'fl5nr-xiaaa-aaaai-qbjmq-cai',
        extWrapped: 'jeghr-iaaaa-aaaah-qco7q-cai',
    },
    getNftLinkUrl({ originalCanisterId, nftIndex }) {
        return [
            (0, ic_canister_domain_1.getCanisterDomain)(originalCanisterId),
            'nft',
            nftIndex,
        ].join('/');
    },
};
