"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntrepotFilterElement = void 0;
const element_vir_1 = require("element-vir");
const class_map_js_1 = require("lit/directives/class-map.js");
const filters_types_1 = require("./filters-types");
const design_system_1 = require("@toniq-labs/design-system");
const common_1 = require("@augment-vir/common");
const toniq_entrepot_expanding_list_filter_element_1 = require("./toniq-entrepot-expanding-list-filter.element");
const toniq_entrepot_image_toggle_filter_element_1 = require("./toniq-entrepot-image-toggle-filter.element");
exports.EntrepotFilterElement = (0, design_system_1.defineToniqElement)()({
    tagName: 'toniq-entrepot-filter',
    events: {
        filterChange: (0, element_vir_1.defineElementEvent)(),
    },
    styles: (0, element_vir_1.css) `
        :host {
            display: flex;
            flex-direction: column;
        }

        .title-row {
            margin-top: 0;
            display: flex;
            justify-content: space-between;
            ${design_system_1.removeNativeFormStyles}
            ${design_system_1.toniqFontStyles.boldParagraphFont}
            margin-bottom: 16px;
            cursor: default;
        }

        .clickable {
            cursor: pointer;
        }

        .numeric-row {
            display: flex;
            align-items: center;
            gap: 8px;
        }

        .numeric-row ${design_system_1.ToniqInput} {
            flex-grow: 1;
        }

        .expanding-entries {
            display: flex;
            flex-direction: column;
            gap: 16px;
        }

        ${toniq_entrepot_expanding_list_filter_element_1.EntrepotExpandingListFilterElement} {
            margin-left: 4px;
        }
    `,
    renderCallback: ({ inputs, events, dispatch }) => {
        function changeCallback(newFilter) {
            dispatch(new events.filterChange({ [inputs.filterName]: newFilter }));
        }
        const filterInternalsTemplate = createFilterInternals(inputs.filter, changeCallback);
        if (!filterInternalsTemplate) {
            console.error(new Error(`Invalid filter type for rendering "${inputs.filter.filterType}" for "${inputs.filterName}"`));
            return (0, element_vir_1.html) ``;
        }
        const filterCountString = createFilterCountString(inputs.filter);
        const expandingIconTemplate = createExpandingIconTemplate(inputs.filter);
        const clickCallback = createFilterTitleRowClickCallback(inputs.filter, changeCallback);
        return (0, element_vir_1.html) `
            <button
                class="title-row ${(0, class_map_js_1.classMap)({ clickable: !!clickCallback })}"
                ${(0, element_vir_1.listen)('click', () => {
            clickCallback === null || clickCallback === void 0 ? void 0 : clickCallback();
        })}
            >
                <span>${inputs.filterName}${filterCountString}</span>
                ${expandingIconTemplate}
            </button>
            ${'expanded' in inputs.filter
            ? inputs.filter.expanded
                ? filterInternalsTemplate
                : ''
            : filterInternalsTemplate}
        `;
    },
});
function createFilterTitleRowClickCallback(filter, changeCallback) {
    if ('expanded' in filter) {
        return () => changeCallback({
            ...filter,
            expanded: !filter.expanded,
        });
    }
    else {
        return undefined;
    }
}
function createFilterCountString(filter) {
    let count;
    if (filter.filterType === filters_types_1.FilterTypeEnum.ExpandingList) {
        count = filter.entries.length;
    }
    else if (filter.filterType === filters_types_1.FilterTypeEnum.ImageToggles) {
        count = Object.keys(filter.entries).length;
    }
    if (count) {
        return ` (${count})`;
    }
    else {
        return '';
    }
}
function createExpandingIconTemplate(filter) {
    if ('expanded' in filter) {
        return (0, element_vir_1.html) `
            <${design_system_1.ToniqIcon}
                ${(0, element_vir_1.assign)(design_system_1.ToniqIcon, {
            icon: filter.expanded ? design_system_1.ChevronUp24Icon : design_system_1.ChevronDown24Icon,
        })}
            ></${design_system_1.ToniqIcon}>
        `;
    }
    else {
        return (0, element_vir_1.html) ``;
    }
}
function createFilterInternals(filter, changeCallback) {
    if (filter.filterType === filters_types_1.FilterTypeEnum.Checkboxes) {
        return filter.checkboxes.map((checkbox, index) => {
            return (0, element_vir_1.html) `
                <${design_system_1.ToniqCheckbox}
                    ${(0, element_vir_1.assign)(design_system_1.ToniqCheckbox, {
                checked: checkbox.checked,
                text: checkbox.label,
            })}
                    ${(0, element_vir_1.listen)(design_system_1.ToniqCheckbox.events.checkedChange, event => {
                changeCallback({
                    ...filter,
                    checkboxes: filter.checkboxes.map((checkbox, innerIndex) => {
                        if (innerIndex === index) {
                            return {
                                ...checkbox,
                                checked: event.detail,
                            };
                        }
                        else {
                            return checkbox;
                        }
                    }),
                });
            })}
                ></${design_system_1.ToniqCheckbox}>
            `;
        });
    }
    else if (filter.filterType === filters_types_1.FilterTypeEnum.Radio) {
        return (0, element_vir_1.html) `
            <${design_system_1.ToniqRadioGroup}
                ${(0, element_vir_1.assign)(design_system_1.ToniqRadioGroup, {
            entries: filter.radios.map(radio => {
                var _a;
                return {
                    value: (0, common_1.isRuntimeTypeOf)(radio.value, 'boolean')
                        ? String(radio.value)
                        : (_a = radio.value) !== null && _a !== void 0 ? _a : radio.label,
                    label: radio.label,
                };
            }),
            value: String(filter.value),
        })}
                ${(0, element_vir_1.listen)(design_system_1.ToniqRadioGroup.events.valueChange, event => {
            changeCallback({
                ...filter,
                value: event.detail,
            });
        })}
            ></${design_system_1.ToniqRadioGroup}>
        `;
    }
    else if (filter.filterType === filters_types_1.FilterTypeEnum.ExpandingList) {
        return createExpandingListTemplate(filter, changeCallback);
    }
    else if (filter.filterType === filters_types_1.FilterTypeEnum.ImageToggles) {
        return createImageTogglesFilterTemplate(filter, changeCallback);
    }
    else if (filter.filterType === filters_types_1.FilterTypeEnum.NumericRange) {
        return (0, element_vir_1.html) `
            <div class="numeric-row">
                ${createNumericRangeInputTemplate({
            filter,
            propName: 'currentMin',
            changeCallback,
        })}
                ${createNumericRangeInputTemplate({
            filter,
            propName: 'currentMax',
            changeCallback,
        })}
            </div>
        `;
    }
    else {
        return undefined;
    }
}
function createImageTogglesFilterTemplate(filter, changeCallback) {
    const { anyChecked, totalCount } = Object.values(filter.entries).reduce((combined, entry) => {
        return {
            anyChecked: combined.anyChecked || entry.checked,
            totalCount: entry.count + combined.totalCount,
        };
    }, {
        anyChecked: false,
        totalCount: 0,
    });
    const firstImages = Object.values(filter.entries)
        .slice(0, 4)
        .map(entry => entry.imageUrl);
    const styleCss = (0, element_vir_1.css) `
        .image-toggle-header {
            margin-bottom: 8px;
        }

        hr {
            width: 100%;
            background-color: ${design_system_1.toniqColors.divider.foregroundColor};
            height: 1px;
            border: 0;
        }

        hr + ${toniq_entrepot_image_toggle_filter_element_1.EntrepotImageToggleFilterElement} {
            margin-top: 8px;
        }
    `;
    return (0, element_vir_1.html) `
        <style>
            ${String(styleCss)}
        </style>
        <div class="expanding-entries">
            <${toniq_entrepot_image_toggle_filter_element_1.EntrepotImageToggleFilterElement}
                class="image-toggle-header"
                ${(0, element_vir_1.assign)(toniq_entrepot_image_toggle_filter_element_1.EntrepotImageToggleFilterElement, {
        name: filter.allEntriesTitle,
        imageToggleEntry: {
            checked: !anyChecked,
            count: totalCount,
            imageUrl: '',
        },
        headerImages: firstImages,
    })}
                ${(0, element_vir_1.listen)(toniq_entrepot_image_toggle_filter_element_1.EntrepotImageToggleFilterElement.events.select, event => {
        changeCallback({
            ...filter,
            entries: (0, common_1.mapObjectValues)(filter.entries, (filterName, filterEntry) => {
                return {
                    ...filterEntry,
                    checked: false,
                };
            }),
        });
    })}
            ></${toniq_entrepot_image_toggle_filter_element_1.EntrepotImageToggleFilterElement}>
            <hr>
            ${Object.entries(filter.entries)
        .sort((a, b) => b[1].count - a[1].count)
        .map(([name, imageToggleEntry,]) => {
        return (0, element_vir_1.html) `
                    <${toniq_entrepot_image_toggle_filter_element_1.EntrepotImageToggleFilterElement}
                        ${(0, element_vir_1.assign)(toniq_entrepot_image_toggle_filter_element_1.EntrepotImageToggleFilterElement, {
            imageToggleEntry,
            name,
        })}
                        ${(0, element_vir_1.listen)(toniq_entrepot_image_toggle_filter_element_1.EntrepotImageToggleFilterElement.events.select, event => {
            const entries = {
                ...filter.entries,
                [name]: {
                    ...filter.entries[name],
                    checked: event.detail,
                },
            };
            changeCallback({
                ...filter,
                entries,
            });
        })}
                    ></${toniq_entrepot_image_toggle_filter_element_1.EntrepotImageToggleFilterElement}>`;
    })}
        </div>
    `;
}
function createExpandingListTemplate(filter, changeCallback) {
    return (0, element_vir_1.html) `
        <div class="expanding-entries">
            ${filter.entries.map((entry, entryIndex) => {
        return (0, element_vir_1.html) `
                    <${toniq_entrepot_expanding_list_filter_element_1.EntrepotExpandingListFilterElement}
                        ${(0, element_vir_1.assign)(toniq_entrepot_expanding_list_filter_element_1.EntrepotExpandingListFilterElement, {
            expandingListEntry: entry,
        })}
                        ${(0, element_vir_1.listen)(toniq_entrepot_expanding_list_filter_element_1.EntrepotExpandingListFilterElement.events.entryChange, event => {
            const entries = [...filter.entries];
            entries[entryIndex] = event.detail;
            changeCallback({
                ...filter,
                entries,
            });
        })}
                    ></${toniq_entrepot_expanding_list_filter_element_1.EntrepotExpandingListFilterElement}>`;
    })}
        </div>
    `;
}
function createNumericRangeInputTemplate({ filter, propName, changeCallback, }) {
    const value = filter[propName];
    return (0, element_vir_1.html) `
        <${design_system_1.ToniqInput}
            class=${design_system_1.ToniqInput.hostClasses.outline}
            ${(0, element_vir_1.assign)(design_system_1.ToniqInput, {
        value: value == undefined ? '' : String(value),
        placeholder: propName.replace(/^current/, ''),
        disableBrowserHelps: true,
    })}
            ${(0, element_vir_1.listen)(design_system_1.ToniqInput.events.valueChange, event => {
        const numericCast = Number((0, common_1.removeCommasFromNumberString)(event.detail));
        const newValue = isNaN(numericCast) || event.detail === '' ? undefined : numericCast;
        changeCallback({
            ...filter,
            [propName]: newValue,
        });
    })}
        ></${design_system_1.ToniqInput}>
    `;
}
