"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.collectionNriCache = exports.makeNriCacheKey = void 0;
const define_local_cache_1 = require("../define-local-cache");
const common_1 = require("@augment-vir/common");
function makeNriCacheKey({ collectionId }) {
    return collectionId;
}
exports.makeNriCacheKey = makeNriCacheKey;
async function updateCollectionNriData({ collectionId, waitIndex, }) {
    await (0, common_1.wait)(waitIndex + (Math.random() * waitIndex || 1) / 10);
    const response = await fetch(`/nri/${collectionId}.json`);
    const contentType = response.headers.get('content-type');
    const isJson = contentType && contentType.includes('application/json');
    let nriData = undefined;
    try {
        nriData = isJson ? await response.json() : undefined;
    }
    catch (error) {
        console.error(`Failed to read NRI data for '${collectionId}':`, error);
    }
    return {
        collectionId: collectionId,
        nriData,
    };
}
exports.collectionNriCache = (0, define_local_cache_1.defineAutomaticallyUpdatingCache)({
    cacheName: 'collection-nri',
    valueUpdater: updateCollectionNriData,
    keyGenerator: makeNriCacheKey,
    subKeyRequirement: define_local_cache_1.SubKeyRequirementEnum.Required,
    minUpdateInterval: 120000,
    enableLogging: false,
});
