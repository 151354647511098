"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.userOwnedNftsStatCache = exports.makeUserNftsKey = void 0;
const entrepot_data_api_1 = require("../../../../api/entrepot-apis/entrepot-data-api");
const define_local_cache_1 = require("../../define-local-cache");
function makeUserNftsKey({ userAccount, userIdentity }) {
    return `${userAccount.address}__${userIdentity.getPrincipal().toText()}`;
}
exports.makeUserNftsKey = makeUserNftsKey;
async function updateUserOwnedNfts({ userAccount }) {
    const cloudFunctionsUrl = (0, entrepot_data_api_1.createCloudFunctionsEndpointUrl)([
        'user',
        userAccount.address,
        'all',
    ]);
    const rawUserNfts = await (await fetch(cloudFunctionsUrl)).json();
    return rawUserNfts;
}
exports.userOwnedNftsStatCache = (0, define_local_cache_1.defineAutomaticallyUpdatingCache)({
    cacheName: 'user-nfts-stat',
    valueUpdater: updateUserOwnedNfts,
    keyGenerator: makeUserNftsKey,
    subKeyRequirement: define_local_cache_1.SubKeyRequirementEnum.Required,
});
