"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isBigInt = exports.convertToIcpNumber = exports.toIcp = exports.icpFactor = void 0;
const common_1 = require("@augment-vir/common");
const bignumber_js_1 = require("bignumber.js");
exports.icpFactor = 100000000;
function toIcp(input) {
    const numeric = convertToIcpNumber(input);
    return (0, common_1.truncateNumber)(numeric);
}
exports.toIcp = toIcp;
function convertToIcpNumber(input) {
    if (input instanceof bignumber_js_1.BigNumber || isBigInt(input)) {
        return Number(input) / exports.icpFactor;
    }
    else {
        return input;
    }
}
exports.convertToIcpNumber = convertToIcpNumber;
// extra type guard because apparently TS isn't smart enough to use typeof on its own here
function isBigInt(input) {
    return typeof input === 'bigint';
}
exports.isBigInt = isBigInt;
