export const cronicFilterTraits = [
    'health',
    'base',
    'speed',
    'attack',
    'range',
    'magic',
    'defense',
    'resistance',
    'basic',
    'special',
];
export const gridLargeMaxWidth = '300px';
export const gridLargeMaxHeight = '600px';
export const gridSmallMaxWidth = '168px';
export const gridSmallMaxHeight = '252px';
