"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntrepotHomePage = exports.EntrepotHomePageElement = void 0;
const wrap_native_element_1 = require("@toniq-labs/design-system/dist/esm/elements/wrap-native-element");
const element_vir_1 = require("element-vir");
const toniq_entrepot_top_tabs_element_1 = require("../../common/toniq-entrepot-top-tabs.element");
const toniq_entrepot_featured_collection_card_element_1 = require("./children/toniq-entrepot-featured-collection-card.element");
const toniq_entrepot_top_card_element_1 = require("./children/toniq-entrepot-top-card.element");
const toniq_entrepot_carousel_element_1 = require("../../common/toniq-entrepot-carousel.element");
const common_1 = require("@augment-vir/common");
const design_system_1 = require("@toniq-labs/design-system");
const toniq_entrepot_top_card_header_element_1 = require("./children/toniq-entrepot-top-card-header.element");
const spa_router_vir_1 = require("spa-router-vir");
const repeat_js_1 = require("lit/directives/repeat.js");
exports.EntrepotHomePageElement = (0, design_system_1.defineToniqElement)()({
    tagName: 'toniq-entrepot-home-page',
    stateInit: {
        topCollectionsSelectedTab: undefined,
    },
    styles: (0, element_vir_1.css) `
        :host {
            flex-grow: 1;
            display: flex;
            max-width: 100%;
            flex-direction: column;
            padding-bottom: 32px;
        }

        ${toniq_entrepot_top_tabs_element_1.EntrepotTopTabsElement} {
            margin: 0 64px;
        }

        .featured-collections {
            margin: 0 64px;
            column-count: 2;
            column-gap: 32px;
            max-width: 100%;
        }

        .top-cards {
            column-count: 2;
            column-gap: 32px;
            margin: 64px;
            margin-top: 16px;
            position: relative;
        }

        .top-cards > * {
            width: 100%;
            max-width: 100%;
            margin-bottom: 16px;
        }

        .top-cards-header {
            display: flex;
            column-count: 2;
            column-gap: 32px;
            margin: 64px;
            margin-bottom: 0;
            margin-top: 24px;
        }

        .top-cards-header > * {
            width: 100%;
        }

        h2 {
            ${design_system_1.toniqFontStyles.h2Font};
            ${design_system_1.toniqFontStyles.extraBoldFont};
            border-bottom: 1px solid ${design_system_1.toniqColors.divider.foregroundColor};
            margin: 40px 64px;
            text-align: center;
            padding-bottom: 12px;
        }

        ${toniq_entrepot_featured_collection_card_element_1.EntrepotFeaturedCollectionCardElement} {
            max-width: 100%;
            margin-bottom: 32px;
        }

        .powered-nft-geek {
            position: absolute;
            right: 0;
            width: auto;
            ${design_system_1.toniqFontStyles.normalWeightFont};
            font-size: 12px;
        }

        a {
            height: 100%;
            width: 100%;
            text-decoration: none;
            display: flex;
            align-items: stretch;
            text-align: left;
            flex-direction: column;
            flex-grow: 1;
            color: inherit;
        }

        @media (max-width: 1200px) and (min-width: 900px), (max-width: 600px) {
            .top-cards-header,
            .top-cards,
            .featured-collections,
            ${toniq_entrepot_top_tabs_element_1.EntrepotTopTabsElement}, h2 {
                margin-right: 0;
                margin-left: 0;
            }
        }

        @media (max-width: 900px) {
            .top-cards-header,
            .top-cards,
            .featured-collections {
                column-count: 1;
            }

            .top-cards-header > :last-of-type {
                display: none;
            }
        }
    `,
    events: {
        collectionRouteClicked: (0, element_vir_1.defineElementEvent)(),
    },
    renderCallback: ({ inputs, state, updateState, events, dispatch }) => {
        var _a, _b;
        const tabs = [
            inputs.topCollections.past24Hours
                ? inputs.topCollections.past24Hours.length
                    ? {
                        label: 'Past 24 Hours',
                        value: inputs.topCollections.past24Hours,
                    }
                    : undefined
                : undefined,
            inputs.topCollections.allTime
                ? inputs.topCollections.allTime.length
                    ? {
                        label: 'All Time',
                        value: inputs.topCollections.allTime,
                    }
                    : undefined
                : undefined,
        ].filter(common_1.isTruthy);
        const selectedTopTab = (_a = state.topCollectionsSelectedTab) !== null && _a !== void 0 ? _a : tabs[0];
        const selectedTopCards = (_b = selectedTopTab === null || selectedTopTab === void 0 ? void 0 : selectedTopTab.value) !== null && _b !== void 0 ? _b : [];
        const topCardsToShow = selectedTopCards.slice(0, 10);
        const headerCount = topCardsToShow.length > 5 ? 2 : 1;
        return (0, element_vir_1.html) `
            <${toniq_entrepot_carousel_element_1.EntrepotCarouselElement}
                ${(0, element_vir_1.assign)(toniq_entrepot_carousel_element_1.EntrepotCarouselElement, {
            items: inputs.carouselItems,
            automaticRotation: true,
        })}
            ></${toniq_entrepot_carousel_element_1.EntrepotCarouselElement}>
            
            ${selectedTopTab
            ? (0, element_vir_1.html) `
                        <${toniq_entrepot_top_tabs_element_1.EntrepotTopTabsElement}
                            ${(0, element_vir_1.assign)(toniq_entrepot_top_tabs_element_1.EntrepotTopTabsElement, {
                tabs,
                selected: selectedTopTab,
            })}
                            ${(0, element_vir_1.listen)(toniq_entrepot_top_tabs_element_1.EntrepotTopTabsElement.events.tabChange, event => {
                const selectedTab = event.detail;
                updateState({ topCollectionsSelectedTab: selectedTab });
            })}
                        ></${toniq_entrepot_top_tabs_element_1.EntrepotTopTabsElement}>`
            : ''}
            <div class="top-cards-header">
                ${Array(headerCount)
            .fill(0)
            .map(() => {
            return (0, element_vir_1.html) `
                            <${toniq_entrepot_top_card_header_element_1.EntrepotHomePageTopCardHeaderElement}
                                ${(0, element_vir_1.assign)(toniq_entrepot_top_card_header_element_1.EntrepotHomePageTopCardHeaderElement, {
                hasImage: true,
                hasIndex: true,
            })}
                            ></${toniq_entrepot_top_card_header_element_1.EntrepotHomePageTopCardHeaderElement}>`;
        })}
            </div>
            <div class="top-cards">
                ${(0, repeat_js_1.repeat)(topCardsToShow, topCard => topCard.id, topCard => {
            return (0, element_vir_1.html) `
                    <a
                        href=${`/marketplace/${topCard.route}`}
                        @click=${(clickEvent) => {
                if ((0, spa_router_vir_1.shouldMouseEventTriggerRoutes)(clickEvent)) {
                    clickEvent.preventDefault();
                    dispatch(new events.collectionRouteClicked(topCard.route));
                }
            }}
                    >
                        <${toniq_entrepot_top_card_element_1.EntrepotHomePageTopCardElement}
                            ${(0, element_vir_1.assign)(toniq_entrepot_top_card_element_1.EntrepotHomePageTopCardElement, topCard)}
                        ></${toniq_entrepot_top_card_element_1.EntrepotHomePageTopCardElement}>
                    </a>
                `;
        })}
                <span class="powered-nft-geek">Data powered by NFT Geek</span>
            </div>
            
            <h2>
                Featured
            </h2>
            <div class="featured-collections">
                ${(0, repeat_js_1.repeat)(inputs.featuredCollections, featuredCollection => featuredCollection.collectionName, featuredCollection => {
            return (0, element_vir_1.html) `
                    <${toniq_entrepot_featured_collection_card_element_1.EntrepotFeaturedCollectionCardElement}
                        ${(0, element_vir_1.assign)(toniq_entrepot_featured_collection_card_element_1.EntrepotFeaturedCollectionCardElement, featuredCollection)}
                        ${(0, element_vir_1.listen)(toniq_entrepot_featured_collection_card_element_1.EntrepotFeaturedCollectionCardElement.events.collectionRouteClicked, event => {
                new events.collectionRouteClicked(event.detail);
            })}
                    ></${toniq_entrepot_featured_collection_card_element_1.EntrepotFeaturedCollectionCardElement}>
                    `;
        })}
            </div>
        `;
    },
});
exports.EntrepotHomePage = (0, wrap_native_element_1.wrapInReactComponent)(exports.EntrepotHomePageElement);
