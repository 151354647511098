"use strict";
/** This is a separate list of properties because it is fetched from a dedicated canister. */
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseRawNftListing = exports.emptyNftListing = void 0;
exports.emptyNftListing = {
    collectionId: '',
    listing: {
        price: 0,
        lockedTimestamp: 0,
    },
    nftId: '',
    ownerAddress: '',
};
function parseRawNftListing(rawNftListingAndOffers) {
    const listing = {
        price: rawNftListingAndOffers.price,
        lockedTimestamp: rawNftListingAndOffers.time,
    };
    return {
        collectionId: rawNftListingAndOffers.canister,
        listing: listing,
        nftId: rawNftListingAndOffers.id,
        ownerAddress: rawNftListingAndOffers.owner,
    };
}
exports.parseRawNftListing = parseRawNftListing;
