"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.maskObjects = void 0;
const common_1 = require("@augment-vir/common");
function maskObjects(a, b) {
    return (0, common_1.mapObjectValues)(a, (key, aValue) => {
        if ((0, common_1.typedHasProperty)(b, key)) {
            const bValue = b[key];
            return bValue;
        }
        return aValue;
    });
}
exports.maskObjects = maskObjects;
