"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRelativeDate = void 0;
const moment_1 = require("moment");
function getRelativeDate(input) {
    const nowNumber = Date.now();
    const thenNumber = Number(input);
    const diff = thenNumber - nowNumber;
    if (diff !== 0)
        return (0, moment_1.duration)(diff).humanize(true);
    else
        return 'Just Now';
}
exports.getRelativeDate = getRelativeDate;
