"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntrepotFilterTokenElement = void 0;
const element_vir_1 = require("element-vir");
const filters_types_1 = require("./filters-types");
const design_system_1 = require("@toniq-labs/design-system");
const common_1 = require("@augment-vir/common");
const is_still_default_1 = require("./is-still-default");
exports.EntrepotFilterTokenElement = (0, design_system_1.defineToniqElement)()({
    tagName: 'toniq-entrepot-filter-token',
    events: {
        resetFilter: (0, element_vir_1.defineElementEvent)(),
    },
    styles: (0, element_vir_1.css) `
        :host {
            display: flex;
        }

        .token {
            display: flex;
            border-radius: 8px;
            padding: 8px 16px;
            gap: 12px;
            ${design_system_1.toniqFontStyles.paragraphFont};
            ${(0, design_system_1.applyBackgroundAndForeground)(design_system_1.toniqColors.accentSecondary)}
        }

        .close-icon {
            cursor: pointer;
        }
    `,
    renderCallback: ({ inputs, events, dispatch }) => {
        const atDefaults = (0, is_still_default_1.isStillAtDefaults)(inputs);
        if (atDefaults) {
            return (0, element_vir_1.html) ``;
        }
        const tokens = createTokens(inputs);
        return (0, element_vir_1.html) `
            ${tokens.map(token => {
            return (0, element_vir_1.html) `
                <div class="token">
                    ${token.text}
                    <${design_system_1.ToniqIcon}
                        class="close-icon"
                        ${(0, element_vir_1.assign)(design_system_1.ToniqIcon, { icon: design_system_1.X24Icon })}
                        ${(0, element_vir_1.listen)('click', () => {
                dispatch(new events.resetFilter(token.resetValue));
            })}
                    ></${design_system_1.ToniqIcon}>
                </div>`;
        })}
        `;
    },
});
function createTokens({ filter, filterName, defaultFilter, }) {
    if (filter.filterType === filters_types_1.FilterTypeEnum.Checkboxes &&
        defaultFilter.filterType === filters_types_1.FilterTypeEnum.Checkboxes) {
        return filter.checkboxes
            .map((checkbox, index) => {
            const defaultCheckbox = defaultFilter.checkboxes[index];
            if (defaultCheckbox.checked === checkbox.checked) {
                return undefined;
            }
            else {
                const inversion = checkbox.checked ? '' : '!';
                return {
                    text: `${inversion}${checkbox.label}`,
                    resetValue: {
                        ...filter,
                        checkboxes: filter.checkboxes.map((checkbox, innerIndex) => {
                            if (innerIndex === index) {
                                return defaultCheckbox;
                            }
                            else {
                                return checkbox;
                            }
                        }),
                    },
                };
            }
        })
            .filter(common_1.isTruthy);
    }
    else if (filter.filterType === filters_types_1.FilterTypeEnum.ExpandingList &&
        defaultFilter.filterType === filters_types_1.FilterTypeEnum.ExpandingList) {
        return filter.entries
            .map((entry, entryIndex) => {
            var _a;
            if ((0, common_1.areJsonEqual)(entry.checkboxes, (_a = defaultFilter.entries[entryIndex]) === null || _a === void 0 ? void 0 : _a.checkboxes)) {
                return undefined;
            }
            const resetFilter = {
                ...filter,
                entries: filter.entries.map((innerEntry, innerEntryIndex) => {
                    if (innerEntryIndex === entryIndex) {
                        return {
                            ...innerEntry,
                            checkboxes: innerEntry.checkboxes.map((checkbox, checkboxIndex) => {
                                return {
                                    ...checkbox,
                                    checked: defaultFilter.entries[innerEntryIndex].checkboxes[checkboxIndex].checked,
                                };
                            }),
                        };
                    }
                    else {
                        return innerEntry;
                    }
                }),
            };
            return {
                resetValue: resetFilter,
                text: `${filterName}: ${entry.name}`,
            };
        })
            .filter(common_1.isTruthy);
    }
    else if (filter.filterType === filters_types_1.FilterTypeEnum.NumericRange &&
        defaultFilter.filterType === filters_types_1.FilterTypeEnum.NumericRange) {
        const start = filter.currentMin == undefined ? '' : `${(0, common_1.truncateNumber)(filter.currentMin)} < `;
        const end = filter.currentMax == undefined ? '' : ` < ${(0, common_1.truncateNumber)(filter.currentMax)}`;
        return [{ text: `${start}${filterName}${end}`, resetValue: defaultFilter }];
    }
    else if (filter.filterType === filters_types_1.FilterTypeEnum.Radio &&
        defaultFilter.filterType === filters_types_1.FilterTypeEnum.Radio) {
        const selectedRadio = filter.radios.filter(radio => String(radio.value) === filter.value)[0];
        if (!selectedRadio) {
            throw new Error(`Failed to find the selected radio filter.`);
        }
        return [{ text: `${filterName} = ${selectedRadio.label}`, resetValue: defaultFilter }];
    }
    else {
        throw new Error(`Unsupported filter type for tokens: '${defaultFilter.filterType}', '${filter.filterType}'`);
    }
}
