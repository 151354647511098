"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.allWrappedCanisters = exports.getCanisterDetails = void 0;
const ic_turtles_1 = require("./specific-canisters/ic-turtles");
const default_canister_details_1 = require("./default-canister-details");
const ic_drip_1 = require("./specific-canisters/ic-drip");
const ic_punks_1 = require("./specific-canisters/ic-punks");
const icats_1 = require("./specific-canisters/icats");
const icp_bunny_1 = require("./specific-canisters/icp-bunny");
const create_full_canister_details_1 = require("./create-full-canister-details");
const btc_flower_1 = require("./specific-canisters/btc-flower");
const icp_flower_1 = require("./specific-canisters/icp-flower");
const eth_flower_1 = require("./specific-canisters/eth-flower");
const pineapple_punks_1 = require("./specific-canisters/pineapple-punks");
const dfinity_deck_origins_1 = require("./specific-canisters/dfinity-deck-origins");
const ic_dinos_1 = require("./specific-canisters/ic-dinos");
const ic_kitties_1 = require("./specific-canisters/ic-kitties");
const ic_puppies_1 = require("./specific-canisters/ic-puppies");
const cronic_critters_1 = require("./specific-canisters/cronic-critters");
const ic_bucks_1 = require("./specific-canisters/ic-bucks");
const icelebrity_1 = require("./specific-canisters/icelebrity");
const poked_bots_1 = require("./specific-canisters/poked-bots");
const motoko_mechs_1 = require("./specific-canisters/motoko-mechs");
const genesis_ii_1 = require("./specific-canisters/genesis-ii");
const moon_walkers_1 = require("./specific-canisters/moon-walkers");
const pet_bots_1 = require("./specific-canisters/pet-bots");
const flight_1 = require("./specific-canisters/flight");
const drip_bang_1 = require("./specific-canisters/drip-bang");
const imagination_project_1 = require("./specific-canisters/imagination-project");
const allCanisterDetails = [
    btc_flower_1.btcFlowerCanisterDetails,
    cronic_critters_1.cronicCrittersCanisterDetails,
    dfinity_deck_origins_1.dfinityDeckOriginsCanisterDetails,
    drip_bang_1.dripBangCanisterDetails,
    eth_flower_1.ethFlowerCanisterDetails,
    flight_1.flightCanisterDetails,
    genesis_ii_1.genesisIICanisterDetails,
    icats_1.icatsCanisterDetails,
    ic_bucks_1.icBucksCanisterDetails,
    ic_dinos_1.icDinosCanisterDetails,
    ic_drip_1.icDripCanisterDetails,
    icelebrity_1.icelebrityCanisterDetails,
    ic_kitties_1.icKittiesCanisterDetails,
    icp_bunny_1.icpBunnyCanisterDetails,
    icp_flower_1.icpFlowerCanisterDetails,
    ic_punks_1.icPunksCanisterDetails,
    ic_puppies_1.icPuppiesCanisterDetails,
    ic_turtles_1.icTurtlesCanisterDetails,
    imagination_project_1.imaginationProjectCanisterDetails,
    moon_walkers_1.moonWalkersCanisterDetails,
    motoko_mechs_1.motokoMechsCanisterDetails,
    pet_bots_1.petBotsCanisterDetails,
    pineapple_punks_1.pineapplePunksCanisterDetails,
    poked_bots_1.pokeBotsCanisterDetails,
];
/**
 * This maps from both the extWrapped canister id (if it exists) and the original canister id. Thus,
 * canister details can be accessed by either canister id.
 */
const mappedCanisterDetails = Object.fromEntries(allCanisterDetails.map(create_full_canister_details_1.createFullCanisterDetails).flat());
function getCanisterDetails(canisterId) {
    var _a;
    return (_a = mappedCanisterDetails[canisterId]) !== null && _a !== void 0 ? _a : (0, default_canister_details_1.createDefaultCanisterDetails)(canisterId);
}
exports.getCanisterDetails = getCanisterDetails;
exports.allWrappedCanisters = Object.values(mappedCanisterDetails).filter(canisterDetails => canisterDetails.hasWrappedCanister);
