"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.flightCanisterDetails = void 0;
const default_canister_details_1 = require("../default-canister-details");
exports.flightCanisterDetails = {
    collectionName: 'Flight',
    canisterId: 'dylar-wyaaa-aaaah-qcexq-cai',
    getNftImageData(inputs) {
        if (inputs.fullSize) {
            return undefined;
        }
        else {
            const defaultImageData = (0, default_canister_details_1.getDefaultNftImageData)({
                ...inputs,
                canisterId: 'dylar-wyaaa-aaaah-qcexq-cai',
            });
            return {
                ...defaultImageData,
                forcedOriginalImageSize: {
                    width: 200,
                    height: 300,
                },
                extraHtml: inputs.fullSize
                    ? ''
                    : /* HTML */
                        `
                          <style>
                              body {
                                  display: flex;
                                  justify-content: center;
                              }
                              img {
                                  width: unset !important;
                                  max-width: unset !important;
                              }
                          </style>
                      `,
            };
        }
    },
};
