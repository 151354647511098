"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.icelebrityCanisterDetails = void 0;
const ic_canister_domain_1 = require("../../../../api/ic-canister-domain");
const url_1 = require("../../../../augments/url");
exports.icelebrityCanisterDetails = {
    collectionName: 'ICelebrity',
    canisterId: 'kss7i-hqaaa-aaaah-qbvmq-cai',
    getNftImageData({ originalCanisterId, nftId, ref }) {
        const imageUrl = [
            (0, ic_canister_domain_1.getCanisterDomain)(originalCanisterId),
            (0, url_1.createQueryParamString)({ type: 'thumbnail', tokenid: nftId }, [ref]),
        ].join('/');
        return { url: imageUrl };
    },
};
