"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.makeDropShadowCardStyles = void 0;
const lit_1 = require("lit");
const design_system_1 = require("@toniq-labs/design-system");
const element_vir_1 = require("element-vir");
function makeDropShadowCardStyles(selector, allowHover = true) {
    const cssSelector = (0, lit_1.unsafeCSS)(selector);
    const hoverSelector = selector === ':host'
        ? // prettier-ignore
            (0, element_vir_1.css) `
                  :host(:hover)
              `
        : (0, element_vir_1.css) `
                  ${cssSelector}:hover
              `;
    const hoverStyles = allowHover
        ? (0, element_vir_1.css) `
              ${cssSelector} {
                  cursor: pointer;
              }
              ${hoverSelector} {
                  border-color: ${design_system_1.toniqColors.pageInteraction.foregroundColor};
              }
          `
        : (0, element_vir_1.css) ``;
    return (0, element_vir_1.css) `
        ${cssSelector} {
            border-radius: 16px;
            background-color: ${design_system_1.toniqColors.pagePrimary.backgroundColor};
            border: 1px solid transparent;
            padding: 16px;
            cursor: auto;
            /* This helps force the drop shadow to re-render when the element moves */
            will-change: filter;
            ${design_system_1.toniqShadows.popupShadow};
        }

        ${hoverStyles}
    `;
}
exports.makeDropShadowCardStyles = makeDropShadowCardStyles;
