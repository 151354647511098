"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.encodeNftId = void 0;
const principal_1 = require("@dfinity/principal");
const bits_1 = require("../augments/bits");
function encodeNftId(principalAddress, nftIndex) {
    const padding = Buffer.from('\x0Atid');
    const array = new Uint8Array([
        ...padding,
        ...principal_1.Principal.fromText(principalAddress).toUint8Array(),
        ...(0, bits_1.to32bitArray)(nftIndex),
    ]);
    return principal_1.Principal.fromUint8Array(array).toText();
}
exports.encodeNftId = encodeNftId;
