"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.profileNftSortDefinitions = exports.defaultProfileNftFilters = void 0;
const common_1 = require("@augment-vir/common");
const filters_types_1 = require("../../../../common/with-filters/filters-types");
const icp_1 = require("../../../../../../data/icp");
exports.defaultProfileNftFilters = (0, common_1.wrapNarrowTypeWithTypeCheck)()({
    'List Status': {
        filterType: filters_types_1.FilterTypeEnum.Radio,
        radios: [
            {
                label: 'All',
                value: 'All',
                filterType: filters_types_1.BooleanFilterTypeEnum.Everything,
            },
            {
                label: 'Listed',
                value: true,
            },
            {
                label: 'Unlisted',
                value: false,
            },
        ],
        filterField: ['isListed'],
        value: 'All',
    },
    Offers: {
        filterType: filters_types_1.FilterTypeEnum.Radio,
        radios: [
            {
                label: 'All',
                value: 'All',
                filterType: filters_types_1.BooleanFilterTypeEnum.Everything,
            },
            {
                label: 'Has offers',
                value: 'received',
                filterType: filters_types_1.BooleanFilterTypeEnum.Contains,
            },
            {
                label: 'No offers',
                value: 'none',
            },
        ],
        filterField: ['offerStatus'],
        value: 'All',
    },
    'List Price': {
        filterType: filters_types_1.FilterTypeEnum.NumericRange,
        currentMin: undefined,
        currentMax: undefined,
        filterField: [
            'listing',
            'price',
        ],
        factor: 1 / icp_1.icpFactor,
    },
    NRI: {
        filterType: filters_types_1.FilterTypeEnum.NumericRange,
        currentMin: undefined,
        currentMax: undefined,
        filterField: ['nftNri'],
        factor: 100,
    },
});
exports.profileNftSortDefinitions = (0, common_1.wrapNarrowTypeWithTypeCheck)()([
    {
        sortName: 'Mint #',
        sortField: [
            'nftMintNumber',
        ],
    },
    {
        sortName: 'Price',
        sortField: [
            'listing',
            'price',
        ],
    },
    {
        sortName: 'Rarity',
        sortField: [
            'nftNri',
        ],
    },
]);
