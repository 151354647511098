"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntrepotCreateCardElement = void 0;
const element_vir_1 = require("element-vir");
const design_system_1 = require("@toniq-labs/design-system");
exports.EntrepotCreateCardElement = (0, design_system_1.defineToniqElement)()({
    tagName: 'toniq-entrepot-create-card',
    styles: (0, element_vir_1.css) `
        :host {
            display: flex;
            flex-direction: column;
            border-radius: 8px;
            box-sizing: border-box;
            padding: 48px;
            border: 1px solid ${design_system_1.toniqColors.divider.foregroundColor};
            ${(0, design_system_1.applyBackgroundAndForeground)(design_system_1.toniqColors.pagePrimary)}
            ${design_system_1.toniqShadows.popupShadow}
        }

        h2 {
            margin: 0;
            margin-top: 16px;
            margin-bottom: 32px;
            ${design_system_1.toniqFontStyles.h2Font}
        }

        .subtitle {
            ${design_system_1.toniqFontStyles.boldParagraphFont}
            color: ${design_system_1.toniqColors.pageInteraction.foregroundColor};
        }

        .bullet-checks,
        .buttons {
            display: flex;
            flex-direction: column;
            gap: 12px;
        }

        .bullet-checks {
            margin-bottom: 48px;
        }

        .top-content {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
        }

        ${design_system_1.ToniqIcon} {
            color: ${design_system_1.toniqColors.pageInteraction.foregroundColor};
        }

        ${design_system_1.ToniqCheckbox} {
            pointer-events: none;
            ${design_system_1.ToniqCheckbox.cssVarNames.checkedLabelColor}: ${design_system_1.toniqColors.pagePrimary
        .foregroundColor};
        }
    `,
    renderCallback: ({ inputs }) => {
        const subtitleTemplate = inputs.subtitle
            ? (0, element_vir_1.html) `
                  <span class="subtitle">${inputs.subtitle}</span>
              `
            : '';
        const bulletsTemplate = inputs.bullets.map(bullet => {
            return (0, element_vir_1.html) `
                <${design_system_1.ToniqCheckbox}
                    ${(0, element_vir_1.assign)(design_system_1.ToniqCheckbox, {
                checked: true,
                text: bullet,
            })}
                ></${design_system_1.ToniqCheckbox}>
            `;
        });
        const buttonsTemplate = inputs.buttons.map(button => {
            return (0, element_vir_1.html) `
                <${design_system_1.ToniqButton}
                    class=${button.primary ? '' : design_system_1.ToniqButton.hostClasses.outline}
                    ${(0, element_vir_1.assign)(design_system_1.ToniqButton, {
                text: button.text,
            })}
                    ${(0, element_vir_1.listen)('click', () => {
                if (button.link)
                    window.open(button.link, '_blank');
            })}
                ></${design_system_1.ToniqButton}>
            `;
        });
        return (0, element_vir_1.html) `
            <div class="top-content">
                <${design_system_1.ToniqIcon}
                    ${(0, element_vir_1.assign)(design_system_1.ToniqIcon, { icon: inputs.icon })}
                ></${design_system_1.ToniqIcon}>
                <h2>${inputs.title}${subtitleTemplate}</h2>
                <div class="bullet-checks">${bulletsTemplate}</div>
            </div>
            <div class="buttons">${buttonsTemplate}</div>
        `;
    },
});
