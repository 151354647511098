"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createFullCanisterDetails = void 0;
const common_1 = require("@augment-vir/common");
const default_canister_details_1 = require("./default-canister-details");
async function fullGetNftImageHtml({ rawCanisterDetails, inputs, originalCanisterId, defaultFallbacks, }) {
    var _a, _b, _c;
    try {
        const inputsForRawCanisterDetails = {
            priority: 10,
            fullSize: false,
            ...inputs,
            originalCanisterId,
        };
        const customNftLinkUrl = (_a = rawCanisterDetails.getNftLinkUrl) === null || _a === void 0 ? void 0 : _a.call(rawCanisterDetails, inputsForRawCanisterDetails);
        const nftLinkUrl = customNftLinkUrl !== null && customNftLinkUrl !== void 0 ? customNftLinkUrl : defaultFallbacks.getNftLinkUrl(inputsForRawCanisterDetails);
        const customData = (_c = (await ((_b = rawCanisterDetails.getNftImageData) === null || _b === void 0 ? void 0 : _b.call(rawCanisterDetails, {
            ...inputsForRawCanisterDetails,
            nftLinkUrl,
        })))) !== null && _c !== void 0 ? _c : (customNftLinkUrl ? { url: customNftLinkUrl } : undefined);
        const finalImageData = customData !== null && customData !== void 0 ? customData : defaultFallbacks.getNftImageData(inputsForRawCanisterDetails);
        return finalImageData;
    }
    catch (error) {
        console.error({ ...inputs });
        throw new Error(`Failed to get NFT image HTML for '${rawCanisterDetails.collectionName}' with inputs: ${(0, common_1.extractErrorMessage)(error)}`);
    }
}
function createFullCanisterDetails(rawCanisterDetails) {
    const canisterIds = (0, common_1.isRuntimeTypeOf)(rawCanisterDetails.canisterId, 'string')
        ? [rawCanisterDetails.canisterId]
        : Object.values(rawCanisterDetails.canisterId);
    const isWrappedCanister = !(0, common_1.isRuntimeTypeOf)(rawCanisterDetails.canisterId, 'string');
    const extCanisterId = (0, common_1.isRuntimeTypeOf)(rawCanisterDetails.canisterId, 'string')
        ? rawCanisterDetails.canisterId
        : rawCanisterDetails.canisterId.extWrapped;
    const originalCanisterId = (0, common_1.isRuntimeTypeOf)(rawCanisterDetails.canisterId, 'string')
        ? rawCanisterDetails.canisterId
        : rawCanisterDetails.canisterId.original;
    const defaultFallbacks = (0, default_canister_details_1.createDefaultCanisterDetails)(originalCanisterId);
    const fullCanisterDetails = {
        ...rawCanisterDetails,
        hasWrappedCanister: isWrappedCanister,
        extCanisterId,
        originalCanisterId,
        getNftLinkUrl: inputs => {
            var _a;
            return (_a = defaultFallbacks.getNftLinkUrl(inputs)) !== null && _a !== void 0 ? _a : defaultFallbacks.getNftLinkUrl(inputs);
        },
        getNftImageData: async (inputs) => {
            return fullGetNftImageHtml({
                rawCanisterDetails,
                inputs,
                originalCanisterId,
                defaultFallbacks,
            });
        },
    };
    return canisterIds.map((canisterId) => [
        canisterId,
        fullCanisterDetails,
    ]);
}
exports.createFullCanisterDetails = createFullCanisterDetails;
