"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntrepotWithFiltersElement = exports.createWithFiltersInputs = void 0;
const repeat_js_1 = require("lit/directives/repeat.js");
const class_map_js_1 = require("lit/directives/class-map.js");
const ref_js_1 = require("lit/directives/ref.js");
const ref_js_2 = require("lit/directives/ref.js");
const element_vir_1 = require("element-vir");
const apply_filters_1 = require("./apply-filters");
const design_system_1 = require("@toniq-labs/design-system");
const toniq_entrepot_filter_element_1 = require("./toniq-entrepot-filter.element");
const apply_sort_1 = require("./apply-sort");
const toniq_entrepot_filter_token_element_1 = require("./toniq-entrepot-filter-token.element");
const is_still_default_1 = require("./is-still-default");
const fixed_sizes_1 = require("../../../fixed-sizes");
const lodash_1 = require("lodash");
/**
 * Use this function when creating inputs for the "with filters" element below so that the generic
 * inferences work correctly.
 */
function createWithFiltersInputs(inputs) {
    return inputs;
}
exports.createWithFiltersInputs = createWithFiltersInputs;
exports.EntrepotWithFiltersElement = (0, design_system_1.defineToniqElement)()({
    tagName: 'toniq-entrepot-with-filters',
    cssVars: {
        filterPanelWidth: '333px',
    },
    styles: ({ cssVarValues, cssVarNames }) => (0, element_vir_1.css) `
        :host {
            display: flex;
            flex-direction: column;
            ${design_system_1.toniqFontStyles.paragraphFont};
        }

        .search-sort-header {
            padding-top: 16px;
            margin-bottom: ${fixed_sizes_1.filterScrollHeaderBottomMargin}px;
            box-sizing: border-box;
            height: ${fixed_sizes_1.filterScrollHeaderHeight}px;
            position: sticky;
            top: 70px;
            z-index: 10;
            ${(0, design_system_1.applyBackgroundAndForeground)(design_system_1.toniqColors.pagePrimary)}
        }

        .shadow-wrapper {
            display: flex;
            gap: 24px;
            height: 40px;
            padding: 16px 32px 8px;
            ${(0, design_system_1.applyBackgroundAndForeground)(design_system_1.toniqColors.pagePrimary)}

            box-shadow: 0 0 10px 15px white;
        }

        .shadow-wrapper ${design_system_1.ToniqInput} {
            flex-grow: 1;
        }

        .bottom-half {
            display: flex;
            padding: 0 32px;
        }

        .filter-tokens-and-count {
            padding: 0 16px;
            height: 56px;
            display: flex;
            gap: 16px;
            align-items: center;
        }

        .filter-tokens {
            flex-grow: 1;
            display: flex;
            gap: 16px;
        }

        ${toniq_entrepot_filter_token_element_1.EntrepotFilterTokenElement} {
            gap: 16px;
        }

        .count {
            color: ${design_system_1.toniqColors.pageSecondary.foregroundColor};
        }

        .content {
            display: flex;
            flex-grow: 1;
            flex-direction: column;
            z-index: 5;
            ${(0, design_system_1.applyBackgroundAndForeground)(design_system_1.toniqColors.pagePrimary)}
        }

        .content-entries {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;
            gap: 16px 4px;
        }

        .content-entries.is-loading-entries {
            justify-content: center;
        }

        .filters-panel-wrapper {
            opacity: 0;
            display: flex;
            width: 0;
            transition: 200ms;
            flex-shrink: 0;
            align-items: flex-start;
        }

        .filters-panel-wrapper.show-filters-panel {
            opacity: 1;
            width: ${cssVarValues.filterPanelWidth};
        }

        .filters-panel {
            max-height: calc(
                100vh -
                    ${fixed_sizes_1.mainEntrepotHeaderHeight +
        fixed_sizes_1.filterScrollHeaderHeight +
        fixed_sizes_1.filterScrollHeaderBottomMargin}px
            );
            overflow-y: auto;
            position: sticky;
            box-sizing: border-box;
            padding-right: 8px;
            /* sufficient padding for selection outlines */
            padding-left: 8px;
            padding-bottom: 64px;
            right: 0;
            top: 174px;
            width: ${cssVarValues.filterPanelWidth};
            flex-shrink: 0;
            display: flex;
            flex-direction: column;
            gap: 32px;
            align-items: stretch;
        }

        hr {
            border: 0;
            height: 1px;
            background: ${design_system_1.toniqColors.pageSecondary.foregroundColor};
            margin: 0;
            padding: 0;
            width: 100%;
        }

        .sort-combo {
            display: flex;
            align-items: center;
        }

        .sort-combo ${design_system_1.ToniqIcon} {
            cursor: pointer;
        }

        .sort-dropdown {
            width: unset;
            min-height: 40px;
            max-height: 40px;
            ${design_system_1.toniqColorCssVarNames.accentSecondary.backgroundColor}: transparent;
        }

        .hidden {
            display: none;
        }

        .clear-all-token {
            ${design_system_1.removeNativeFormStyles}
            ${design_system_1.toniqFontStyles.paragraphFont}
            cursor: pointer;
        }
        .clear-all-token:hover {
            color: ${design_system_1.toniqColors.pageInteraction.foregroundColor};
        }

        .filter-tokens-and-count .sort-combo {
            display: none;
        }

        .shadow-footer {
            position: sticky;
            bottom: 0;
            width: 100vw;
            z-index: 10;
            margin-top: 64px;
            box-shadow: 0 0 10px 15px white;
        }

        .state-container {
            display: flex;
            justify-content: center;
            align-items: center;
            background: #ffffff;
            border: 1px ${design_system_1.toniqColors.pageInteraction.foregroundColor} solid;
            border-radius: 8px;
            padding: 12px 16px;
            width: max-content;
            margin-left: auto;
            margin-right: auto;
            margin-top: 32px;
            ${design_system_1.toniqFontStyles.boldParagraphFont};
            text-align: center;
        }

        @media (max-width: 1200px) {
            :host {
                ${cssVarNames.filterPanelWidth}: 200px;
            }
        }

        @media (max-width: 950px) {
            :host {
                ${cssVarNames.filterPanelWidth}: 500px;
            }

            .search-sort-header .sort-combo {
                display: none;
            }

            .filter-tokens-and-count {
                justify-content: space-between;
                padding: 0;
            }

            .filter-tokens-and-count .sort-combo {
                display: flex;
            }

            .filter-tokens {
                display: none;
            }

            .bottom-half {
                flex-direction: column;
            }

            .filters-panel-wrapper {
                max-width: 100%;
                width: unset;
                justify-content: center;
                height: 0;
            }

            .filters-panel-wrapper.show-filters-panel {
                width: unset;
                height: unset;
            }

            .filters-panel {
                max-width: 100%;
                top: unset;
                right: unset;
            }
        }
    `,
    stateInit: {
        searchValue: '',
        loadingRef: (0, ref_js_2.createRef)(),
        pageListing: 0,
        hasRenderedOnce: false,
    },
    initCallback: ({ state, updateState }) => {
        const searchTerm = new URL(document.location.href).searchParams.get('search');
        if (searchTerm !== null)
            updateState({ searchValue: searchTerm });
        const options = {
            root: null,
            rootMargin: '-32px',
            threshold: 0,
        };
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.intersectionRatio > 0) {
                    updateState({ pageListing: state.pageListing + 1 });
                }
            });
        }, options);
        const loadingRefInterval = setInterval(() => {
            const loadingRefEl = state.loadingRef.value;
            if (loadingRefEl) {
                observer.observe(loadingRefEl);
                clearInterval(loadingRefInterval);
            }
        }, 1000);
    },
    events: {
        showFiltersChange: (0, element_vir_1.defineElementEvent)(),
        filtersChange: (0, element_vir_1.defineElementEvent)(),
        sortChange: (0, element_vir_1.defineElementEvent)(),
    },
    renderCallback: ({ inputs, dispatch, events, state, updateState }) => {
        const filteredEntries = inputs.allEntries.filter(entry => {
            return (inputs.searchCallback(state.searchValue, entry) &&
                (0, apply_filters_1.applyAllFilters)(entry, inputs.currentFilters));
        });
        const sortedFilteredEntries = (0, apply_sort_1.applySort)({
            ...inputs,
            entries: filteredEntries,
        });
        const chunkedAndSortedFilteredEntries = (0, lodash_1.chunk)(sortedFilteredEntries, 4);
        const getEntries = chunkedAndSortedFilteredEntries.reduce((current, entry, index) => {
            if (index <= state.pageListing) {
                return current.concat(entry);
            }
            else {
                return current;
            }
        }, []);
        const filterTemplates = Object.keys(inputs.currentFilters).map((filterName, index) => {
            const filter = inputs.currentFilters[filterName];
            return (0, element_vir_1.html) `
                ${index > 0
                ? (0, element_vir_1.html) `
                              <hr />
                          `
                : ''}
                <${toniq_entrepot_filter_element_1.EntrepotFilterElement}
                    ${(0, element_vir_1.listen)(toniq_entrepot_filter_element_1.EntrepotFilterElement.events.filterChange, event => {
                dispatch(new events.filtersChange({
                    ...inputs.currentFilters,
                    ...event.detail,
                }));
            })}
                    ${(0, element_vir_1.assign)(toniq_entrepot_filter_element_1.EntrepotFilterElement, {
                filter,
                filterName,
            })}
                ></${toniq_entrepot_filter_element_1.EntrepotFilterElement}>
            `;
        });
        const sortTemplate = (0, element_vir_1.html) `
            <div class="sort-combo">
                <${design_system_1.ToniqIcon}
                    ${(0, element_vir_1.assign)(design_system_1.ToniqIcon, {
            icon: inputs.currentSort.ascending
                ? design_system_1.ArrowsSortAscending24Icon
                : design_system_1.ArrowsSortDescending24Icon,
        })}
                    ${(0, element_vir_1.listen)('click', () => {
            dispatch(new events.sortChange({
                ...inputs.currentSort,
                ascending: !inputs.currentSort.ascending,
            }));
        })}
                ></${design_system_1.ToniqIcon}>
                <${design_system_1.ToniqDropdown}
                    class="sort-dropdown"
                    ${(0, element_vir_1.assign)(design_system_1.ToniqDropdown, {
            options: inputs.sortDefinitions.map(definition => {
                return {
                    label: definition.sortName,
                    value: definition.sortName,
                };
            }),
            selected: {
                label: inputs.currentSort.name,
                value: inputs.currentSort.name,
            },
        })}
                    ${(0, element_vir_1.listen)(design_system_1.ToniqDropdown.events.selectChange, event => {
            dispatch(new events.sortChange({
                ...inputs.currentSort,
                name: event.detail.value,
            }));
        })}
                ></${design_system_1.ToniqDropdown}>
                <slot name="extra-controls"></slot>
            </div>
        `;
        const searchTemplate = (0, element_vir_1.html) `
            <${design_system_1.ToniqToggleButton}
                class="${design_system_1.ToniqToggleButton.hostClasses.textOnly}"
                ${(0, element_vir_1.assign)(design_system_1.ToniqToggleButton, {
            toggled: inputs.showFilters,
            text: state.searchValue ? '' : 'Filters',
            icon: design_system_1.Filter24Icon,
        })}
                ${(0, element_vir_1.listen)('click', () => {
            dispatch(new events.showFiltersChange(!inputs.showFilters));
        })}
            ></${design_system_1.ToniqToggleButton}>
            <${design_system_1.ToniqInput}
                class="${design_system_1.ToniqInput.hostClasses.outline}"
                ${(0, element_vir_1.assign)(design_system_1.ToniqInput, {
            value: state.searchValue,
            placeholder: inputs.searchPlaceholder,
            icon: design_system_1.Search24Icon,
            disableBrowserHelps: true,
        })}
                ${(0, element_vir_1.listen)(design_system_1.ToniqInput.events.valueChange, event => {
            updateState({
                searchValue: event.detail,
            });
        })}
            ></${design_system_1.ToniqInput}>
            ${sortTemplate}
        `;
        const nonDefaultFilterCount = (0, is_still_default_1.countFiltersNotAtDefaults)({
            filters: inputs.currentFilters,
            defaultFilters: inputs.defaultFilters,
        });
        const filterTokensTemplates = Object.keys(inputs.currentFilters).map(filterName => {
            const filter = inputs.currentFilters[filterName];
            const defaultFilter = inputs.defaultFilters[filterName];
            return (0, element_vir_1.html) `
                <${toniq_entrepot_filter_token_element_1.EntrepotFilterTokenElement}
                    ${(0, element_vir_1.assign)(toniq_entrepot_filter_token_element_1.EntrepotFilterTokenElement, {
                defaultFilter,
                filter,
                filterName,
            })}
                    ${(0, element_vir_1.listen)(toniq_entrepot_filter_token_element_1.EntrepotFilterTokenElement.events.resetFilter, event => {
                dispatch(new events.filtersChange({
                    ...inputs.currentFilters,
                    [filterName]: event.detail,
                }));
            })}
                ></${toniq_entrepot_filter_token_element_1.EntrepotFilterTokenElement}>
            `;
        });
        return (0, element_vir_1.html) `
            <div class="search-sort-header">
                <div class="shadow-wrapper">${searchTemplate}</div>
            </div>
            <div class="bottom-half">
                <div
                    class="filters-panel-wrapper ${(0, class_map_js_1.classMap)({
            'show-filters-panel': inputs.showFilters,
        })}"
                >
                    <div class="filters-panel">${filterTemplates}</div>
                </div>
                <div class="content">
                    <div class="filter-tokens-and-count">
                        <div class="filter-tokens">
                            ${filterTokensTemplates}
                            <button
                                class="clear-all-token ${(0, class_map_js_1.classMap)({
            hidden: nonDefaultFilterCount <= 1,
        })}"
                                ${(0, element_vir_1.listen)('click', () => {
            dispatch(new events.filtersChange(inputs.defaultFilters));
        })}
                            >
                                Clear All
                            </button>
                        </div>
                        <span class="count">
                            ${sortedFilteredEntries.length} ${inputs.countName}
                        </span>
                        ${sortTemplate}
                    </div>
                    <div
                        class=${(0, class_map_js_1.classMap)({
            'content-entries': true,
            'is-loading-entries': !!inputs.isLoading,
        })}
                    >
                        <slot name="entries-header"></slot>
                        ${(0, element_vir_1.renderIf)(!!inputs.isLoading, (0, element_vir_1.html) `
                                <${design_system_1.ToniqIcon}
                                    ${(0, element_vir_1.assign)(design_system_1.ToniqIcon, {
            icon: design_system_1.LoaderAnimated24Icon,
        })}
                                ></${design_system_1.ToniqIcon}>
                            `, (0, repeat_js_1.repeat)(getEntries, entry => entry.nftId, entry => inputs.createEntryTemplateCallback(entry)))}
                    </div>
                    <div ${(0, ref_js_1.ref)(state.loadingRef)}>
                        ${(0, element_vir_1.renderIf)(!getEntries.length, (0, element_vir_1.html) `
                                <div class="state-container">No Result</div>
                            `, (0, element_vir_1.html) `
                                ${(0, element_vir_1.renderIf)(state.pageListing >= chunkedAndSortedFilteredEntries.length, (0, element_vir_1.html) `
                                        <div class="state-container">End of Results</div>
                                    `, (0, element_vir_1.html) `
                                <div class="state-container">
                                    <${design_system_1.ToniqIcon}
                                        ${(0, element_vir_1.assign)(design_system_1.ToniqIcon, {
            icon: design_system_1.LoaderAnimated24Icon,
        })}
                                    ></${design_system_1.ToniqIcon}>
                                    &nbsp;Loading...
                                </div>
                            `)}
                            `)}
                    </div>
                </div>
            </div>
            <div class="shadow-footer"></div>
        `;
    },
});
