"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deleteDatabase = void 0;
const common_1 = require("@augment-vir/common");
async function deleteDatabase(name) {
    const deferredPromise = (0, common_1.createDeferredPromiseWrapper)();
    const request = globalThis.indexedDB.deleteDatabase(name);
    request.onerror = event => {
        deferredPromise.reject(event);
    };
    request.onsuccess = () => {
        deferredPromise.resolve();
    };
    return deferredPromise.promise;
}
exports.deleteDatabase = deleteDatabase;
