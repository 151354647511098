"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.icDripCanisterDetails = void 0;
const ic_canister_domain_1 = require("../../../../api/ic-canister-domain");
exports.icDripCanisterDetails = {
    collectionName: 'IC Drip',
    canisterId: {
        original: 'd3ttm-qaaaa-aaaai-qam4a-cai',
        extWrapped: '3db6u-aiaaa-aaaah-qbjbq-cai',
    },
    getNftLinkUrl({ originalCanisterId, nftIndex }) {
        return [
            (0, ic_canister_domain_1.getCanisterDomain)(originalCanisterId),
            '?tokenId=',
            nftIndex,
        ].join('');
    },
};
