"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.urlStringToFilters = exports.filterValueObjectToUrlString = exports.filtersToUrlString = void 0;
const common_1 = require("@augment-vir/common");
const filters_types_1 = require("./filters-types");
const is_still_default_1 = require("./is-still-default");
function filtersToUrlString(currentFilters, defaultFilters) {
    const modifiedFilterKeys = Object.keys(currentFilters).filter(filterName => {
        const filter = currentFilters[filterName];
        const defaultFilter = defaultFilters[filterName];
        if (!filter || !defaultFilter) {
            return false;
        }
        return !(0, is_still_default_1.isStillAtDefaults)({
            defaultFilter,
            filter,
        });
    });
    const urlValues = Object.fromEntries(modifiedFilterKeys
        .map((filterKey) => {
        const filter = currentFilters[filterKey];
        const defaultFilter = defaultFilters[filterKey];
        if (!defaultFilter) {
            throw new Error(`Failed to find default filter by key '${filterKey}'`);
        }
        const urlValue = getFilterDiff({
            filter,
            defaultFilter,
            filterKey,
        });
        if (urlValue) {
            return [
                filterKey,
                urlValue,
            ];
        }
        else {
            return undefined;
        }
    })
        .filter(common_1.isTruthy));
    return filterValueObjectToUrlString(urlValues);
}
exports.filtersToUrlString = filtersToUrlString;
function filterValueObjectToUrlString(filterValueObject) {
    return encodeURIComponent(JSON.stringify(filterValueObject));
}
exports.filterValueObjectToUrlString = filterValueObjectToUrlString;
function urlStringToFilters({ filterString, defaultFilters, }) {
    try {
        const newFilters = (0, common_1.copyThroughJson)(defaultFilters);
        const parsedFilterString = JSON.parse(decodeURIComponent(filterString));
        if (!(0, common_1.isObject)(parsedFilterString)) {
            throw new Error('parsed filter string is not an object');
        }
        (0, common_1.getObjectTypedKeys)(parsedFilterString).forEach(filterKey => {
            if (!(filterKey in newFilters)) {
                throw new Error(`Invalid filter key: ${filterKey}`);
            }
            const urlValue = parsedFilterString[filterKey];
            const filter = newFilters[filterKey];
            if (filter.filterType === filters_types_1.FilterTypeEnum.Checkboxes) {
                const checkBoxIndexesToChange = urlValue.split(',').map(value => Number(value));
                filter.checkboxes.forEach((checkbox, index) => {
                    if (checkBoxIndexesToChange.includes(index)) {
                        filter.checkboxes[index].checked = !checkbox.checked;
                    }
                });
            }
            else if (filter.filterType === filters_types_1.FilterTypeEnum.Radio) {
                if (!filter.radios.some(radio => radio.value === urlValue)) {
                    console.error(`Found no radio in filter '${filterKey}' with value '${urlValue}'`);
                    return;
                }
                if (typeof filter.value === 'boolean') {
                    filter.value = Boolean(urlValue);
                }
                else {
                    filter.value = urlValue;
                }
            }
            else if (filter.filterType === filters_types_1.FilterTypeEnum.NumericRange) {
                const [min, max,] = urlValue.split(',').map(value => (value === '' ? '' : Number(value)));
                if (min !== '' && min != undefined && !isNaN(min)) {
                    filter.currentMin = min;
                }
                if (max !== '' && max != undefined && !isNaN(max)) {
                    filter.currentMax = max;
                }
            }
        });
        return newFilters;
    }
    catch (error) {
        console.error(`failed to parse filterString`, filterString, error);
        return undefined;
    }
}
exports.urlStringToFilters = urlStringToFilters;
function getFilterDiff({ filter, defaultFilter, filterKey, }) {
    if (filter.filterType === filters_types_1.FilterTypeEnum.Checkboxes &&
        defaultFilter.filterType === filters_types_1.FilterTypeEnum.Checkboxes) {
        const modifiedCheckboxIndexes = filter.checkboxes.map((checkbox, index) => {
            const defaultCheckbox = defaultFilter.checkboxes[index];
            if (!defaultCheckbox) {
                throw new Error(`Failed to find default checkbox at index '${index}' on filter '${filterKey}'`);
            }
            if (checkbox.checked === (defaultCheckbox === null || defaultCheckbox === void 0 ? void 0 : defaultCheckbox.checked)) {
                return undefined;
            }
            else {
                return index;
            }
        });
        return modifiedCheckboxIndexes.filter(entry => entry == undefined).join(',');
    }
    else if (filter.filterType === filters_types_1.FilterTypeEnum.Radio &&
        defaultFilter.filterType === filters_types_1.FilterTypeEnum.Radio) {
        if (filter.value === defaultFilter.value) {
            return '';
        }
        else {
            return String(filter.value);
        }
    }
    else if (filter.filterType === filters_types_1.FilterTypeEnum.NumericRange &&
        defaultFilter.filterType === filters_types_1.FilterTypeEnum.NumericRange) {
        const min = filter.currentMin === defaultFilter.currentMin ? '' : String(defaultFilter.currentMin);
        const max = filter.currentMax === defaultFilter.currentMax ? '' : String(defaultFilter.currentMax);
        if (min || max) {
            return [
                min,
                max,
            ].join(',');
        }
        else {
            return '';
        }
    }
    // the other filter types are not supported as URL strings
    return '';
}
