"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.pineapplePunksCanisterDetails = void 0;
const ic_canister_domain_1 = require("../../../../api/ic-canister-domain");
const entrepot_images_url_1 = require("../../../../api/entrepot-apis/entrepot-images-url");
exports.pineapplePunksCanisterDetails = {
    collectionName: 'Pineapple Punks',
    canisterId: 'skjpp-haaaa-aaaae-qac7q-cai',
    getNftImageData({ originalCanisterId, fullSize, nftId }) {
        const imageUrl = fullSize
            ? `${(0, ic_canister_domain_1.getCanisterDomain)(originalCanisterId)}/?tokenid=${nftId}`
            : (0, entrepot_images_url_1.formEntrepotImagesUrl)({
                entrepotImagesCanisterId: 'wtwf2-biaaa-aaaam-qauoq-cai',
                nftId,
            });
        return { url: imageUrl };
    },
};
