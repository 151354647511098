"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntrepotProfileNftListItemTextItemsElement = void 0;
const class_map_js_1 = require("lit/directives/class-map.js");
const element_vir_1 = require("element-vir");
const design_system_1 = require("@toniq-labs/design-system");
const icp_1 = require("../../../../../data/icp");
const common_1 = require("@augment-vir/common");
const headerTitles = {
    mint: 'MINT #',
    nri: 'NRI',
    price: 'PRICE',
};
exports.EntrepotProfileNftListItemTextItemsElement = (0, design_system_1.defineToniqElement)()({
    tagName: 'toniq-entrepot-profile-nft-list-item-text-items',
    hostClasses: {
        header: ({ inputs }) => !!inputs.isHeader,
    },
    styles: ({ hostClassSelectors }) => (0, element_vir_1.css) `
        :host {
            display: flex;
            gap: 16px;
        }

        :host > div {
            flex-grow: 1;
            display: flex;
            align-items: center;
            flex-basis: 0;
            flex-shrink: 1;
        }

        .nft-mint-number {
            margin-left: 32px;
            max-width: 120px;
        }

        ${hostClassSelectors.header} {
            ${design_system_1.toniqFontStyles.labelFont}
            ${(0, design_system_1.applyBackgroundAndForeground)(design_system_1.toniqColors.accentSecondary)}
            border-radius: 8px;
            padding: 8px 16px;
        }

        ${hostClassSelectors.header} .nft-mint-number {
            margin-left: 96px;
        }

        .monospace-price {
            ${design_system_1.toniqFontStyles.boldFont};
            ${design_system_1.toniqFontStyles.monospaceFont};
        }

        .nft-price ${design_system_1.ToniqIcon} {
            margin-right: 4px;
        }

        .nft-rarity {
            max-width: 64px;
        }

        .slot-wrapper {
            display: flex;
            justify-content: flex-end;
        }
    `,
    renderCallback: ({ inputs }) => {
        var _a, _b, _c, _d, _e, _f;
        const isHeader = !!inputs.isHeader || !inputs.nft;
        const hasListing = ((_b = (_a = inputs.nft) === null || _a === void 0 ? void 0 : _a.listing) === null || _b === void 0 ? void 0 : _b.hasOwnProperty('price'))
            ? ((_d = (_c = inputs.nft) === null || _c === void 0 ? void 0 : _c.listing) === null || _d === void 0 ? void 0 : _d.price) > 0
            : false;
        const listPriceText = hasListing && inputs.nft ? (0, icp_1.toIcp)(BigInt(inputs.nft.listing.price)) : '-';
        const rarityText = inputs.nft && (0, common_1.isRuntimeTypeOf)((_e = inputs.nft) === null || _e === void 0 ? void 0 : _e.nftNri, 'number')
            ? `NRI: ${(inputs.nft.nftNri * 100).toFixed(1)}%`
            : '-';
        const mintNumberDisplay = isHeader ? headerTitles.mint : `#${(_f = inputs.nft) === null || _f === void 0 ? void 0 : _f.nftMintNumber}`;
        const rarityDisplay = isHeader ? headerTitles.nri : rarityText;
        const priceDisplay = isHeader ? headerTitles.price : listPriceText;
        return (0, element_vir_1.html) `
            <div class="nft-mint-number">${mintNumberDisplay}</div>
            <div class="nft-rarity">${rarityDisplay}</div>
            <div class="nft-price">
                ${(0, element_vir_1.renderIf)(!!hasListing && !isHeader, (0, element_vir_1.html) `
                        <${design_system_1.ToniqIcon}
                            ${(0, element_vir_1.assign)(design_system_1.ToniqIcon, { icon: design_system_1.Icp16Icon })}
                        ></${design_system_1.ToniqIcon}>
                    `)}
                <span class=${(0, class_map_js_1.classMap)({ 'monospace-price': hasListing })}>${priceDisplay}</span>
            </div>
            <div class="slot-wrapper">
                ${(0, element_vir_1.renderIf)(!isHeader, (0, element_vir_1.html) `
                        <slot></slot>
                    `, inputs.finalItemHeaderTitle)}
            </div>
        `;
    },
});
