"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntrepotNftListItemElement = void 0;
const element_vir_1 = require("element-vir");
const design_system_1 = require("@toniq-labs/design-system");
const toniq_entrepot_nft_display_element_1 = require("./toniq-entrepot-nft-display.element");
exports.EntrepotNftListItemElement = (0, design_system_1.defineToniqElement)()({
    tagName: 'toniq-entrepot-nft-list-item',
    hostClasses: {
        blockInteraction: false,
    },
    styles: ({ hostClassSelectors, hostClassNames }) => (0, element_vir_1.css) `
        :host {
            max-height: 64px;
            max-width: 100%;
            ${(0, design_system_1.applyBackgroundAndForeground)(design_system_1.toniqColors.pagePrimary)}
            color: ${design_system_1.toniqColors.pagePrimary.foregroundColor};
            border-radius: 16px;
            overflow: hidden;
            display: flex;
            ${design_system_1.toniqShadows.popupShadow}
            transition: ${design_system_1.interactionDuration};
            padding: 16px;
            cursor: pointer;
            border: 1px solid transparent;
        }

        :host(:hover) {
            border-color: ${design_system_1.toniqColors.pageInteraction.foregroundColor};
        }

        ${hostClassSelectors.blockInteraction} {
            cursor: auto;
        }

        :host(.${hostClassNames.blockInteraction}:hover) {
            border-color: transparent;
        }

        .image-wrapper {
            position: relative;
        }

        .other-contents {
            display: flex;
            flex-grow: 1;
            align-items: stretch;
        }
    `,
    renderCallback: ({ inputs }) => {
        return (0, element_vir_1.html) `
            <div class="image-wrapper">
                <${toniq_entrepot_nft_display_element_1.EntrepotNftDisplayElement}
                    ${(0, element_vir_1.assign)(toniq_entrepot_nft_display_element_1.EntrepotNftDisplayElement, {
            collectionId: inputs.nft.collectionId,
            fullSize: false,
            cachePriority: 0,
            nftId: inputs.nft.nftId,
            nftIndex: inputs.nft.nftIndex,
            ref: 0,
            max: {
                width: 64,
                height: 64,
            },
            min: {
                width: 64,
                height: 64,
            },
        })}
                ></${toniq_entrepot_nft_display_element_1.EntrepotNftDisplayElement}>
            </div>
            <div class="other-contents">
                <slot></slot>
            </div>
        `;
    },
});
