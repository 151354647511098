"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fromHexString = exports.toHexString = void 0;
function toHexString(byteArray) {
    return Array.from(byteArray, (byte) => {
        return ('0' + (byte & 0xff).toString(16)).slice(-2);
    }).join('');
}
exports.toHexString = toHexString;
function fromHexString(hexString) {
    if (hexString.substr(0, 2) === '0x')
        hexString = hexString.substr(2);
    const bytes = [];
    for (let index = 0; index < hexString.length; index += 2)
        bytes.push(parseInt(hexString.substr(index, 2), 16));
    return bytes;
}
exports.fromHexString = fromHexString;
