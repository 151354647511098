"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.userTransactionsCache = void 0;
const common_1 = require("@augment-vir/common");
const user_nft_transaction_1 = require("../../../nft/user-nft-transaction");
const define_local_cache_1 = require("../../define-local-cache");
const nft_id_1 = require("../../../nft/nft-id");
const nft_mint_number_1 = require("../../../nft/nft-mint-number");
const lodash_1 = require("lodash");
const wrapped_canister_id_1 = require("../../../canisters/canister-details/wrapped-canister-id");
async function updateUserTransactions({ userAccount, userIdentity, }) {
    const userAccountAddress = userAccount.address;
    const userPrincipal = userIdentity.getPrincipal().toText();
    const rawPrincipalTransactions = await (await fetch(`https://api.nftgeek.app/api/1/toniq/principal/${userPrincipal}/transactions`)).json();
    const rawAccountIdentifierTransactions = await (await fetch(`https://api.nftgeek.app/api/1/toniq/accountIdentifier/${userAccountAddress}/transactions`)).json();
    const mergedTransactions = (0, lodash_1.merge)(rawAccountIdentifierTransactions, rawPrincipalTransactions);
    const rawTransactionsMapped = (0, lodash_1.chain)(Object.keys(mergedTransactions.transactions))
        .map(key => {
        return (0, lodash_1.map)(mergedTransactions.transactions[key], transaction => {
            return {
                canister: key,
                ...transaction,
            };
        });
    })
        .map(transactions => {
        return (0, lodash_1.values)(transactions);
    })
        .flattenDeep()
        .unionBy('timeMillis')
        .map(transaction => {
        return {
            buyer: transaction.buyerUniqueIdentifier
                ? transaction.buyerUniqueIdentifier.id
                : '',
            canister: transaction.canister,
            price: transaction.price,
            seller: transaction.sellerUniqueIdentifier
                ? transaction.sellerUniqueIdentifier.id
                : '',
            time: transaction.timeMillis * 1000000,
            token: (0, nft_id_1.encodeNftId)((0, wrapped_canister_id_1.getExtCanisterId)(transaction.canister), transaction.tokenId),
        };
    })
        .value();
    const transactions = await Promise.allSettled(rawTransactionsMapped.map(async (rawTransaction) => {
        const nftId = rawTransaction.token;
        if (!nftId) {
            return undefined;
        }
        const decodedNft = (0, nft_id_1.decodeNftId)(rawTransaction.token);
        const listing = {
            price: rawTransaction.price,
            lockedTimestamp: rawTransaction.time,
        };
        const direction = rawTransaction.buyer === userAccountAddress
            ? user_nft_transaction_1.TransactionDirection.Purchase
            : user_nft_transaction_1.TransactionDirection.Sale;
        return {
            collectionId: rawTransaction.canister,
            buyerAddress: rawTransaction.buyer,
            nftIndex: decodedNft.index,
            nftId,
            nftMintNumber: (0, nft_mint_number_1.getNftMintNumber)({
                collectionId: rawTransaction.canister,
                nftIndex: decodedNft.index,
            }),
            sellerAddress: rawTransaction.seller,
            transactionTimeMillisecond: rawTransaction.time / 1000000,
            transactionId: rawTransaction.id ? rawTransaction.id : '',
            listing,
            directionForCurrentUser: direction,
        };
    })).then(results => results.map(result => {
        if (result.status === 'fulfilled') {
            return result.value;
        }
        else {
            return undefined;
        }
    }));
    return transactions.filter(common_1.isTruthy);
}
exports.userTransactionsCache = (0, define_local_cache_1.defineAutomaticallyUpdatingCache)({
    cacheName: 'user-transactions',
    valueUpdater: updateUserTransactions,
    keyGenerator: ({ userAccount }) => userAccount.address,
    subKeyRequirement: define_local_cache_1.SubKeyRequirementEnum.Required,
});
