"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.entrepotRouter = exports.EntrepotRoutePageEnum = void 0;
const spa_router_vir_1 = require("spa-router-vir");
var EntrepotRoutePageEnum;
(function (EntrepotRoutePageEnum) {
    EntrepotRoutePageEnum["Home"] = "";
    EntrepotRoutePageEnum["Marketplace"] = "marketplace";
    EntrepotRoutePageEnum["Profile"] = "profile";
})(EntrepotRoutePageEnum = exports.EntrepotRoutePageEnum || (exports.EntrepotRoutePageEnum = {}));
// no route sanitization yet because we don't have all the routes enumerated yet
exports.entrepotRouter = (0, spa_router_vir_1.createSpaRouter)();
