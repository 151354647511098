"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.pokeBotsCanisterDetails = void 0;
const default_canister_details_1 = require("../default-canister-details");
exports.pokeBotsCanisterDetails = {
    collectionName: 'Poked Bots',
    canisterId: 'bzsui-sqaaa-aaaah-qce2a-cai',
    getNftLinkUrl({ originalCanisterId, nftId }) {
        return (0, default_canister_details_1.getDefaultNftImageData)({
            canisterId: originalCanisterId,
            nftId: nftId,
            fullSize: true,
        }).url;
    },
};
