"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.dripBangCanisterDetails = void 0;
const element_vir_1 = require("element-vir");
exports.dripBangCanisterDetails = {
    collectionName: 'DRIP BANG',
    canisterId: 'x4oqm-bqaaa-aaaam-qahaq-cai',
    getNftImageData({ fullSize, nftLinkUrl }) {
        if (fullSize) {
            return {
                url: nftLinkUrl,
                extraHtml: (0, element_vir_1.html) `
                    <style>
                        body {
                            background-color: black;
                        }
                    </style>
                `,
            };
        }
        else {
            return undefined;
        }
    },
};
