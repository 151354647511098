"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCachedWithUpdate = void 0;
const cache_database_1 = require("./cache-database");
const inProgressFetches = new Map();
function getInProgressFetch(databaseTableName, rowKey) {
    var _a;
    return (_a = inProgressFetches.get(databaseTableName)) === null || _a === void 0 ? void 0 : _a.get(rowKey);
}
async function getCachedWithUpdate({ databaseTableName, rowKey, fetchValueCallback, }) {
    var _a;
    const alreadyInProgress = getInProgressFetch(databaseTableName, rowKey);
    if (alreadyInProgress) {
        return alreadyInProgress;
    }
    let cached = undefined;
    try {
        cached = await (await (0, cache_database_1.getEntrepotCacheDatabase)())[databaseTableName].get(rowKey);
    }
    catch (error) {
        console.error(`Database error`);
    }
    if (cached) {
        // update later in the background
        setTimeout(async () => {
            await updateDatabase({ databaseTableName, rowKey, fetchValueCallback });
        }, 
        /**
         * Wait some seconds so fetching all this data to update the cache doesn't impede
         * initial loading times.
         */
        5000);
        return cached.data;
    }
    else {
        const tableMap = (_a = inProgressFetches.get(databaseTableName)) !== null && _a !== void 0 ? _a : (inProgressFetches.set(databaseTableName, new Map()) &&
            inProgressFetches.get(databaseTableName));
        const getDataPromise = updateDatabase({ databaseTableName, rowKey, fetchValueCallback });
        tableMap.set(rowKey, getDataPromise);
        return await getDataPromise;
    }
}
exports.getCachedWithUpdate = getCachedWithUpdate;
async function updateDatabase({ databaseTableName, rowKey, fetchValueCallback, }) {
    var _a;
    const data = await fetchValueCallback(rowKey);
    await (await (0, cache_database_1.getEntrepotCacheDatabase)())[databaseTableName].put({
        rowKey,
        // as cast here because the database table types are not sufficiently typed
        data: data,
    });
    (_a = inProgressFetches.get(databaseTableName)) === null || _a === void 0 ? void 0 : _a.delete(rowKey);
    return data;
}
