"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAllCollectionsWithCaching = void 0;
const canister_id_1 = require("../../models/canister-id");
const entrepot_data_api_1 = require("../../../api/entrepot-apis/entrepot-data-api");
const cache_cleanup_1 = require("./cache-cleanup");
const get_cached_with_update_1 = require("./get-cached-with-update");
const environment_by_url_1 = require("../../../environment/environment-by-url");
const common_1 = require("@augment-vir/common");
const collectionsEndpoint = environment_by_url_1.isProd
    ? (0, entrepot_data_api_1.createCloudFunctionsEndpointUrl)(['collections'])
    : (0, entrepot_data_api_1.createCloudFunctionsEndpointUrl)(['collectionsDev']);
async function getBaseCollections() {
    return await (0, get_cached_with_update_1.getCachedWithUpdate)({
        databaseTableName: 'allBaseCollectionsCache',
        rowKey: 'entrepot',
        fetchValueCallback: async () => {
            const response = await fetch(collectionsEndpoint);
            const baseCollectionArray = await response.json();
            /**
             * At the time of this writing, every collection passes this filter. However, the old
             * code makes this check so I'm propagating it to here as well.
             */
            return baseCollectionArray
                .map(collection => ({ ...collection, canister: collection.id }))
                .filter(collection => (0, canister_id_1.isCanisterId)(collection.id));
        },
    });
}
async function getAllCollectionsWithCaching() {
    try {
        const baseCollections = (await getBaseCollections()).filter(collection => !collection.dev);
        const collectionStats = await getCollectionsStats(baseCollections);
        const collectionsById = [...baseCollections].reduce((accum, currentCollection, collectionIndex) => {
            accum[currentCollection.id] = {
                ...currentCollection,
                stats: collectionStats[collectionIndex],
            };
            return accum;
        }, {});
        // don't await this, it'll execute in the background
        (0, cache_cleanup_1.removeUnknownKeys)('collectionsStatsCache', Object.keys(collectionsById));
        return collectionsById;
    }
    catch (error) {
        console.error(`failed: ${(0, common_1.extractErrorMessage)(error)}`);
        throw error;
    }
}
exports.getAllCollectionsWithCaching = getAllCollectionsWithCaching;
async function getCollectionsStats(baseCollections) {
    return await Promise.all(baseCollections.map(async (baseCollection) => {
        return await (0, get_cached_with_update_1.getCachedWithUpdate)({
            databaseTableName: 'collectionsStatsCache',
            rowKey: baseCollection.id,
            fetchValueCallback: async () => {
                if (baseCollection.market) {
                    try {
                        const stats = await entrepot_data_api_1.defaultEntrepotApi.token(baseCollection.id).stats();
                        return stats;
                    }
                    catch (error) {
                        return undefined;
                    }
                }
                else {
                    return undefined;
                }
            },
        });
    }));
}
