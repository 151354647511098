"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntrepotNftCardElement = void 0;
const element_vir_1 = require("element-vir");
const design_system_1 = require("@toniq-labs/design-system");
const toniq_entrepot_nft_display_element_1 = require("./toniq-entrepot-nft-display.element");
exports.EntrepotNftCardElement = (0, design_system_1.defineToniqElement)()({
    tagName: 'toniq-entrepot-nft-card',
    hostClasses: {
        blockInteraction: false,
    },
    styles: ({ hostClassSelectors, hostClassNames }) => (0, element_vir_1.css) `
        :host {
            width: 384;
            max-width: 100%;
            ${(0, design_system_1.applyBackgroundAndForeground)(design_system_1.toniqColors.pagePrimary)}
            color: ${design_system_1.toniqColors.pagePrimary.foregroundColor};
            border-radius: 16px;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            ${design_system_1.toniqShadows.popupShadow}
            transition: ${design_system_1.interactionDuration};
            padding: 16px;
            cursor: pointer;
            border: 1px solid transparent;
        }

        :host(:hover) {
            border-color: ${design_system_1.toniqColors.pageInteraction.foregroundColor};
        }

        ${hostClassSelectors.blockInteraction} {
            cursor: auto;
        }

        :host(.${hostClassNames.blockInteraction}:hover) {
            border-color: transparent;
        }

        .image-wrapper {
            position: relative;
        }

        .image-overlay {
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
        }
    `,
    renderCallback: ({ inputs }) => {
        return (0, element_vir_1.html) `
            <div class="image-wrapper">
                <${toniq_entrepot_nft_display_element_1.EntrepotNftDisplayElement}
                    ${(0, element_vir_1.assign)(toniq_entrepot_nft_display_element_1.EntrepotNftDisplayElement, {
            collectionId: inputs.nft.collectionId,
            fullSize: false,
            cachePriority: 0,
            nftId: inputs.nft.nftId,
            nftIndex: inputs.nft.nftIndex,
            ref: 0,
        })}
                ></${toniq_entrepot_nft_display_element_1.EntrepotNftDisplayElement}>
                <div class="image-overlay">
                    <slot name="image-overlay"></slot>
                </div>
            </div>
            <div class="footer-contents">
                <slot></slot>
            </div>
        `;
    },
});
