"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntrepotCreate = exports.EntrepotCreatePageElement = void 0;
const wrap_native_element_1 = require("@toniq-labs/design-system/dist/esm/elements/wrap-native-element");
const element_vir_1 = require("element-vir");
const design_system_1 = require("@toniq-labs/design-system");
const toniq_entrepot_page_header_element_1 = require("../../common/toniq-entrepot-page-header.element");
const toniq_entrepot_create_card_element_1 = require("./toniq-entrepot-create-card.element");
const createCards = [
    {
        icon: design_system_1.Rocket64Icon,
        title: 'Launchpad',
        bullets: [
            'No-code',
            'Highly customizable launch page',
            'Canister control upon request',
            'No external application integration',
            'Open Beta',
        ],
        buttons: [
            {
                primary: false,
                text: 'Access Application',
                link: 'https://jfosu-pqaaa-aaaal-qcifq-cai.raw.icp0.io/',
            },
            {
                primary: true,
                text: 'User Documentation',
                link: 'https://toniq-labs.gitbook.io/toniq-mint/',
            },
        ],
    },
    {
        icon: design_system_1.CircleDashes64Icon,
        title: 'Token Standard',
        subtitle: '(EXT)',
        bullets: [
            'Code',
            'Does not support Toniq launch page',
            'Deploy & control own canister',
            'Open Source',
        ],
        buttons: [
            {
                primary: false,
                text: 'Github',
                link: 'https://github.com/Toniq-Labs/ext-v2-token',
            },
            {
                primary: true,
                text: 'Add External Canister (Coming Soon)',
            },
        ],
    },
];
exports.EntrepotCreatePageElement = (0, design_system_1.defineToniqElementNoInputs)({
    tagName: 'toniq-entrepot-create-page',
    styles: (0, element_vir_1.css) `
        :host {
            display: flex;
            flex-direction: column;
            min-height: 100vh;
        }

        ${toniq_entrepot_create_card_element_1.EntrepotCreateCardElement} {
            flex-basis: 0;
            flex-grow: 1;
            max-width: 444px;
            min-width: 320px;
        }

        .cards-wrapper {
            display: flex;
            justify-content: center;
        }

        .cards {
            display: flex;
            gap: 16px;
            flex-grow: 1;
            max-width: 1600px;
            flex-wrap: wrap;
            justify-content: center;
            margin: 32px 0;
        }

        p {
            margin: 32px 32px 56px;
            ${design_system_1.toniqFontStyles.paragraphFont}
        }

        @media (max-width: 1200px) {
            :host {
                padding: 16px;
            }
        }
    `,
    renderCallback: () => {
        return (0, element_vir_1.html) `
            <${toniq_entrepot_page_header_element_1.EntrepotPageHeaderElement}
                ${(0, element_vir_1.assign)(toniq_entrepot_page_header_element_1.EntrepotPageHeaderElement, {
            headerText: 'Toniq Create',
        })}
            ></${toniq_entrepot_page_header_element_1.EntrepotPageHeaderElement}>
            <p>
                We offer a variety of tools for developers and non-developers to help build, launch
                and grow your business with NFTs.
            </p>
            <div class="cards-wrapper">
                <div class="cards">
                    ${createCards.map(createCard => {
            return (0, element_vir_1.html) `
                        <${toniq_entrepot_create_card_element_1.EntrepotCreateCardElement}
                            ${(0, element_vir_1.assign)(toniq_entrepot_create_card_element_1.EntrepotCreateCardElement, { ...createCard })}
                        ></${toniq_entrepot_create_card_element_1.EntrepotCreateCardElement}>
                    `;
        })}
                </div>
            </div>
        `;
    },
});
exports.EntrepotCreate = (0, wrap_native_element_1.wrapInReactComponent)(exports.EntrepotCreatePageElement);
