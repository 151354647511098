"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getExtNftId = exports.encodeNftId = exports.decodeNftId = void 0;
const principal_1 = require("@dfinity/principal");
const string_1 = require("../../augments/string");
const bits_1 = require("../../augments/bits");
const wrapped_canister_id_1 = require("../canisters/canister-details/wrapped-canister-id");
function decodeNftId(nftId) {
    const p = [...principal_1.Principal.fromText(nftId).toUint8Array()];
    const padding = p.splice(0, 4);
    if ((0, string_1.toHexString)(padding) !== (0, string_1.toHexString)(Buffer.from('\x0Atid'))) {
        return {
            index: 0,
            canister: nftId,
            token: encodeNftId(nftId, 0),
        };
    }
    else {
        return {
            index: (0, bits_1.from32bits)(p.splice(-4)),
            canister: principal_1.Principal.fromUint8Array(p).toText(),
            token: nftId,
        };
    }
}
exports.decodeNftId = decodeNftId;
function encodeNftId(principal, index) {
    const padding = Buffer.from('\x0Atid');
    const array = new Uint8Array([
        ...padding,
        ...principal_1.Principal.fromText(principal).toUint8Array(),
        ...(0, bits_1.to32bitArray)(index),
    ]);
    return principal_1.Principal.fromUint8Array(array).toText();
}
exports.encodeNftId = encodeNftId;
function getExtNftId(nftId) {
    const { index, canister } = decodeNftId(nftId);
    return encodeNftId((0, wrapped_canister_id_1.getExtCanisterId)(canister), index);
}
exports.getExtNftId = getExtNftId;
