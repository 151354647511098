"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.dfinityDeckOriginsCanisterDetails = void 0;
const entrepot_images_url_1 = require("../../../../api/entrepot-apis/entrepot-images-url");
exports.dfinityDeckOriginsCanisterDetails = {
    collectionName: 'DfinityDeck:ORIGINS',
    canisterId: '7i54s-nyaaa-aaaal-abomq-cai',
    getNftImageData({ originalCanisterId, nftId, priority }) {
        return {
            url: (0, entrepot_images_url_1.formEntrepotImagesUrl)({
                entrepotImagesCanisterId: originalCanisterId,
                nftId,
                cachePriority: priority,
                useCacheBuster: true,
            }),
        };
    },
};
