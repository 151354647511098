"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntrepotTestPage = void 0;
const design_system_1 = require("@toniq-labs/design-system");
const wrap_native_element_1 = require("@toniq-labs/design-system/dist/esm/elements/wrap-native-element");
const element_vir_1 = require("element-vir");
const toniq_entrepot_flip_card_element_1 = require("../../common/toniq-entrepot-flip-card.element");
const toniq_entrepot_social_link_element_1 = require("../../common/toniq-entrepot-social-link.element");
const toniq_entrepot_home_page_element_1 = require("../home-page/toniq-entrepot-home-page.element");
const ext_1 = require("../../../../api/ext");
const nft_id_1 = require("../../../../data/nft/nft-id");
const toniq_entrepot_home_page_banner_canister_1 = require("./toniq-entrepot-home-page-banner-canister");
const toniq_entrepot_home_page_featured_canister_1 = require("./toniq-entrepot-home-page-featured-canister");
function formatTopCollection(collections, canisters) {
    return canisters.map((canister, collectionIndex) => {
        const collectionMatch = collections.find((collection) => collection.canister === canister.canisterId);
        return {
            collectionName: collectionMatch ? collectionMatch.name : '',
            floorPrice: Number(canister.floorPriceE8s) / 100000000,
            volume: Number(canister.volumeE8s) / 100000000,
            index: collectionIndex + 1,
            route: collectionMatch ? collectionMatch.route : '',
            id: canister.canisterId,
        };
    });
}
async function fetchLast24hData(collections) {
    const data = await fetch('https://api.nftgeek.app/api/1/toniq/top/volume/last24h').then(r => r.json());
    return formatTopCollection(collections, data.canisters);
}
async function fetchAllTimeData(collections) {
    const data = await fetch('https://api.nftgeek.app/api/1/toniq/top/volume/allTime').then(r => r.json());
    return formatTopCollection(collections, data.canisters);
}
function setIntervalImmediately(func, interval) {
    func();
    return setInterval(func, interval);
}
const EntrepotTestElement = (0, design_system_1.defineToniqElement)()({
    tagName: 'toniq-entrepot-test-page',
    styles: (0, element_vir_1.css) `
        :host {
            max-width: 100%;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
        }

        h2 {
            ${design_system_1.toniqFontStyles.h2Font};
        }

        ${toniq_entrepot_flip_card_element_1.EntrepotFlipCardElement} {
            height: 200px;
            width: 200px;
        }

        p {
            margin: 0;
            padding: 0;
        }
    `,
    stateInit: {
        carouselItems: [],
        featuredCollections: [],
        topCollections: {},
    },
    initCallback: async ({ inputs, updateState }) => {
        const carouselItems = inputs.collections
            .filter(collection => !collection.dev)
            .filter(collection => {
            return toniq_entrepot_home_page_banner_canister_1.bannerCanisters.includes(collection.canister);
        })
            .slice(0, 26)
            .map(collection => {
            var _a;
            const tokenid = (0, ext_1.encodeNftId)(collection.canister, Math.floor(Math.random() * (((_a = collection.stats) === null || _a === void 0 ? void 0 : _a.listings) - 0) + 0));
            const { index, canister } = (0, nft_id_1.decodeNftId)(tokenid);
            const nftRoute = (0, nft_id_1.getExtNftId)(tokenid);
            return {
                ...collection,
                nftRoute,
                collectionId: canister,
                fullSize: false,
                cachePriority: 0,
                nftId: tokenid,
                nftIndex: index,
                ref: 0,
                min: { width: 360, height: 360 },
                max: { width: 360, height: 360 },
            };
        });
        updateState({ carouselItems });
        const featuredCollections = inputs.collections
            .filter(collection => !collection.dev)
            .filter(collection => {
            return toniq_entrepot_home_page_featured_canister_1.featuredCanisters.includes(collection.canister);
        })
            .slice(0, 4)
            .map(collection => {
            return {
                collectionName: collection.name,
                nfts: Array(10)
                    .fill(0)
                    .map(() => {
                    var _a;
                    const tokenid = (0, ext_1.encodeNftId)(collection.canister, Math.floor(Math.random() * (((_a = collection.stats) === null || _a === void 0 ? void 0 : _a.listings) - 0) + 0));
                    const { index, canister } = (0, nft_id_1.decodeNftId)(tokenid);
                    return {
                        collectionId: canister,
                        nftId: tokenid,
                        nftIndex: index,
                        fullSize: true,
                        cachePriority: 0,
                        ref: 0,
                    };
                }),
                longDescription: collection.blurb,
                collectionRoute: collection.route,
                socialLinks: [
                    {
                        link: collection.discord,
                        type: toniq_entrepot_social_link_element_1.SocialLinkTypeEnum.Discord,
                    },
                    {
                        link: collection.twitter,
                        type: toniq_entrepot_social_link_element_1.SocialLinkTypeEnum.Twitter,
                    },
                    {
                        link: collection.telegram,
                        type: toniq_entrepot_social_link_element_1.SocialLinkTypeEnum.Telegram,
                    },
                ].filter(social => social.link !== ''),
            };
        });
        updateState({ featuredCollections });
        setIntervalImmediately(async () => {
            const topCollections = {
                past24Hours: await fetchLast24hData(inputs.collections),
                allTime: await fetchAllTimeData(inputs.collections),
            };
            updateState({ topCollections });
        }, 60000);
    },
    events: {
        collectionRouteClicked: (0, element_vir_1.defineElementEvent)(),
    },
    renderCallback: ({ state, events }) => {
        const homepageInputs = {
            carouselItems: state.carouselItems,
            featuredCollections: state.featuredCollections,
            topCollections: state.topCollections,
        };
        return (0, element_vir_1.html) `
            <${toniq_entrepot_home_page_element_1.EntrepotHomePageElement}
                ${(0, element_vir_1.assign)(toniq_entrepot_home_page_element_1.EntrepotHomePageElement, homepageInputs)}
                ${(0, element_vir_1.listen)(toniq_entrepot_home_page_element_1.EntrepotHomePageElement.events.collectionRouteClicked, event => {
            new events.collectionRouteClicked(event.detail);
        })}
            ></${toniq_entrepot_home_page_element_1.EntrepotHomePageElement}>
            
        `;
    },
});
exports.EntrepotTestPage = (0, wrap_native_element_1.wrapInReactComponent)(EntrepotTestElement);
