"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntrepotCarouselElement = void 0;
const scroll_snap_api_1 = require("scroll-snap-api");
const class_map_js_1 = require("lit/directives/class-map.js");
const design_system_1 = require("@toniq-labs/design-system");
const element_vir_1 = require("element-vir");
const function_1 = require("../../../augments/function");
const toniq_entrepot_nft_display_element_1 = require("./toniq-entrepot-nft-display.element");
const repeat_js_1 = require("lit/directives/repeat.js");
const carouselImageWidth = 340;
exports.EntrepotCarouselElement = (0, design_system_1.defineToniqElement)()({
    tagName: 'toniq-entrepot-carousel',
    stateInit: {
        scrollPosition: {
            left: 0,
            right: Infinity,
        },
        scrollSnapPositions: [],
        scrollThrottle: false,
        rotationIntervalId: undefined,
        enableRotation: true,
    },
    styles: (0, element_vir_1.css) `
        :host {
            display: block;
            position: relative;
            margin: 32px 0;
            max-height: 375px;
            overflow-y: hidden;
        }

        .carousel-image-wrapper {
            height: 340px;
            width: ${carouselImageWidth}px;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            scroll-snap-align: center;
            background: #ffffff;
            border: 1px solid rgba(0, 0, 0, 0.04);
            box-shadow: 0px 16px 16px rgba(39, 170, 243, 0.16);
            border-radius: 16px;
            overflow: hidden;
        }

        img {
            border-radius: 16px;
            width: 100%;
            margin: auto;
        }

        .images-container {
            position: relative;
            display: flex;
            gap: 24px;
            overflow-x: scroll;
            scroll-snap-type: x mandatory;
            z-index: 9;
            padding: 4px 0 100px;
        }

        .arrow {
            --background-degrees: 90deg;
            position: sticky;
            width: 200px;
            max-width: 20%;
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            z-index: 200;
            background: linear-gradient(
                var(--background-degrees),
                white 0%,
                rgba(255, 255, 255, 0.6) 30%,
                rgba(255, 255, 255, 0) 100%
            );
        }

        .arrow.right {
            justify-content: flex-end;
            --background-degrees: -90deg;
            left: unset;
            right: 0;
        }

        .arrow.right::after {
            left: unset;
            right: 0;
        }

        .arrow ${design_system_1.ToniqIcon} {
            transition: 300ms;
            cursor: pointer;
            position: relative;
            z-index: 11;
            margin: 40px;
            box-shadow: 0px 2px 28px rgba(0, 0, 0, 0.12);
            display: inline-flex;
            padding: 8px;
            border-radius: 50%;
            ${(0, design_system_1.applyBackgroundAndForeground)(design_system_1.toniqColors.pagePrimary)};
        }

        .hidden {
            opacity: 0;
            pointer-events: none;
        }

        @media (max-width: 600px) {
            .arrow ${design_system_1.ToniqIcon} {
                margin: 12px;
            }
        }
    `,
    cleanupCallback: ({ state }) => {
        window.clearInterval(state.rotationIntervalId);
    },
    renderCallback: ({ inputs, state, updateState, host }) => {
        if (inputs.automaticRotation && state.rotationIntervalId == undefined) {
            updateState({
                rotationIntervalId: window.setInterval(() => {
                    rotateCarousel({
                        host,
                        direction: 'right',
                        allowWrapping: true,
                        blockIfHovering: true,
                    });
                }, 4000),
            });
        }
        else if (!inputs.automaticRotation && state.rotationIntervalId != undefined) {
            window.clearInterval(state.rotationIntervalId);
            updateState({
                rotationIntervalId: undefined,
            });
        }
        const leftArrowHideZone = getMidSnapPosition(state.scrollSnapPositions, 0);
        const rightArrowHideZone = getMidSnapPosition(state.scrollSnapPositions, -1);
        return (0, element_vir_1.html) `
            <div
                ${(0, element_vir_1.onResize)(() => {
            updateState({
                scrollSnapPositions: (0, scroll_snap_api_1.getScrollSnapPositions)(getScrollContainer(host)).x,
            });
        })}
                class="images-container"
                ${(0, element_vir_1.listen)('scroll', event => {
            throttledUpdateScrollPosition(event.target, updateState);
        })}
            >
                <div class="arrow left">
                    <${design_system_1.ToniqIcon}
                        class=${(0, class_map_js_1.classMap)({
            hidden: state.scrollPosition.left <= (leftArrowHideZone !== null && leftArrowHideZone !== void 0 ? leftArrowHideZone : 100),
        })}
                        ${(0, element_vir_1.assign)(design_system_1.ToniqIcon, {
            icon: design_system_1.ArrowLeft24Icon,
        })}
                        ${(0, element_vir_1.listen)('click', () => {
            rotateCarousel({
                allowWrapping: false,
                blockIfHovering: false,
                direction: 'left',
                host,
            });
        })}
                    ></${design_system_1.ToniqIcon}>
                </div>
                ${(0, repeat_js_1.repeat)(inputs.items, item => item.nftId, item => {
            const { collectionId, fullSize, cachePriority, nftId, nftIndex, ref, min, max, } = item;
            return (0, element_vir_1.html) `
                        <a href="${`marketplace/asset/${item.nftRoute}`}">
                            <div class="carousel-image-wrapper">
                                <${toniq_entrepot_nft_display_element_1.EntrepotNftDisplayElement}
                                    ${(0, element_vir_1.assign)(toniq_entrepot_nft_display_element_1.EntrepotNftDisplayElement, {
                collectionId,
                fullSize,
                cachePriority,
                nftId,
                nftIndex,
                ref,
                min,
                max,
            })}
                                ></${toniq_entrepot_nft_display_element_1.EntrepotNftDisplayElement}>
                            </div>
                        </a>
                    `;
        })}
                <div class="arrow right">
                    <${design_system_1.ToniqIcon}
                        class=${(0, class_map_js_1.classMap)({
            hidden: rightArrowHideZone == undefined
                ? state.scrollPosition.right <= 100
                : state.scrollPosition.left >= rightArrowHideZone,
        })}
                        ${(0, element_vir_1.assign)(design_system_1.ToniqIcon, {
            icon: design_system_1.ArrowRight24Icon,
        })}
                        ${(0, element_vir_1.listen)('click', () => {
            rotateCarousel({
                allowWrapping: false,
                blockIfHovering: false,
                direction: 'right',
                host,
            });
        })}
                    ></${design_system_1.ToniqIcon}>
                </div>
            </div>
        `;
    },
});
function getMidSnapPosition(positions, positionToRead) {
    const increment = positionToRead >= 0 ? 1 : -1;
    const indexToRead = positionToRead < 0 ? positions.length + positionToRead : positionToRead;
    const nextIndex = indexToRead + increment;
    const start = positions[indexToRead];
    const end = positions[nextIndex];
    if (start == undefined || end == undefined) {
        return undefined;
    }
    return (start + end) / 2 + increment * 20;
}
function getScrollContainer(host) {
    const scrollContainer = host.shadowRoot.querySelector('.images-container');
    if (!(scrollContainer instanceof HTMLElement)) {
        throw new Error(`Failed to find scroll container.`);
    }
    return scrollContainer;
}
function rotateCarousel({ host, direction, allowWrapping, blockIfHovering, }) {
    var _a, _b;
    // don't rotate if the user is mousing over the carousel
    if (blockIfHovering && host.matches(':hover')) {
        return;
    }
    const scrollContainer = getScrollContainer(host);
    const containerWidth = scrollContainer.clientWidth;
    const snapPositions = (0, scroll_snap_api_1.getScrollSnapPositions)(getScrollContainer(host)).x;
    const firstScrollPosition = (_a = snapPositions[0]) !== null && _a !== void 0 ? _a : 0;
    const lastScrollPosition = (_b = snapPositions.slice(-1).pop()) !== null && _b !== void 0 ? _b : containerWidth;
    if (direction === 'right' &&
        scrollContainer.scrollLeft >=
            lastScrollPosition -
                // small buffer
                10) {
        if (allowWrapping) {
            scrollContainer.scrollTo({
                behavior: 'smooth',
                left: firstScrollPosition,
            });
        }
    }
    else if (direction === 'left' &&
        scrollContainer.scrollLeft <=
            firstScrollPosition +
                // small buffer
                10) {
        if (allowWrapping) {
            scrollContainer.scrollTo({
                behavior: 'smooth',
                left: lastScrollPosition,
            });
        }
    }
    else {
        const currentPosition = getSnapPositionClosestTo(scrollContainer.scrollLeft, snapPositions);
        const directionFactor = direction === 'right' ? 1 : -1;
        const nextPosition = getSnapPositionClosestTo((currentPosition || 0) +
            (scrollContainer.clientWidth - carouselImageWidth) * directionFactor, snapPositions);
        if (direction === 'right' && nextPosition > currentPosition) {
            scrollContainer.scrollTo({ behavior: 'smooth', left: nextPosition });
        }
        else if (direction === 'left' && nextPosition < currentPosition) {
            scrollContainer.scrollTo({ behavior: 'smooth', left: nextPosition });
        }
        else {
            (0, scroll_snap_api_1.scrollSnapToNext)(scrollContainer, direction);
        }
    }
}
function getSnapPositionClosestTo(closestToThis, snapPositions) {
    const closestPosition = snapPositions.reduce((closest, position) => {
        const positionDistance = Math.abs(closestToThis - position);
        const closestDistance = Math.abs(closestToThis - closest);
        if (positionDistance < closestDistance) {
            return position;
        }
        else {
            return closest;
        }
    }, Infinity);
    return closestPosition;
}
const throttledUpdateScrollPosition = (0, function_1.throttle)(250, (element, updateState) => {
    updateState({
        scrollPosition: {
            left: element.scrollLeft,
            right: element.scrollWidth - element.scrollLeft - element.clientWidth,
        },
        scrollThrottle: false,
    });
});
