"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createProfileNftFilterInputs = void 0;
const element_vir_1 = require("element-vir");
const toniq_entrepot_with_filters_element_1 = require("../../../../common/with-filters/toniq-entrepot-with-filters.element");
const profile_page_state_1 = require("../profile-page-state");
const create_base_profile_filters_1 = require("../create-base-profile-filters");
const design_system_1 = require("@toniq-labs/design-system");
const toniq_entrepot_profile_nft_card_element_1 = require("../../profile-nft-card-element/toniq-entrepot-profile-nft-card.element");
const create_right_column_template_1 = require("../../profile-page-nfts/create-right-column-template");
const toniq_entrepot_profile_nft_list_item_element_1 = require("../../profile-nft-card-element/toniq-entrepot-profile-nft-list-item.element");
function createProfileNftFilterInputs({ showFilters, allSorts, allFilters, isRenderReady, entries, currentProfileTab, sellCallback, transferCallback, userAccount, nftClickCallback, viewStyle, }) {
    return (0, toniq_entrepot_with_filters_element_1.createWithFiltersInputs)({
        ...(0, create_base_profile_filters_1.createBaseProfileWithFiltersInputs)({
            isRenderReady,
            showFilters,
            allFilters,
            currentProfileTab,
            allSorts,
        }),
        allEntries: entries,
        createEntryTemplateCallback: (entry) => {
            if (!isRenderReady) {
                return (0, element_vir_1.html) `
                    <${design_system_1.ToniqIcon}
                        ${(0, element_vir_1.assign)(design_system_1.ToniqIcon, {
                    icon: design_system_1.LoaderAnimated24Icon,
                })}
                    ></${design_system_1.ToniqIcon}>
                `;
            }
            const rightSideTemplate = createRightSideTemplate({
                sellCallback: () => {
                    sellCallback(entry);
                },
                transferCallback: () => {
                    transferCallback(entry);
                },
                currentProfileTab,
                offers: entry.offers,
                viewStyle,
                userOwns: (userAccount === null || userAccount === void 0 ? void 0 : userAccount.address) != undefined &&
                    (userAccount === null || userAccount === void 0 ? void 0 : userAccount.address) === entry.ownerAddress,
            });
            if (viewStyle === profile_page_state_1.ProfileViewStyleEnum.Grid) {
                return (0, element_vir_1.html) `
                    <${toniq_entrepot_profile_nft_card_element_1.EntrepotProfileNftCardElement}
                        ${(0, element_vir_1.assign)(toniq_entrepot_profile_nft_card_element_1.EntrepotProfileNftCardElement, {
                    nft: entry,
                })}
                        ${(0, element_vir_1.listen)('click', () => {
                    nftClickCallback(entry);
                })}
                    >
                        ${rightSideTemplate}
                    </${toniq_entrepot_profile_nft_card_element_1.EntrepotProfileNftCardElement}>
                `;
            }
            else {
                return (0, element_vir_1.html) `
                    <${toniq_entrepot_profile_nft_list_item_element_1.EntrepotProfileNftListItemElement}
                        ${(0, element_vir_1.assign)(toniq_entrepot_profile_nft_list_item_element_1.EntrepotProfileNftListItemElement, {
                    nft: entry,
                })}
                        ${(0, element_vir_1.listen)('click', () => {
                    nftClickCallback(entry);
                })}
                    >
                        ${rightSideTemplate}
                    </${toniq_entrepot_profile_nft_list_item_element_1.EntrepotProfileNftListItemElement}>
                `;
            }
        },
    });
}
exports.createProfileNftFilterInputs = createProfileNftFilterInputs;
function createRightSideTemplate({ sellCallback, transferCallback, currentProfileTab, offers, userOwns, viewStyle, }) {
    if (currentProfileTab.value === 'my-nfts') {
        return (0, element_vir_1.html) `
        <style>
            .buttons-row {
                display: flex;
                gap: 16px;
            }
        </style>
        <div class="buttons-row">
            <${design_system_1.ToniqButton}
                class=${design_system_1.ToniqButton.hostClasses.secondary}
                ${(0, element_vir_1.assign)(design_system_1.ToniqButton, {
            text: 'Sell',
        })}
                ${(0, element_vir_1.listen)('click', event => {
            event.preventDefault();
            event.stopPropagation();
            sellCallback();
        })}
            ></${design_system_1.ToniqButton}>
            <${design_system_1.ToniqButton}
                ${(0, element_vir_1.assign)(design_system_1.ToniqButton, {
            text: 'Transfer',
        })}
                ${(0, element_vir_1.listen)('click', event => {
            event.preventDefault();
            event.stopPropagation();
            transferCallback();
        })}
            ></${design_system_1.ToniqButton}>
        </div>
    `;
    }
    else {
        const plural = offers.length === 1 ? '' : 's';
        const received = userOwns ? ' Received' : '';
        const offersDisplay = `${offers.length} Offer${plural}${received}`;
        const offersIncludeCurrentUser = false;
        const includingYoursDisplay = offersIncludeCurrentUser ? 'including yours' : '';
        return (0, create_right_column_template_1.createRightSideTextTemplate)({
            topString: offersDisplay,
            bottomString: includingYoursDisplay,
            useNbsp: viewStyle !== profile_page_state_1.ProfileViewStyleEnum.List,
        });
    }
}
