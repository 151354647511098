"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.from32bits = exports.to32bitArray = void 0;
function to32bitArray(input) {
    let b = new ArrayBuffer(4);
    new DataView(b).setUint32(0, input);
    return Array.from(new Uint8Array(b));
}
exports.to32bitArray = to32bitArray;
function from32bits(ba) {
    let value;
    for (let index = 0; index < 4; index++) {
        value = (value << 8) | ba[index];
    }
    return value;
}
exports.from32bits = from32bits;
