"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.filterSortKeyByTab = exports.sortDefinitions = exports.defaultProfileSort = exports.defaultProfileFilters = void 0;
const common_1 = require("@augment-vir/common");
const profile_nft_filters_1 = require("./profile-page-nft-filters/profile-nft-filters");
const profile_transaction_filters_1 = require("./profile-page-transaction-filters/profile-transaction-filters");
const profile_offer_filters_1 = require("./profile-page-offer-filters/profile-offer-filters");
exports.defaultProfileFilters = (0, common_1.wrapNarrowTypeWithTypeCheck)()({
    base: profile_nft_filters_1.defaultProfileNftFilters,
    activity: profile_transaction_filters_1.defaultProfileUserTransactionFilters,
    earn: profile_nft_filters_1.defaultProfileNftFilters,
    offers: profile_offer_filters_1.defaultProfileOfferFilters,
});
exports.defaultProfileSort = {
    activity: {
        ascending: false,
        name: profile_transaction_filters_1.profileUserTransactionSortDefinitions[0].sortName,
    },
    earn: {
        ascending: false,
        name: profile_nft_filters_1.profileNftSortDefinitions[0].sortName,
    },
    base: {
        ascending: false,
        name: profile_nft_filters_1.profileNftSortDefinitions[0].sortName,
    },
    offers: {
        ascending: false,
        name: profile_offer_filters_1.profileOfferSortDefinitions[0].sortName,
    },
};
exports.sortDefinitions = {
    activity: profile_transaction_filters_1.profileUserTransactionSortDefinitions,
    earn: profile_nft_filters_1.profileNftSortDefinitions,
    base: profile_nft_filters_1.profileNftSortDefinitions,
    offers: profile_offer_filters_1.profileOfferSortDefinitions,
};
exports.filterSortKeyByTab = {
    'my-nfts': 'base',
    favorites: 'base',
    offers: 'offers',
    activity: 'activity',
    earn: 'activity',
};
