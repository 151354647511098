"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.icPuppiesCanisterDetails = void 0;
const entrepot_images_url_1 = require("../../../../api/entrepot-apis/entrepot-images-url");
exports.icPuppiesCanisterDetails = {
    collectionName: 'ICPuppies',
    canisterId: '5movr-diaaa-aaaak-aaftq-cai',
    getNftImageData({ originalCanisterId, ref, nftId }) {
        return {
            url: (0, entrepot_images_url_1.formEntrepotTncImagesUrl)({
                canisterId: originalCanisterId,
                nftId,
                ref,
            }),
        };
    },
};
