"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.icKittiesCanisterDetails = void 0;
const entrepot_images_url_1 = require("../../../../api/entrepot-apis/entrepot-images-url");
const element_vir_1 = require("element-vir");
const default_canister_details_1 = require("../default-canister-details");
exports.icKittiesCanisterDetails = {
    collectionName: 'ICKitties',
    canisterId: 'rw7qm-eiaaa-aaaak-aaiqq-cai',
    getNftLinkUrl({ originalCanisterId, nftId }) {
        return (0, default_canister_details_1.getDefaultNftImageData)({
            canisterId: originalCanisterId,
            nftId: nftId,
            fullSize: true,
        }).url;
    },
    getNftImageData({ originalCanisterId, ref, nftId }) {
        return {
            url: (0, entrepot_images_url_1.formEntrepotTncImagesUrl)({
                canisterId: originalCanisterId,
                nftId,
                ref,
            }),
            extraHtml: (0, element_vir_1.html) `
                <script>
                    const svgElement = document.querySelector('svg');
                    svgElement.setAttribute(
                        'viewBox',
                        svgElement.getAttribute('viewBox').replace(/1024/g, '1000'),
                    );
                </script>
            `,
        };
    },
};
