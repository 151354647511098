"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getEntrepotCanister = exports.entrepotCanisters = void 0;
const entrepot_data_api_1 = require("./entrepot-data-api");
const treasure_canister_1 = require("../../data/canisters/treasure-canister");
exports.entrepotCanisters = getEntrepotCanister();
function getEntrepotCanister({ userIdentity } = {}) {
    const entrepotApi = userIdentity
        ? (0, entrepot_data_api_1.createEntrepotApiWithIdentity)(userIdentity)
        : entrepot_data_api_1.defaultEntrepotApi;
    return {
        /**
         * Idl JS file: src/ic/candid/volt-offers.did.js
         *
         * You may see another canister id floating around for offers
         * ("6z5wo-yqaaa-aaaah-qcsfa-cai"), but that's the old way. This canister is the new way.
         */
        nftOffers: entrepotApi.canister('fcwhh-piaaa-aaaak-qazba-cai'),
        nftAuctions: entrepotApi.canister('ffxbt-cqaaa-aaaak-qazbq-cai'),
        toniqEarn: entrepotApi.canister(treasure_canister_1.treasureCanisterId),
        launch: entrepotApi.canister('uczwa-vyaaa-aaaam-abdba-cai', 'launch'),
        favorites: entrepotApi.canister('6z5wo-yqaaa-aaaah-qcsfa-cai'),
        collection: (collectionId) => {
            return entrepotApi.canister(collectionId);
        },
    };
}
exports.getEntrepotCanister = getEntrepotCanister;
