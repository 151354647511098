"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createQueryParamString = void 0;
const common_1 = require("@augment-vir/common");
function createQueryParamString(queryRecord, valuesWithoutKeys) {
    var _a;
    const filteredRecord = (0, common_1.filterObject)(queryRecord, (key, value) => {
        return !!key && value != undefined;
    });
    const entriesWithoutKeys = ((_a = valuesWithoutKeys === null || valuesWithoutKeys === void 0 ? void 0 : valuesWithoutKeys.filter(value => value != undefined)) !== null && _a !== void 0 ? _a : []).map((valueWithoutKey) => [
        '',
        valueWithoutKey,
    ]);
    const allParams = [
        ...Object.entries(filteredRecord),
        ...entriesWithoutKeys,
    ];
    const urlSearchParamsInstance = new URLSearchParams(allParams);
    const searchParamsString = urlSearchParamsInstance.toString().replace(/&=/g, '&');
    if (searchParamsString) {
        return `?${searchParamsString}`.replace(/\?=/, '?');
    }
    else {
        return '';
    }
}
exports.createQueryParamString = createQueryParamString;
