"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.userFavoritesCache = exports.makeUserFavoritesKey = void 0;
const define_local_cache_1 = require("../../define-local-cache");
const fetch_raw_nft_listing_and_offers_1 = require("../fetch-raw-nft-listing-and-offers");
const base_nft_1 = require("../../../nft/base-nft");
const entrepot_canisters_1 = require("../../../../api/entrepot-apis/entrepot-canisters");
function makeUserFavoritesKey({ userIdentity }) {
    return userIdentity.getPrincipal().toText();
}
exports.makeUserFavoritesKey = makeUserFavoritesKey;
async function updateUserFavorites({ userIdentity }) {
    const favoriteNftIds = await (0, entrepot_canisters_1.getEntrepotCanister)({ userIdentity }).favorites.liked();
    const userOfferedNfts = Promise.all(favoriteNftIds.map(async (favoriteNftId, index) => {
        const rawNftListingAndOffers = await (0, fetch_raw_nft_listing_and_offers_1.fetchRawNftListingAndOffers)(index + 1, favoriteNftId);
        return (0, base_nft_1.parseRawNftData)(rawNftListingAndOffers);
    }));
    return userOfferedNfts;
}
exports.userFavoritesCache = (0, define_local_cache_1.defineAutomaticallyUpdatingCache)({
    cacheName: 'user-favorites',
    valueUpdater: updateUserFavorites,
    keyGenerator: makeUserFavoritesKey,
    subKeyRequirement: define_local_cache_1.SubKeyRequirementEnum.Required,
});
