"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createRightSideTextTemplate = void 0;
const element_vir_1 = require("element-vir");
const design_system_1 = require("@toniq-labs/design-system");
function createRightSideTextTemplate({ topString, bottomString, useNbsp, }) {
    const empty = useNbsp
        ? (0, element_vir_1.html) `
              &nbsp;
          `
        : '';
    const topDisplay = topString || empty;
    const bottomDisplay = bottomString || empty;
    return (0, element_vir_1.html) `
        <style>
            .right-side-column {
                display: flex;
                flex-direction: column;
                gap: 4px;
            }

            .top {
                ${design_system_1.toniqFontStyles.boldParagraphFont};
            }

            .bottom {
                ${design_system_1.toniqFontStyles.labelFont};
                color: ${design_system_1.toniqColors.pageSecondary.foregroundColor};
            }
        </style>
        <div class="right-side-column">
            ${(0, element_vir_1.renderIf)(!!topDisplay, (0, element_vir_1.html) `
                    <div class="top">${topDisplay}</div>
                `)}
            ${(0, element_vir_1.renderIf)(!!bottomDisplay, (0, element_vir_1.html) `
                    <div class="bottom">${bottomDisplay}</div>
                `)}
        </div>
    `;
}
exports.createRightSideTextTemplate = createRightSideTextTemplate;
