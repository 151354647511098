"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntrepotHomePageTopCardHeaderElement = void 0;
const class_map_js_1 = require("lit/directives/class-map.js");
const design_system_1 = require("@toniq-labs/design-system");
const element_vir_1 = require("element-vir");
exports.EntrepotHomePageTopCardHeaderElement = (0, design_system_1.defineToniqElement)()({
    tagName: 'toniq-entrepot-home-page-top-card-header',
    styles: (0, element_vir_1.css) `
        :host {
            ${design_system_1.toniqFontStyles.paragraphFont};
            ${(0, design_system_1.applyBackgroundAndForeground)(design_system_1.toniqColors.pagePrimary)};
            display: inline-flex;
            border-radius: 16px;
            align-items: stretch;
            padding: 0;
            max-width: 100%;
            overflow: hidden;
        }

        .index-number {
            border-right: 1px dashed transparent;

            border-top-left-radius: inherit;
            border-bottom-left-radius: inherit;
            width: 44px;
            justify-content: center;
            flex-shrink: 0;
            display: flex;
            align-items: center;
        }

        .main-contents {
            padding: 12px;
            display: flex;
            flex-grow: 1;
            align-items: stretch;
            border-radius: inherit;
            margin-right: 32px;
        }

        .main-contents span {
            flex-shrink: 0;
            white-space: nowrap;
        }

        .main-contents.left-spacing {
            margin-left: 32px;
        }

        .collection-image {
            max-height: 100%;
            max-width: 100%;
            border-radius: 8px;
        }

        .collection-name {
            flex-grow: 3;
            flex-shrink: 1;
        }

        .floor-price {
            padding: 0 12px;
            margin-right: 32px;
        }

        .price {
            text-align: right;
            flex-basis: 90px;
        }

        @media (max-width: 600px) {
            .price {
                flex-basis: 65px;
            }

            .floor-price {
                margin-right: 0px;
            }
        }
    `,
    renderCallback: ({ inputs }) => {
        const indexTemplate = inputs.hasIndex
            ? (0, element_vir_1.html) `
                  <span class="index-number">&nbsp;</span>
              `
            : '';
        return (0, element_vir_1.html) `
            ${indexTemplate}
            <div
                class="main-contents ${(0, class_map_js_1.classMap)({
            'left-spacing': !indexTemplate,
        })}"
            >
                <span class="collection-name">Collection</span>
                <span class="price floor-price">Floor Price</span>
                <span class="price">Volume</span>
            </div>
        `;
    },
});
